import React from 'react'
import { Flex, Box, Text } from '@chakra-ui/react'
import { useTreatmentPlan } from '@hooks'

const ReviewAndAcceptBanner = () => {
  const {
    isUnaccepted
  } = useTreatmentPlan()

  if (!isUnaccepted) {
    return null
  }

  return (
    <Flex 
      bg="black" 
      borderRadius="8px"
      padding="12px 16px"
      mb="24px"
      width="100%"
      maxW="100%"
    >
      <Box height={{ base: '100%', md: '22px' }}>
        <Text color="white" whiteSpace={{ base: 'pre-wrap', md: 'nowrap' }}>
          <b>Review & Accept. </b>
          Once accepted, this plan will be incorporated into future notes.
        </Text>
      </Box>
    </Flex>
  )
}

export default ReviewAndAcceptBanner