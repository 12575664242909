import React, { Fragment } from 'react'
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  Text,
  Flex,
  Box,
  Divider
} from '@chakra-ui/react'
import { CloseIcon, ArrowRightIcon, SettingsIcon } from '@blueprinthq/joy'
import { CircleQuestion, LightBulb } from '@components/icons'
import { useHistory } from 'react-router-dom'
import { DateTime } from 'luxon'
import { groupBy } from 'lodash'
import { useSessionControllerGetSessionsList } from '~/clinician-api'

export const PreviousSessionsMenu = ({
  isOpen,
  onClose
}: {
  isOpen: boolean
  onClose: () => void
}) => {
  const history = useHistory()

  const { data: sessionsData } = useSessionControllerGetSessionsList(
    {
      includeRecordingDrafts: 'false'
    },
    {
      query: {
        initialData: { nodes: [] }
      }
    }
  )

  const sessionsByDay = sessionsData?.nodes?.length
    ? groupBy(sessionsData.nodes, session =>
        DateTime.fromISO(session.startedAt).toFormat('yyyy-MM-dd')
      )
    : {}

  return (
    <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
      <DrawerOverlay>
        <DrawerContent backgroundColor="primary" position="relative">
          <Flex justifyContent="space-between" padding="small">
            <Text as="b" color="white">
              Previous Sessions
            </Text>
            <Box _hover={{ cursor: 'pointer' }} onClick={onClose}>
              <CloseIcon fill="white" />
            </Box>
          </Flex>
          <Box overflow="auto" pl="small" pr="small" pb="120px">
            {Object.keys(sessionsByDay).map((date, i) => (
              <Box key={date} mt={i > 0 ? 'small' : '0'}>
                <Text color="white" as="b">
                  {DateTime.fromISO(date).toFormat('EEE MMM dd')}
                </Text>
                {sessionsByDay[date].map((session: any) => {
                  const duration = DateTime.fromISO(session.completedAt).diff(
                    DateTime.fromISO(session.startedAt),
                    'minutes'
                  ).minutes

                  return (
                    <Fragment key={session.id}>
                      <Flex
                        justifyContent="space-between"
                        alignItems="center"
                        _hover={{ cursor: 'pointer' }}
                        onClick={() => {
                          history.push(
                            `/extension/patient/${session.client.id}/completed-session/${session.id}`
                          )
                        }}
                      >
                        <Box>
                          <Text color="white" fontSize="small" as="b">
                            {session.client.fullName}
                          </Text>
                          <Text
                            color="white"
                            fontSize="small"
                          >{`${DateTime.fromISO(session.startedAt).toFormat(
                            'hh:mm a'
                          )} | ${Math.round(duration)} min`}</Text>
                        </Box>
                        <Box mr="xxsmall">
                          <ArrowRightIcon fill="white" />
                        </Box>
                      </Flex>
                      <Divider mt="xsmall" mb="xsmall" />
                    </Fragment>
                  )
                })}
              </Box>
            ))}
          </Box>
          <Box
            backgroundColor="#1033B7"
            position="absolute"
            bottom="0"
            width="100%"
            padding="small"
          >
            <Flex
              alignItems="center"
              cursor="pointer"
              onClick={() =>
                window.open(
                  `${process.env.REACT_APP_CLINICIAN_APP_URL}/settings/progress-notes`,
                  '_blank'
                )
              }
            >
              <SettingsIcon fill="white" width="16px" />
              <Text ml="xsmall" fontSize="small" color="white">
                Settings
              </Text>
            </Flex>
            <Flex
              alignItems="center"
              mt="xxsmall"
              cursor="pointer"
              onClick={() =>
                window.open(
                  `${process.env.REACT_APP_HELP_URL}/en/collections/8084072-documentation-automation`,
                  '_blank'
                )
              }
            >
              <CircleQuestion fill="white" />
              <Text ml="xsmall" fontSize="small" color="white">
                Help
              </Text>
            </Flex>
            <Flex
              alignItems="center"
              mt="xxsmall"
              cursor="pointer"
              onClick={() =>
                window.open(
                  'https://blueprint-health.canny.io/ai-notetaker',
                  '_blank'
                )
              }
            >
              <LightBulb fill="white" width="16px" />
              <Text ml="xsmall" fontSize="small" color="white">
                Share Feedback
              </Text>
            </Flex>
          </Box>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  )
}
