import React, { useMemo, useState, useCallback } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Flex,
  Text,
  Box
} from '@chakra-ui/react'
import { Button, TextField, Select, StarCheck } from '@blueprinthq/joy'
import { useStoreActions, useStoreState } from 'easy-peasy'
import { useMutation, useQuery, useQueryClient } from 'react-query'

import { endpoints } from '@api'

import { TREATMENT_APPROACHES } from '@constants/index'
import { InterventionList } from './intervention-list'
import { StoreModel } from 'src/store/types'

type InterventionRecommendation = {
  id: string
  name: string
  interventionId: string
}

interface InterventionListModalProps {
  selectedInterventionIds: string[]
  clientId: string
  isOpen: boolean
  handleCloseModal: () => void
  onAssign: (interventionIds: string[]) => void
}

export const InterventionListModal = ({
  clientId,
  isOpen,
  handleCloseModal,
  selectedInterventionIds,
  onAssign
}: InterventionListModalProps) => {
  const [searchText, setSearchText] = useState('')
  const [modalityOption, setModalityOption] = useState(TREATMENT_APPROACHES[0])
  const queryClient = useQueryClient()

  const [newSelectedInterventionIds, setNewSelectedInterventionIds] = useState<
    string[]
  >([])

  const _onAssign = useCallback(() => {
    onAssign(newSelectedInterventionIds)
    checkIfRecommSelected(newSelectedInterventionIds)
    handleCloseModal()
  }, [handleCloseModal, newSelectedInterventionIds, onAssign])

  // Edge case: A user can add a recommended intervention from the A to Z list
  // checkIfRecommSelected - checks if any newly selected ids are
  // in the recommended so we can hide them on the session page when the
  // modal is closed
  const checkIfRecommSelected = (selectedIds: string[]) => {
    const idsOfRecommSelected = interventionRecommendations?.reduce(
      (acc: string[], curr) => {
        if (selectedIds.indexOf(curr.interventionId) >= 0) {
          acc.push(curr.id)
        }
        return acc
      },
      []
    )
    if (idsOfRecommSelected) {
      idsOfRecommSelected.map(id =>
        selectInterventionRecommendation({
          clientId,
          interventionRecommendationId: id
        })
      )
    }
  }

  const {
    mutate: selectInterventionRecommendation
  }: { mutate: Function } = useMutation(
    // @ts-ignore
    endpoints.selectInterventionRecommendation.request,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          endpoints.getInterventionRecommendations.getCacheId()
        )
      }
    }
  )

  const { data: interventions }: any = useQuery(
    [endpoints.getAllInterventions.getCacheId(), clientId],
    () =>
      // @ts-ignore
      endpoints.getAllInterventions.request({
        clientId,
        excludeNonGuided: false
      }),
    {
      // @ts-ignore
      placeholderData: []
    }
  )

  const {
    data: interventionRecommendations
  }: { data: InterventionRecommendation[] | undefined } = useQuery(
    [endpoints.getInterventionRecommendations.getCacheId(), clientId],
    // @ts-ignore
    () => endpoints.getInterventionRecommendations.request({ clientId })
  )

  const filteredInterventions = useMemo(() => {
    let newInterventions = interventions
    if (searchText.length) {
      newInterventions = newInterventions.filter(
        (i: any) =>
          i.name.toLowerCase().includes(searchText.toLowerCase()) ||
          i?.short_description?.toLowerCase().includes(searchText.toLowerCase())
      )
    }
    if (modalityOption.value !== '') {
      newInterventions = newInterventions.filter((i: any) =>
        i.treatment_modality
          .toLowerCase()
          .includes(modalityOption.value.toLowerCase())
      )
    }

    return newInterventions
  }, [searchText, modalityOption, interventions])

  const _handleCloseModal = useCallback(() => {
    setSearchText('')
    setModalityOption(TREATMENT_APPROACHES[0])
    handleCloseModal()
  }, [handleCloseModal])

  return (
    <Modal
      size="2xl"
      scrollBehavior="inside"
      isOpen={isOpen}
      onClose={_handleCloseModal}
    >
      <ModalOverlay />
      <ModalContent h="80vh">
        <ModalHeader
          textTransform="capitalize"
          fontSize="24px"
          borderBottom="1px solid #E4E5E6"
        >
          Interventions
        </ModalHeader>
        <ModalCloseButton mr="xsmall" mt="10px" borderRadius="25px" />
        <ModalBody pt="0" overflowX="hidden">
          <Flex align="center" justify="space-between">
            <Box w="50%" mt="medium" mb="small" mr="small">
              <TextField
                showClear
                onClear={() => setSearchText('')}
                label="Search interventions"
                onChange={e => setSearchText(e.currentTarget.value)}
                value={searchText}
              />
            </Box>
            <Box w="50%" mt="medium" mb="small" ml="small">
              <Select
                value={modalityOption}
                label="Treatment Approaches"
                options={TREATMENT_APPROACHES}
                valueKey="value"
                labelKey="label"
                onChange={option => setModalityOption(option)}
                menuListProps={{
                  height: '30vh',
                  overflowY: 'scroll'
                }}
              />
            </Box>
          </Flex>

          {/* Recommended interventions */}
          <InterventionList
            clientId={clientId}
            interventionList={interventionRecommendations}
            idOrInterventionId="interventionId"
            newSelectedInterventionIds={newSelectedInterventionIds}
            setNewSelectedInterventionIds={setNewSelectedInterventionIds}
            selectedInterventionIds={selectedInterventionIds}
            title="Recommended"
          />

          {/* All interventions */}
          <InterventionList
            clientId={clientId}
            interventionList={filteredInterventions}
            idOrInterventionId="id"
            newSelectedInterventionIds={newSelectedInterventionIds}
            setNewSelectedInterventionIds={setNewSelectedInterventionIds}
            selectedInterventionIds={selectedInterventionIds}
            title="A to Z"
          />
        </ModalBody>
        <ModalFooter borderTop="1px solid #E4E5E6">
          <Flex justify="space-between" align="center" flex={1}>
            <Flex align="center">
              <StarCheck mt="xxsmall" fill="medium_gray" />
              <Text ml="xsmall" color="dark_gray">
                Available on Blueprint
              </Text>
            </Flex>
            <Box>
              <Button variant="outline" size="lg" onClick={_handleCloseModal}>
                Cancel
              </Button>
              <Button
                isDisabled={newSelectedInterventionIds.length < 1}
                size="lg"
                onClick={_onAssign}
              >
                {newSelectedInterventionIds.length < 1
                  ? 'Add Intervention(s)'
                  : `Add ${newSelectedInterventionIds.length} Intervention(s)`}
              </Button>
            </Box>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export const InterventionListModalConnected = () => {
  const { isOpen, clientId, selectedInterventionIds, onAssign } = useStoreState<
    StoreModel
  >((state: StoreModel) => state.modals.interventionList)
  const handleCloseModal = useStoreActions<StoreModel>(
    //@ts-ignore
    actions => actions.modals.interventionList.closeModal
  )

  return clientId ? (
    <InterventionListModal
      isOpen={isOpen}
      clientId={clientId}
      selectedInterventionIds={selectedInterventionIds}
      onAssign={onAssign}
      handleCloseModal={handleCloseModal}
    />
  ) : null
}
