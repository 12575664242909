import React, { useEffect, useRef } from 'react'
import {
  Flex,
  Text,
  VStack,
  HStack,
  Box,
  Divider,
  Button,
  useToast,
  Link
} from '@chakra-ui/react'
import {
  Link as RouterLink,
  useLocation
} from 'react-router-dom'

import { useQueryClient } from 'react-query'
import { useAssistPanelContext } from '../../../in-session-assist-panel/context'
import { useExperienceManager } from '@hooks'
import {
  useInterventionsControllerSaveInterventionForLater,
  getInterventionsControllerGetSavedForLaterInterventionsQueryKey,
  getInterventionsControllerV1GetAllInterventionsQueryKey
} from '~/clinician-api'
import { useStoreState } from 'easy-peasy'
interface InterventionListProps {
  interventionList: any[] | undefined,
  clientId: string
}

export const InterventionList = ({
  clientId,
  interventionList
}: InterventionListProps) => {
  const { setOverlayPanel, interventionLibraryData, saveInterventionLibraryData } = useAssistPanelContext()
  const queryClient = useQueryClient()
  const { isSessionRecordingV2Enabled } = useExperienceManager()
  const toast = useToast()
  const scrollRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (scrollRef.current && interventionLibraryData.scrollTop) {
      scrollRef.current.scrollTop = interventionLibraryData.scrollTop
    }
  }, [scrollRef.current, interventionLibraryData.scrollTop])

  const { isOpen: isSaveInterventionsModalOpen } = useStoreState(
    // @ts-ignore
    state => state.modals.saveInterventions
  )

  const location = useLocation()
  const [baseUrl] = location.pathname.split('/patient')

  const {
    mutateAsync: saveInterventionForLater
  } = useInterventionsControllerSaveInterventionForLater({
    mutation: {
      onSuccess: () => {
        toast({
          title: 'Intervention Saved for Later',
          status: 'success',
          isClosable: true,
          duration: 2000
        })
        queryClient.invalidateQueries(
          getInterventionsControllerGetSavedForLaterInterventionsQueryKey(
            clientId
          )
        )
        queryClient.invalidateQueries(
          getInterventionsControllerV1GetAllInterventionsQueryKey()
        )
      }
    }
  })

  if (!Boolean(interventionList?.length)) return null

  return (
    <Box
      h={
        isSaveInterventionsModalOpen
          ? 'calc(100vh - 380px)'
          : isSessionRecordingV2Enabled ? 'calc(100vh - 235px)' : 'calc(100vh - 293px)'
      }
    >
      <Box p="4px 24px" mt="8px" bg="pale_gray">
        <Text fontWeight="bold" textTransform="uppercase">
          A to Z
        </Text>
      </Box>
      <VStack
        ref={scrollRef}
        overflowY="scroll"
        h="100%"
        px="24px"
        divider={<Divider color="pale_gray" />}
      >
        {interventionList?.map((intervention: any) => (
          <Flex
            _first={{ mt: 'xsmall' }}
            _last={{ mb: 'xsmall' }}
            justify="space-between"
            key={intervention.id}
            w="100%"
          >
            <Flex align="center" flex="2" minW="0">
              <Box flex="0.9" minW="0">
                <Text>{intervention.name}</Text>
                <Text
                  color="dark_gray"
                  sx={{
                    overflowWrap: 'break-word',
                    wordWrap: 'break-word'
                  }}
                >
                  {intervention.shortDescription}
                </Text>

                <Box
                  p="2px 4px"
                  bg="pale_gray"
                  width="fit-content"
                  borderRadius="3px"
                >
                  <Text fontSize="12px" textTransform="uppercase">
                    {intervention.treatmentModality}
                  </Text>
                </Box>
              </Box>
            </Flex>

            <HStack spacing={3}>
              {isSaveInterventionsModalOpen ? (
                <Link
                  id={`pendo-intervention-view-link-tag-${intervention.id}`}
                  as={RouterLink}
                  color="primary"
                  isExternal={true}
                  target="_blank"
                  rel="noopener noreferrer"
                  flexShrink={0}
                  _focus={{
                    outline: 'none'
                  }}
                  to={{
                    pathname: `${baseUrl}/patient/${clientId}/interventions/${intervention.id}/summary?isPlus=true`,
                    state: { prevLocation: location.pathname }
                  }}
                >
                  <Text fontSize={'16px'}>Learn more</Text>
                </Link>
              ) : (
                <Text
                  fontSize={'16px'}
                  color="primary"
                  _hover={{ cursor: 'pointer' }}
                  onClick={() => {
                    saveInterventionLibraryData({ scrollTop: scrollRef.current?.scrollTop })
                    setOverlayPanel('INTERVENTION_OVERVIEW', {
                      interventionId: intervention.id,
                      interventionName: intervention.name
                    })
                  }}
                >
                  Learn more
                </Text>
              )}
              <Button
                isDisabled={intervention.isAlreadySaved}
                paddingLeft={'6'}
                paddingRight={'6'}
                borderRadius="4px"
                size="sm"
                onClick={() => {
                  saveInterventionForLater({
                    patientId: clientId,
                    interventionId: intervention.id,
                    data: {}
                  })
                }}
                _focus={{
                  outline: 'none'
                }}
              >
                <Text fontSize={'16px'}>
                  {intervention.isAlreadySaved
                    ? 'Saved for later'
                    : 'Save for later'}
                </Text>
              </Button>
            </HStack>
          </Flex>
        ))}
      </VStack>
    </Box>
  )
}
