import { Link, Text } from '@chakra-ui/react'
import { AlertBox } from '@components'
import React from 'react'

export default function LowWordCountBanner(props: {
  onStartNewDictationSessionClick: () => void
}) {
  const { onStartNewDictationSessionClick } = props

  return (
    <AlertBox
      sx={{
        a: {
          color: 'primary'
        }
      }}
    >
      <Text mb="4px">
        <b>Important:</b> Too few words were captured to create a quality note.{' '}
        <Link
          target="_blank"
          color="blue.500"
          href={`${process.env.REACT_APP_HELP_URL}/en/articles/9824072-failed-notes-causes-workarounds-and-tips-for-success`}
        >
          Mic or audio issues may be to blame.
        </Link>{' '}
        Our apologies for the inconvenience, this session won’t count towards
        your usage. You can still generate a progress note by{' '}
        <Link
          target="_blank"
          color="blue.500"
          onClick={onStartNewDictationSessionClick}
        >
          dictating a quick summary.
        </Link>
      </Text>
    </AlertBox>
  )
}
