import { baseStyles as styles } from '../patient-profile/components/patient-header/components/PatientInfo/_base'
import { useStoreActions } from 'easy-peasy'
import React, { useState, useMemo } from 'react'
import moment from 'moment'
import { DateTime } from 'luxon'
import { Box, Flex, Tooltip, Text, useTheme } from '@chakra-ui/react'
import queryString from 'query-string'
import { Link, useLocation } from 'react-router-dom'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import { ClientStatusBuckets } from '../../../constants/clientStatusBuckets'
import { HighRiskToggle } from '@components'
import { PTOScheduleModal } from '@containers/pto-schedule-modal'
import { useExperienceManager } from '@hooks'

const Value = ({ children, color }) => {
  return (
    <Box
      sx={{
        lineHeight: '20px',
        fontSize: '16px',
        color: color || '#333333'
      }}
      as="span"
    >
      {children || 'N/A'}
    </Box>
  )
}

const DateValue = ({ date }) => {
  if (date) {
    return (
      <Tooltip
        label={DateTime.fromISO(date).toLocaleString(DateTime.DATETIME_MED)}
      >
        <Box as="span" color="#333333">
          {DateTime.fromISO(date).toLocaleString({
            month: 'short',
            day: 'numeric'
          })}
        </Box>
      </Tooltip>
    )
  }

  return <Value>N/A</Value>
}

const ModuleList = ({ modules, path, patient }) => {
  if (Array.isArray(modules) && modules.length) {
    const uniqueNames = [...new Set(modules.map(a => a.title))]
    const displayedNames = uniqueNames.slice(0, 3).join(', ')
    const additionalCount = uniqueNames.length - 3

    let displayText = displayedNames
    if (additionalCount > 0) {
      displayText += `, and ${additionalCount} more`
    }

    return (
      <Link to={`/patient/${patient.id}/settings/${path}`}>
        <Text _hover={{ textDecoration: 'underline', cursor: 'pointer' }}>
          {displayText}
        </Text>
      </Link>
    )
  }

  return <Value>N/A</Value>
}

export const AdjustableNextMeasure = ({
  patient,
  nextAdministrationDeliveryData
}) => {
  const location = useLocation()
  const theme = useTheme()
  const queryParams = useMemo(
    () => queryString.parse(location.search, { parseBooleans: true }),
    [location.search]
  )
  const [isModalOpen, setIsModalOpen] = useState(
    queryParams.open_adjust_measure_date === true
  )

  const deliveryAssignees = useMemo(() => {
    return nextAdministrationDeliveryData
      .map(delivery => {
        const assigneeUser = patient.assigneeUsers.find(
          user => user.id === delivery.assigneeUserId
        )
        const date = DateTime.fromISO(delivery.nextAdministrationDate)

        return {
          user: assigneeUser,
          date
        }
      })
      .filter(assignee => !!assignee.user)
  }, [nextAdministrationDeliveryData, patient.assigneeUsers])

  const handleModal = () => {
    setIsModalOpen(!isModalOpen)
  }

  const renderModal = () => {
    return patient.is_archived ? (
      <PTOScheduleModal
        clientId={patient.id}
        handleModal={handleModal}
        isModalOpen={isModalOpen}
      />
    ) : (
      <>
        <span>
          {deliveryAssignees.length === 0
            ? 'N/A'
            : deliveryAssignees.map((deliveryAssignee, i) => {
                const suffix =
                  deliveryAssignees.length === 1 &&
                  patient.user_id === deliveryAssignee.user.id
                    ? ''
                    : ` (${deliveryAssignee.user.info.firstName} ${
                        deliveryAssignee.user.info.lastName
                      })${i === deliveryAssignees.length - 1 ? '' : ', '}`
                return (
                  <Tooltip
                    label={deliveryAssignee.date.toLocaleString(
                      DateTime.DATETIME_MED
                    )}
                    key={deliveryAssignee.user.id}
                  >
                    <span>
                      {i !== 0 && deliveryAssignees.length > 1 ? <br /> : null}
                      {`${deliveryAssignee.date.toLocaleString({
                        month: 'short',
                        day: 'numeric'
                      })} ${suffix}`}
                    </span>
                  </Tooltip>
                )
              })}
        </span>

        <Link
          style={{ color: theme.colors.primary }}
          to={`/patient/${patient.id}/settings/outcome_measures`}
        >
          <Text
            display={
              deliveryAssignees.length === 0 || deliveryAssignees.length === 1
                ? 'inline'
                : ''
            }
            ml={deliveryAssignees.length === 0 ? 1 : 0}
            _hover={{ textDecoration: 'underline' }}
          >
            Manage
          </Text>
        </Link>
      </>
    )
  }

  return (
    <Flex>
      <span>{renderModal()}</span>
    </Flex>
  )
}

export const EnrollmentDate = ({ patient }) => {
  const dateOfInvite = moment(patient.date_of_invite).format('M/DD/YYYY h:mm a')
  const openAdjustEnrollmentDateModal = useStoreActions(
    actions => actions.modals.adjustEnrollmentDate.openModal
  )

  return (
    <>
      <span>{dateOfInvite}</span>
      <span
        className={styles.link}
        onClick={() => openAdjustEnrollmentDateModal({ patientId: patient.id })}
      >
        Adjust
      </span>
    </>
  )
}

export const TakeNowOutcome = props => {
  const theme = useTheme()
  const { patient, activeAssessments } = props
  if (activeAssessments.length) {
    const uniqueNames = [...new Set(activeAssessments.map(a => a.name))]
    const displayedNames = uniqueNames.slice(0, 3).join(', ')
    const additionalCount = uniqueNames.length - 3
    let displayText = displayedNames
    if (additionalCount > 0) {
      displayText += `, and ${additionalCount} more`
    }
    return (
      <>
        {' '}
        <Box as="span" display="inline">
          <Link
            to={`/patient/${patient.id}/settings/outcome_measures`}
            style={{ display: 'inline' }}
          >
            <Text
              _hover={{ textDecoration: 'underline', cursor: 'pointer' }}
              style={{ display: 'inline' }}
            >
              {displayText}
            </Text>
          </Link>{' '}
          <Link
            style={{
              color: theme.colors.primary,
              display: 'inline'
            }}
            to={`/patient/${patient.id}/settings/outcome_measures`}
          >
            <Text
              _hover={{ textDecoration: 'underline' }}
              style={{ display: 'inline' }}
            >
              Manage
            </Text>
          </Link>
        </Box>
      </>
    )
  }

  return (
    <Text>
      {!patient.is_archived && (
        <Box as="span" display="inline-block">
          N/A
          <Link
            style={{ color: theme.colors.primary, marginLeft: '5px' }}
            to={`/patient/${patient.id}/settings/outcome_measures`}
          >
            <Text display={'inline'} _hover={{ textDecoration: 'underline' }}>
              Manage
            </Text>
          </Link>
        </Box>
      )}
    </Text>
  )
}

const SettingsLink = ({ item, patient }) => {
  const location = useLocation()

  // Chop current path down to base (/patient/:patient_id) before adding settings path
  // This prevents a bug where pressing the link repeatedly adds 'settings/account'
  // to the path which leads to a white screen/tab
  const path = location.pathname.split('/settings')[0] + '/settings/account'

  const { first_name } = patient
  return (
    <Link className={styles.settingsLink} to={`${path}`}>
      {`Add ${
        item === 'email' ? 'an email address' : 'a phone number'
      } for ${first_name}`}
    </Link>
  )
}

export const PatientPhoneNumber = ({ patient }) => {
  if (moment().diff(patient.date_of_birth, 'years') < 13)
    return <Value color="#a6a6a6">Must be 13 years or older</Value>

  const phone_number = patient.phone_number ? (
    parsePhoneNumberFromString(patient.phone_number).formatNational()
  ) : (
    <SettingsLink item={'phone'} patient={patient} />
  )

  return <Value>{phone_number}</Value>
}

export const PatientEmail = ({ patient }) => {
  if (moment().diff(patient.date_of_birth, 'years') < 13)
    return <Value color="#a6a6a6">Must be 13 years or older</Value>

  const email = patient.email || (
    <SettingsLink item={'email'} patient={patient} />
  )
  return <Value>{email}</Value>
}

export const DateOfBirth = ({ patient }) => {
  const dob = patient.date_of_birth
  const age = moment().diff(dob, 'years')

  return (
    <Value>
      {moment.utc(dob).format('MM/DD/YYYY')} (age {age})
    </Value>
  )
}

export const PatientName = ({ patient }) => {
  const {
    isEvidenceBasedCareEnabled,
    isPlusPlanEnabled
  } = useExperienceManager()

  return (
    <Flex
      direction={{ base: 'column', sm: 'row' }}
      justifyContent={{ base: 'flex-start', sm: 'flex-start' }}
      alignItems={{ base: 'flex-start', sm: 'center' }}
    >
      <p className={styles.name}>
        <span id="patient-header-patient-name">{`${patient.first_name} ${patient.last_name}`}</span>
        {patient.medical_record ? (
          <span className={styles.mrn}>{`${patient.medical_record}`}</span>
        ) : null}
      </p>
      {patient.status === ClientStatusBuckets.ACTIVE &&
        (isEvidenceBasedCareEnabled ||
          isPlusPlanEnabled ||
          patient.is_high_risk) && (
          <HighRiskToggle
            clientId={patient.id}
            isHighRisk={patient.is_high_risk}
          />
        )}
    </Flex>
  )
}

export const AssignedClinicians = ({ clinicians }) => {
  const clinicianNames =
    clinicians && clinicians.length
      ? clinicians
          .filter(clinician => clinician.assigned)
          .map(clinician => `${clinician.first_name} ${clinician.last_name}`)
          .join(', ')
      : ''

  return (
    <Flex>
      <Value>{clinicianNames}</Value>
    </Flex>
  )
}

export const LastAssessment = ({ patient }) => {
  return <DateValue date={patient.last_assessment_date} />
}

export const LastCheckIn = ({ patient }) => {
  return <DateValue date={patient.last_checkin_date} />
}

export const SymptomTracking = ({ activeCheckinModules, patient }) => {
  const symptomModules = activeCheckinModules.filter(m => m.type === 'symptom')
  return (
    <ModuleList
      modules={symptomModules}
      patient={patient}
      path={'symptom_tracking'}
    />
  )
}

export const Worksheets = ({ activeCheckinModules, patient }) => {
  const worksheetModules = activeCheckinModules.filter(
    m => m.type === 'treatment_activity'
  )
  return (
    <ModuleList
      modules={worksheetModules}
      patient={patient}
      path={'worksheets'}
    />
  )
}

export const Source = ({ patient }) => {
  return <Value>{patient.creation_source}</Value>
}
