import React from 'react'
import {
  Box,
  Divider,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Text,
  Container,
  Stack
} from '@chakra-ui/react'
import Prep from './prep'
import TreatmentPlan from './treatment-plan'
import Assessments from './assessments'
import { Interventions } from './interventions'
import { Worksheets } from './worksheets'
import { PanelBody, PanelHeader } from '@handlers/sessions/components'
import { TreatmentPlanProvider } from '@context'
import { useTreatmentPlan } from '@hooks'
import { CompassOutlined } from '@components/icons'
import AssistPanelProvider, { useAssistPanelContext } from './context'
import Panel from './panel'
import OverlayPanel from './overlay-panel'
import { SessionPanelTab } from '@handlers/sessions/types'
import { ClientAccountResponse } from '@api'
import flagsmith from 'flagsmith'
import { FlagsmithFeatures } from '@constants/flagsmith'
import AssistPanelEmptyCouch from './svgs/assist-panel-empty-couch'

interface InSessionAssistPanelProps {
  toggleAssist: () => void
  fullScreen?: boolean
}

const InSessionAssistPanel = ({
  toggleAssist,
  fullScreen
}: InSessionAssistPanelProps) => {
  const { isUnaccepted, client } = useTreatmentPlan()
  const {
    switchTab,
    activeTab,
    panel: activeOverlayPanel
  } = useAssistPanelContext()

  const showInterventionsTab = flagsmith.hasFeature(
    FlagsmithFeatures.INTERVENTION_SUGGESTIONS
  )

  const TabConfig = [
    {
      identifier: SessionPanelTab.PREP,
      name: 'Prep',
      component: <Prep client={client} />,
      showTab: true
    },
    {
      identifier: SessionPanelTab.ASSESSMENTS,
      name: 'Assessments',
      component: <Assessments />,
      showTab: true
    },
    {
      identifier: SessionPanelTab.WORKSHEETS,
      name: 'Worksheets',
      component: <Worksheets />,
      showTab: true
    },
    {
      identifier: SessionPanelTab.INTERVENTIONS,
      name: 'Interventions',
      component: <Interventions />,
      showTab: showInterventionsTab
    },
    {
      identifier: SessionPanelTab.TREATMENT_PLAN,
      name: 'Treatment Plan',
      component: <TreatmentPlan />,
      showIndicator: isUnaccepted,
      showTab: true
    }
  ]

  if (activeOverlayPanel) {
    return <OverlayPanel client={client} />
  }

  const filteredTabConfig = TabConfig.filter(tab => tab.showTab)

  const activeTabIdx = filteredTabConfig.findIndex(
    tab => tab.identifier === activeTab
  )

  return (
    <Panel id="assist-in-session-panel">
      <PanelHeader
        icon={<CompassOutlined fill="black" />}
        title={'Assist'}
        onClose={toggleAssist}
        fullScreen={fullScreen}
      />
      <Box width="100%" maxWidth="100%">
        <PanelBody
          px="0px"
          pb={fullScreen ? '0px' : '24px'}
          overflowY={{
            base: 'clip',
            sm: 'auto',
            md: 'hidden'
          }}
          height={
            client
              ? {
                  base: '100%',
                  sm: '100%',
                  md: 'auto'
                }
              : {
                  base: '100%'
                }
          }
        >
          {!client && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              h="100%"
            >
              <AssistPanelEmptyCouch />
              <Text color="#757575">
                Assist info will load once you select a client
              </Text>
            </Box>
          )}
          {client && (
            <Tabs
              isLazy
              index={activeTabIdx}
              onChange={idx => switchTab(filteredTabConfig[idx].identifier)}
              height="100%"
              display="flex"
              flexDirection="column"
              width="100%"
              maxWidth="100%"
            >
              <Container maxW="100%" px={0}>
                <Stack flex="1" width="100%">
                  <TabList
                    sx={{
                      display: 'flex',
                      textWrap: 'nowrap',
                      msOverflowStyle: 'none',
                      scrollbarWidth: 'none',
                      '&::-webkit-scrollbar': {
                        display: 'none'
                      }
                    }}
                    gap="32px"
                    borderBottom="none"
                    overflowX="auto"
                    overflowY="hidden"
                    width="100%"
                    maxWidth="100%"
                    padding="0 16px"
                  >
                    {filteredTabConfig.map((tab, index) => (
                      <Tab
                        key={tab.name}
                        justifyContent="flex-start"
                        px="0"
                        color="dark_gray"
                        fontWeight="normal"
                        borderBottom="6px solid"
                        whiteSpace="nowrap"
                        _focus={{ outline: 'none' }}
                        _selected={{
                          color: '#282828',
                          borderBottomColor: 'primary'
                        }}
                        position="relative"
                        _last={{
                          pr: '15px'
                        }}
                      >
                        <Box
                          h="8px"
                          w="8px"
                          bg="primary"
                          borderRadius="50%"
                          position="absolute"
                          top="5px"
                          right="-4px"
                          display={tab.showIndicator ? 'block' : 'none'}
                        />
                        {tab.name}
                      </Tab>
                    ))}
                  </TabList>
                  <Divider color="#E4E5E6" style={{ marginTop: '0px' }} />
                </Stack>
              </Container>
              <TabPanels
                flex="1"
                width="100%"
                maxWidth="100%"
                overflow="hidden"
                display="flex"
                flexDirection="column"
              >
                {filteredTabConfig.map((tab, index) => (
                  <TabPanel
                    key={index}
                    p={0}
                    m={0}
                    width="100%"
                    maxWidth="100%"
                    height="100%"
                    overflowY="auto"
                    overflowX="hidden"
                  >
                    <Box width="100%" maxWidth="100%">
                      {tab.component}
                    </Box>
                  </TabPanel>
                ))}
              </TabPanels>
            </Tabs>
          )}
        </PanelBody>
      </Box>
    </Panel>
  )
}

const WrappedInSessionAssistPanel = ({
  client,
  toggleAssist,
  fullScreen
}: {
  client?: ClientAccountResponse
  toggleAssist: any
  fullScreen?: boolean
}) => {
  return (
    <AssistPanelProvider>
      <TreatmentPlanProvider client={client}>
        <InSessionAssistPanel
          toggleAssist={toggleAssist}
          fullScreen={fullScreen}
        />
      </TreatmentPlanProvider>
    </AssistPanelProvider>
  )
}

export default WrappedInSessionAssistPanel
