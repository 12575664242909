import React, { createContext, useState, useEffect, FC } from 'react'
import { useExperienceManager } from '@hooks'

interface PanelManagerContextType {
  isSessionAgendaOpen: boolean
  isSessionNotesOpen: boolean
  toggleSessionAgenda: () => void
  toggleSessionNotes: () => void
  isDictationTipsOpen: boolean
  toggleDictationTips: () => void
  isAssistOpen: boolean
  toggleAssist: () => void
  isMobileAssistOpen: boolean
  toggleMobileAssist: () => void
}

export const PanelManagerContext = createContext<PanelManagerContextType>(
  {} as PanelManagerContextType
)

interface PanelManagerProviderProps {
  isDictation: boolean
  children: React.ReactNode
  isOnAssistWaitlist: boolean
}

export const PanelManagerProvider: FC<PanelManagerProviderProps> = ({
  isDictation,
  isOnAssistWaitlist,
  children
}) => {
  const {
    isEvidenceBasedCareEnabled,
    isPlusPlanEnabled,
    isSessionRecordingV2Enabled,
    isWidget
  } = useExperienceManager()

  const [isSessionAgendaOpen, setSessionAgendaOpen] = useState<boolean>(
    isEvidenceBasedCareEnabled || !isPlusPlanEnabled
  )
  const [isSessionNotesOpen, setSessionNotesOpen] = useState<boolean>(
    isEvidenceBasedCareEnabled || !isPlusPlanEnabled
  )
  const [isDictationTipsOpen, setIsDictationTipsOpen] = useState<boolean>(
    isDictation && !isWidget
  )

  const [isAssistOpen, setIsAssistOpen] = useState<boolean>(isPlusPlanEnabled)
  const [isMobileAssistOpen, setIsMobileAssistOpen] = useState<boolean>(false)

  useEffect(() => {
    if (isEvidenceBasedCareEnabled) {
      setSessionAgendaOpen(true)
    }
  }, [isEvidenceBasedCareEnabled])

  useEffect(() => {
    if (isPlusPlanEnabled) {
      setIsAssistOpen(true)
    }
  }, [isPlusPlanEnabled])

  const toggleSessionAgenda = () =>
    setSessionAgendaOpen((isSessionAgendaOpen: boolean) => !isSessionAgendaOpen)

  const toggleSessionNotes = () =>
    setSessionNotesOpen((isSessionNotesOpen: boolean) => !isSessionNotesOpen)

  const toggleDictationTips = () =>
    setIsDictationTipsOpen(
      (isDictationTipsOpen: boolean) => !isDictationTipsOpen
    )

  const toggleAssist = () =>
    setIsAssistOpen((isAssistOpen: boolean) => !isAssistOpen)

  const toggleMobileAssist = () => {
    setIsMobileAssistOpen((isMobileAssistOpen: boolean) => !isMobileAssistOpen)
  }

  return (
    <PanelManagerContext.Provider
      value={{
        isSessionAgendaOpen,
        toggleSessionAgenda,
        isSessionNotesOpen,
        toggleSessionNotes,
        isDictationTipsOpen,
        toggleDictationTips,
        isAssistOpen,
        toggleAssist,
        isMobileAssistOpen,
        toggleMobileAssist
      }}
    >
      {children}
    </PanelManagerContext.Provider>
  )
}
