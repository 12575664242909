import React from 'react'
import {
  Box,
  Flex,
  Input,
  Text,
  HStack,
  Link,
  Stack,
  Divider,
  Button,
  FlexProps
} from '@chakra-ui/react'
import { useCheckInControllerGetCheckInsForPlusCustomer, usePatientControllerGetAssignedCheckIns } from '~/clinician-api'
import { buildCheckInPreviewUrl } from '@utilities'

const WorksheetRow = ({
  title,
  subtitle,
  previewUrl,
  onAssign,
  isAssigned,
  isLast
}: any) => {
  return (
    <Stack spacing="0">
      <HStack py="8px" justifyContent="space-between">
        <Stack spacing="0">
          <Text>{title}</Text>
          <Text color="dark_gray">{subtitle}</Text>
        </Stack>
        <HStack gap="12px" spacing="0">
          <Link
            href={previewUrl}
            target="_blank"
            color="primary"
            textDecoration="none"
            _focus={{ outline: 'none' }}
            mr={4}
          >
            Preview
          </Link>
          <Button
            isDisabled={isAssigned}
            textDecoration="none"
            borderRadius="4px"
            size="sm"
            _focus={{ outline: 'none' }}
            onClick={onAssign}
            w="85px"
          >
            {isAssigned ? 'Assigned' : 'Assign'}
          </Button>
        </HStack>
      </HStack>
      {!isLast && <Divider m="0" />}
    </Stack>
  )
}

interface LibraryProps {
  client: { id: string }
  onAssign: (assessment: any) => void
  searchContainerProps?: FlexProps
}

const Library = ({ client, onAssign, searchContainerProps = {} }: LibraryProps) => {
  const [searchText, setSearchText] = React.useState('')

  const { data: checkIns = [] } = useCheckInControllerGetCheckInsForPlusCustomer()

  const { data: assignedCheckIns = [] } = usePatientControllerGetAssignedCheckIns(
    client.id
  )

  const handleAssign = async (worksheet: any) => {
    onAssign(worksheet)
  }

  // @ts-ignore
  const filteredWorksheets: any[] = checkIns?.filter(w => w.type === 'treatment_activity' && w.title.toLowerCase().includes(searchText.toLowerCase())) || []

  return (
    <Box flex="1" overflowY="hidden">
      <Flex
        h="100px"
        align="center"
        flex="1"
        borderBottom="1px solid"
        borderColor="pale_gray"
        p="24px"
        {...searchContainerProps}
      >
        <Input
          placeholder="Search worksheets"
          onChange={e => setSearchText(e.currentTarget.value)}
          value={searchText}
        />
      </Flex>
      <Stack px="24px" py={4} h="calc(100vh - 190px)" overflowY="scroll" spacing="0">
        {filteredWorksheets.map((w, index) => (
          <WorksheetRow
            key={w.id}
            title={w.title}
            subtitle={w.subtitle}
            previewUrl={buildCheckInPreviewUrl(w.id)}
            isAssigned={assignedCheckIns.some(c => c.checkInId === w.id )}
            onAssign={() => handleAssign(w)}
            isLast={index === filteredWorksheets.length - 1}
          />
        ))}
      </Stack>
    </Box>
  )
}

export default Library
