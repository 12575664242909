import React from 'react'
import { Box, Flex, Heading, Select, Text } from '@chakra-ui/react'
import { useStoreState } from 'easy-peasy'
import { Select as JoySelect } from '@blueprinthq/joy'

import { Loading } from '../../../components'
import { ClinicName } from './clinic-name'
import { KioskUrl } from './kiosk-url'
import CoBranding from './co-branding'
import { usePermissions } from '../../../hooks'
import { useExperienceManager, useClinicSelectControl } from '@hooks'
import { SuperAdminClinicSearch } from '@components/clinic-selector/super-admin-clinic-search'

export const GeneralTab = ({ clinics, selectedClinic, setSelectedClinic }) => {
  const { user } = useStoreState(state => state.auth)
  const { isEvidenceBasedCareEnabled } = useExperienceManager()
  const { hasPermission } = usePermissions()

  const isSuperAdmin = hasPermission('read:any:clinics:list')

  const HeaderText = () => {
    return (
      <Box mb={'medium'} w={'100%'}>
        <Heading mb="xxsmall">General</Heading>
        <Text>Manage your organizations settings</Text>
      </Box>
    )
  }

  const { clinicSelectEnabled } = useClinicSelectControl(clinics.length)

  const orgAdminSelectComponent = (
    <JoySelect
      value={selectedClinic}
      options={clinics}
      valueKey="id"
      labelKey="name"
      onChange={value => setSelectedClinic(value)}
    />
  )

  const superAdminSelectComponent = (
    <SuperAdminClinicSearch
      onClinicSelected={value => {
        setSelectedClinic(value)
      }}
      selectedClinicName={selectedClinic.name}
    />
  )

  let selectComponent = null
  if (isSuperAdmin) {
    selectComponent = superAdminSelectComponent
  } else if (clinicSelectEnabled) {
    selectComponent = orgAdminSelectComponent
  }

  return (
    <>
      <Box mb="xxlarge">
        <Box
          width={{
            base: '100%',
            sm: 'auto'
          }}
        >
          <Flex justify="space-between" alignItems="center">
            <Box w={'100%'}>
              <HeaderText boxWidth="100%" />
              {selectComponent && <Box mb="medium">{selectComponent}</Box>}
            </Box>
          </Flex>
        </Box>
        <Box
          mb="medium"
          overflowX={{
            base: 'auto',
            md: 'initial'
          }}
        >
          {!user ? (
            <Loading />
          ) : (
            <ClinicName user={user} clinic={selectedClinic} />
          )}
        </Box>

        <Box
          mb="medium"
          overflowX={{
            base: 'auto',
            md: 'initial'
          }}
        >
          {' '}
          {!user ? (
            <Loading />
          ) : (
            isEvidenceBasedCareEnabled && (
              <KioskUrl user={user} clinic={selectedClinic} />
            )
          )}
        </Box>

        <Box>
          {isEvidenceBasedCareEnabled && (
            <CoBranding user={user} clinic={selectedClinic} />
          )}
        </Box>
      </Box>
    </>
  )
}
