import React, { useState } from 'react'
import { useFormikContext } from 'formik'
import { Flex, useBreakpointValue } from '@chakra-ui/react'
import { TrashIcon } from '@icons'
import { useTreatmentPlan } from '@hooks'
import { Button } from '../Button'
import Toolbar from '../Toolbar'
import { PencilIcon } from '../Icon'

const Actions: React.FC = () => {
  const {
    onCopyAll,
    setIsEditing,
    openDeleteConfirmationModal
  } = useTreatmentPlan()


  return (
    <Toolbar>
      <Flex 
        position={{ base: 'fixed', md: 'static' }}
        bottom={{ base: 0, md: 'auto' }}
        left={{ base: 0, md: 'auto' }}
        right={{ base: 0, md: 'auto' }}
        py={4}
        px={6}
        bg="white"
        borderTop={{ base: '1px solid', md: 'none' }}
        borderColor={{ base: 'gray.200', md: 'transparent' }}
        justifyContent="flex-end"
        gap="16px"
        flex="1"
      >
        <Button colorScheme="primary" onClick={onCopyAll}>
          Copy All
        </Button>
        <Button
          variant="outline"
          onClick={() => setIsEditing(true)}
          leftIcon={<PencilIcon />}
        >
          Edit
        </Button>
        <Button
          variant="outline"
          onClick={openDeleteConfirmationModal}
          leftIcon={<TrashIcon fill="#EB5164" />}
        >
          Delete
        </Button>
      </Flex>
    </Toolbar>
  )
}

export default Actions
