import React, { useState } from 'react'
import { useQuery } from 'react-query'
import {
  Container,
  GridItem,
  Flex,
  Text,
  Box,
  Button,
  VStack
} from '@chakra-ui/react'
import { usePermissions } from '@hooks'
import { LayoutGrid } from '@blueprinthq/joy'
import { ClinicianOverride, Loading } from '@components'
import { endpoints } from '@api'
import { useHistory } from 'react-router-dom'

import { SessionContainer } from './components'
import { SessionNotesRemainingBanner } from '../sessions/components'
import SessionNotesRemainingWarning from '../sessions/components/session-notes-remaining-warning'
import { useSessionControllerGetSessionsList } from '~/clinician-api'

const EBCSessionDashboard = () => {
  const [overrideClinicianId, setOverrideClinicianId] = useState(null)
  const { hasPermission } = usePermissions()
  const history = useHistory()

  const {
    data: sessionsData,
    isLoading: isSessionsLoading,
    refetch: refetchSessions
  } = useSessionControllerGetSessionsList({
    includeRecordingDrafts: 'true',
    overrideClinicianId: overrideClinicianId || undefined
  })

  const { data: user } = useQuery(
    endpoints.getUserAccount.getCacheId(),
    endpoints.getUserAccount.request
  )

  const hasSupervisorRole = user?.roles?.some(role =>
    ['supervisor_clinician', 'supervisor', 'clinicadmin'].includes(role.name)
  )

  const hasAdminRole = user?.roles?.some(role => role.name === 'orgadmin')

  const showDropdown =
    hasPermission('*:any:*:*') || hasSupervisorRole || hasAdminRole

  if (!user) return null

  return (
    <Container mt={'24px'}>
      <LayoutGrid>
        <GridItem
          colSpan={{
            base: 12,
            sm: 6,
            md: 6
          }}
          colStart={{
            base: 0,
            sm: 2,
            md: 4
          }}
        >
          <Flex direction="column" align="space-between" justify="center">
            <Flex align="center" justify="space-between" mb={2}>
              <Text as="h1" fontSize="xl" fontWeight="bold">
                Sessions
              </Text>
              <Button
                data-click-event-name="Sessions Index -> Clicked Start Session"
                onClick={() => history.push('/start-session/select-client')}
                w="146px"
              >
                New Session
              </Button>
            </Flex>
            <VStack spacing={4} align="stretch">
              {showDropdown && (
                <ClinicianOverride
                  user={user}
                  canViewOrgDropdown={hasAdminRole}
                  onChange={id => setOverrideClinicianId(id)}
                />
              )}
              <SessionNotesRemainingBanner />
              <SessionNotesRemainingWarning />
              <GridItem
                display={{ base: 'block', md: 'none' }}
                colSpan={{
                  base: 12,
                  md: 3
                }}
                colStart={{
                  base: 0,
                  md: 10
                }}
                mb={8}
              ></GridItem>
            </VStack>
          </Flex>
          <Box mb="64px" mt={4}>
            {isSessionsLoading ? (
              <Loading />
            ) : (
              <SessionContainer
                data={sessionsData}
                isLoading={isSessionsLoading}
                refetchSessions={refetchSessions}
              />
            )}
          </Box>
        </GridItem>
      </LayoutGrid>
    </Container>
  )
}

export default EBCSessionDashboard
