import React, { useEffect, useMemo, useState } from 'react'
import { AutocompleteInput } from '@blueprinthq/joy'
import { useClinicControllerSearchClinics } from '~/clinician-api'
import { ClinicSearchResultDto } from '~/clinician-api/models'
import { debounce } from 'lodash'
import { Clinic } from './models'

export const SuperAdminClinicSearch = ({
  onClinicSelected,
  selectedClinicName
}: {
  onClinicSelected: (selectedClinic: Clinic) => void
  selectedClinicName: string
}) => {
  const [clinicSearchValue, setClinicSearchValue] = useState(
    selectedClinicName ?? ''
  )

  const {
    refetch,
    data: clinicSearchResults = { clinics: [] }
  } = useClinicControllerSearchClinics(
    { s: clinicSearchValue },
    {
      query: { enabled: false }
    }
  )

  const debouncedSearch = useMemo(
    () => debounce(refetch, 500, { trailing: true }),
    []
  )

  const updateClinicSearchValue = (val: string) => {
    setClinicSearchValue(val)
    if (val.length >= 1) {
      debouncedSearch()
    }
  }

  useEffect(() => {
    setClinicSearchValue(selectedClinicName ?? '')
  }, [selectedClinicName])

  return (
    <AutocompleteInput
      placeholder="Search clinics"
      onSelectSuggestion={({ selectedItem }) => {
        let clinic = selectedItem as ClinicSearchResultDto
        onClinicSelected(
          (({
            id,
            name,
            displayId: display_id,
            organizationId: organization_id
          }) => ({ id, name, display_id, organization_id }))(clinic)
        )
      }}
      suggestionToString={(suggestion: unknown) => {
        return (suggestion as ClinicSearchResultDto).name
      }}
      suggestions={
        clinicSearchValue.length > 0 ? clinicSearchResults.clinics : []
      }
      size={'md'}
      mr={{ base: 0, md: '16px' }}
      mb={{ base: '4px', md: 0 }}
      borderColor="gray.300"
      height={'40px'}
      minWidth={'200px'}
      onChange={e => updateClinicSearchValue(e)}
      value={clinicSearchValue}
      label={''}
      showClear={clinicSearchValue.length > 0}
      onClear={() => setClinicSearchValue('')}
    />
  )
}
