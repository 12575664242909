import React from 'react'

const EmailFilled = () => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.8333 2.66666H3.16659C2.43325 2.66666 1.83992 3.26666 1.83992 3.99999L1.83325 12C1.83325 12.7333 2.43325 13.3333 3.16659 13.3333H13.8333C14.5666 13.3333 15.1666 12.7333 15.1666 12V3.99999C15.1666 3.26666 14.5666 2.66666 13.8333 2.66666ZM13.8333 5.33332L8.49992 8.66666L3.16659 5.33332V3.99999L8.49992 7.33332L13.8333 3.99999V5.33332Z"
        fill="white"
      />
    </svg>
  )
}

export default EmailFilled
