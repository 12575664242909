import React, { useState, useEffect } from 'react'
import {
  Text,
  VStack,
  Button,
  FormControl,
  FormLabel,
  Box,
  Flex,
  Alert,
  Spinner
} from '@chakra-ui/react'
import {
  DialogContainer,
  DialogHeader,
  DialogBody
} from '@handlers/sessions/components/dialog'
import { useQuery } from 'react-query'
import { useExperienceManager } from '@hooks'
import { endpoints } from '@api'
import { Group } from '@components/icons'
import { TrashcanIcon, Assist, AccountIcon, PeopleIcon } from '@blueprinthq/joy'
import { IconSelect } from './'
import { Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import _ from 'lodash'
import { NoteTypeDropdown } from './note-type-dropdown'
import { TreatmentApproachDropdown } from './treatment-approach-dropdown'
import Warning from '@material-ui/icons/Warning'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'

type ProgressNoteTypeT = {
  id: string
  sessionType: string
  display: string
  noteGroup: string
}

interface EndSessionModalProps {
  isOpen: boolean
  onClose: () => void
  noteType: string
  treatmentApproach: string | null
  onEnd: (args: any) => Promise<void>
  onDiscard: () => Promise<void>
  isEnding: boolean
  isUploadingAudio: boolean
  showRecordingLengthWarning: boolean
}

const sessionTypes = [
  {
    value: 'individual',
    title: 'Individual',
    icon: <AccountIcon />
  },
  {
    value: 'couple',
    title: 'Couple',
    icon: <PeopleIcon />
  },
  {
    value: 'group',
    title: 'Group',
    icon: <Group />
  }
]

const EndSessionModalComponent = ({
  isOpen,
  onClose,
  noteType,
  treatmentApproach,
  onEnd,
  onDiscard,
  isEnding,
  isUploadingAudio,
  showRecordingLengthWarning
}: EndSessionModalProps) => {
  const [isDiscardSelected, setIsDiscardSelected] = useState<boolean>(false)
  const [showSlowUpload, setShowSlowUpload] = useState<boolean>(false)
  const [ignoreRecordingLengthWarning, setIgnoreRecordingLengthWarning] = useState<boolean>(false)

  useEffect(() => {
    if (isUploadingAudio && isEnding) {
      const timeout = setTimeout(() => {
        setShowSlowUpload(true)
      }, 5000)
      return () => clearTimeout(timeout)
    }
  })

  const { data: user } = useQuery(
    endpoints.getUserAccount.getCacheId(),
    () => endpoints.getUserAccount.request()
  )

  const {
    data: { progressNoteTypes }
  }: any = useQuery(
    [
      endpoints.getProgressNoteTypes.getCacheId(),
      user?.clinic?.organization_id
    ],
    () =>
      endpoints.getProgressNoteTypes.request({
        organizationId: user?.clinic?.organization_id
      }),
    {
      initialData: {
        progressNoteTypes: []
      },
      enabled: !!user
    }
  )

  const { data, isLoading } = useQuery(
    [
      endpoints.getProgressNoteSettings.getCacheId(),
      user?.clinic?.organization_id
    ],
    () =>
      endpoints.getProgressNoteSettings.request({
        organizationId: user?.clinic?.organization_id
      }),
    {
      enabled: !!user?.clinic?.organization_id
    }
  )

  const handleEndSession = async (
    noteType: string,
    treatmentApproach: string | null
  ) => {
    await onEnd({
      noteType,
      treatmentApproach: treatmentApproach === 'none' ? null : treatmentApproach
    })
  }

  const handleDeleteSession = async () => {
    await onDiscard()
  }

  const handleClose = () => {
    if (!isEnding) {
      setIsDiscardSelected(false)
      onClose()
    }
  }

  const noteTypeOptions = progressNoteTypes.map((note: ProgressNoteTypeT) => {
    const id = note.id
    const sessionType = note.sessionType
    const display = note.display
    const noteGroup = note.noteGroup
    return {
      id,
      sessionType,
      display,
      noteGroup
    }
  })

  const treatmentApproachOptions =
    // @ts-ignore
    data?.preferenceOptions?.treatmentApproach?.options?.map((option: any) => {
      return {
        id: option.value,
        display: option.label
      }
    }) || []

  const groupedNoteTypes = _.groupBy(noteTypeOptions, 'sessionType')

  const sessionTypeOptions = sessionTypes.filter(t =>
    Object.keys(groupedNoteTypes).includes(t.value)
  )

  const defaultNoteType = noteTypeOptions.find(
    (t: ProgressNoteTypeT) =>
      t.id === noteType ??
      t.id === user?.clinic?.organization?.default_note_type
  )

  const defaultTreatmentApproach = treatmentApproachOptions.find((o: any) =>
    treatmentApproach ? o.id === treatmentApproach : o.id === 'none'
  )

  const { isDocumentationAutomationEnabled } = useExperienceManager()

  const DiscardContent = () => (
    <VStack spacing={6}>
      <Text textAlign="center">
        This session will be discarded and no progress note summary will be
        generated. This cannot be undone.
      </Text>
      <VStack w="100%">
        <Button
          bg="error"
          isFullWidth
          size="lg"
          onClick={handleDeleteSession}
          isLoading={isEnding}
        >
          Delete Session
        </Button>
        <Button
          _focus={{ outline: 'none' }}
          variant="outline"
          isFullWidth
          size="lg"
          onClick={handleClose}
        >
          Cancel
        </Button>
      </VStack>
    </VStack>
  )

  const SessionDurationWarningContent = () => (
    <VStack spacing={4} align="stretch">
      <p style={{
        textAlign: 'center',
        marginBottom: '32px',
        paddingLeft: '16px',
        paddingRight: '16px',
        paddingTop: '16px',
        fontSize: '20px',
      }}>
        This session may be too short to generate a high-quality note. Are you sure you want to finish?
      </p>
      <Button colorScheme="primary" height="48px" onClick={handleClose} width="100%">
        Resume session
      </Button>
      <Button variant="outline" height="48px" onClick={() => setIgnoreRecordingLengthWarning(true)} width="100%">
        End the session
      </Button>
      <Button 
        variant="ghost"
        height="48px"
        onClick={() => setIsDiscardSelected(true)}
        width="100%"
        leftIcon={<TrashcanIcon fill="error" height="16px" width="16px" />}
      >
        Delete session
      </Button>
    </VStack>
  )

  if (!defaultNoteType || isLoading) return null
  return (
    <DialogContainer isOpen={isOpen} onClose={handleClose}>
      {showSlowUpload && !isDiscardSelected ? (
        <>
          <DialogHeader
            text={'Ending Session'}
            onClose={isEnding ? undefined : handleClose}
          />
          <DialogBody>
            <Flex justifyContent="center" align="center" flexDirection="column">
              <Spinner thickness="4px" h="48px" w="48px" mb={2} />
              <Text mb={2} fontWeight="bold">
                Uploading audio...
              </Text>
              <Text mb={6}>This process could take a few minutes.</Text>
              <Alert bg="#FFF9E5" borderRadius="8px" mb={6}>
                <Flex alignItems="center">
                  <Box display="flex" alignItems="center" mr={2}>
                    <Warning style={{ color: '#F8D557' }} />
                  </Box>
                  <Box>
                    <Text mb="xsmall">
                      <Text as="span" fontWeight="bold">
                        Important:
                      </Text>
                      <Text as="span">
                        {` Leave this tab open until the uploading process has finished.`}
                      </Text>
                    </Text>
                  </Box>
                </Flex>
              </Alert>
              <Text textAlign="center" mb={6}>
                If you need to jump to your next session, you can start
                recording in a new tab:
              </Text>
              <Button
                size="lg"
                isFullWidth
                leftIcon={
                  <OpenInNewIcon style={{ width: '16px', height: '16px' }} />
                }
                onClick={() => {
                  window.open('/sessions', '_blank')
                }}
              >
                Open a new tab
              </Button>
            </Flex>
          </DialogBody>
        </>
      ) : (
        <>
          <DialogHeader
            text={isDiscardSelected ? 'Are you sure?' : 'End Session?'}
            onClose={isEnding ? undefined : handleClose}
          />
          <DialogBody>
            <Box m="0 auto" maxWidth="750px">
              {isDiscardSelected ? (
                <DiscardContent />
              ) : (showRecordingLengthWarning && !ignoreRecordingLengthWarning) ? (
                <SessionDurationWarningContent />
              ) : (
                <Formik
                  initialValues={{
                    noteType: defaultNoteType,
                    sessionType: defaultNoteType.sessionType,
                    treatmentApproach: defaultTreatmentApproach
                  }}
                  validationSchema={Yup.object({
                    noteType: Yup.object().required(
                      'Progress note type is required'
                    )
                  })}
                  onSubmit={async values =>
                    await handleEndSession(
                      values.noteType.id,
                      values.treatmentApproach.id
                    )
                  }
                >
                  {({ setFieldValue, values }) => (
                    <Form>
                      <VStack spacing={6} w="100%">
                        {isDocumentationAutomationEnabled && (
                          <>
                            <Field name="sessionType">
                              {({ field }: any) => (
                                <FormControl>
                                  <FormLabel fontWeight="bold">
                                    Session Type:
                                  </FormLabel>
                                  <IconSelect
                                    {...field}
                                    onChange={(value: string) => {
                                      setFieldValue(field.name, value)
                                      setFieldValue(
                                        'noteType',
                                        groupedNoteTypes[value][0]
                                      )
                                    }}
                                    selectedValue={field.value}
                                    options={sessionTypeOptions}
                                  />
                                </FormControl>
                              )}
                            </Field>
                            <Field name="noteType">
                              {({ field }: any) => (
                                <FormControl>
                                  <Box id="pendo-note-type">
                                    <FormLabel fontWeight="bold">
                                      Note Type:
                                    </FormLabel>
                                    <NoteTypeDropdown
                                      optionValue={field.value.id}
                                      onChange={value => {
                                        setFieldValue(
                                          'noteType',
                                          progressNoteTypes.find(
                                            (note: ProgressNoteTypeT) =>
                                              note.id === value
                                          )
                                        )
                                      }}
                                      progressNoteTypes={
                                        groupedNoteTypes[values.sessionType]
                                      }
                                    />
                                  </Box>
                                </FormControl>
                              )}
                            </Field>
                            <Field name="treatmentApproach">
                              {({ field }: any) => (
                                <FormControl>
                                  <FormLabel fontWeight="bold">
                                    Primary Treatment Approach:
                                  </FormLabel>

                                  <TreatmentApproachDropdown
                                    optionValue={field.value.id}
                                    onChange={value => {
                                      const selectedOption = treatmentApproachOptions.find(
                                        (o: any) => o.id === value
                                      )
                                      setFieldValue(
                                        'treatmentApproach',
                                        selectedOption
                                      )
                                    }}
                                    options={treatmentApproachOptions}
                                  />
                                </FormControl>
                              )}
                            </Field>
                          </>
                        )}
                        <VStack w="100%">
                          {isDocumentationAutomationEnabled ? (
                            <Button
                              bg="primary"
                              isFullWidth
                              size="lg"
                              type="submit"
                              isLoading={isEnding}
                              loadingText={
                                isUploadingAudio ? 'Uploading audio...' : ''
                              }
                              leftIcon={<Assist fill="white" />}
                            >
                              Generate Note
                            </Button>
                          ) : (
                            <Button
                              bg="primary"
                              isFullWidth
                              size="lg"
                              onClick={() =>
                                handleEndSession(
                                  user?.clinic?.organization
                                    ?.default_note_type || 'soap',
                                  null
                                )
                              }
                            >
                              End Session
                            </Button>
                          )}
                          <Button
                            variant="ghost"
                            disabled={isEnding}
                            onClick={() => setIsDiscardSelected(true)}
                            leftIcon={<TrashcanIcon fill="error" />}
                          >
                            Delete Session
                          </Button>
                        </VStack>
                      </VStack>
                    </Form>
                  )}
                </Formik>
              )}
            </Box>
          </DialogBody>
        </>
      )}
    </DialogContainer>
  )
}

export const EndSessionModalV2 = React.memo(
  EndSessionModalComponent,
  (props, nextProps) => {
    const { onClose, onEnd, onDiscard, ...restProps } = props

    const {
      onClose: nextOnClose,
      onEnd: nextOnEnd,
      onDiscard: nextOnDiscard,
      ...restNextProps
    } = nextProps

    return _.isEqual(restProps, restNextProps)
  }
)
