import React, { useCallback, useEffect, useState } from 'react'
import { useQuery, useMutation } from 'react-query'
import { PatientListContainer } from '../PatientListContainer'
import { PatientsListHeader } from '../PatientsListHeader'
import { useToast, Container, GridItem } from '@chakra-ui/react'
import { useStoreActions, useStoreState } from 'easy-peasy'
import { endpoints } from '../../../api'
import { LayoutGrid } from '@blueprinthq/joy'
import { useExperienceManager } from '@hooks'

export default function PatientsBase(props) {
  const user = useStoreState(state => state.auth.user)
  const { selectedClinic, selectedClinicianId, page } = useStoreState(
    state => state.patientList
  )
  const { selectClinic, selectClinicianId, setPage } = useStoreActions(
    actions => actions.patientList
  )
  const [canViewAllClinicians, setCanViewAllClinicians] = useState(false)

  useEffect(() => {
    if (!selectedClinic.id && !selectedClinicianId) {
      selectClinic(user.clinic)
      selectClinicianId(user.id)
    }
  }, [user])

  const toast = useToast()

  const { mutateAsync: executeReinvite } = useMutation(
    endpoints.postReinviteToDownload.request,
    {
      onSuccess: () =>
        toast({
          description: 'Invitation was sent!',
          status: 'success',
          isClosable: true,
          duration: 2000
        }),
      onError: () =>
        toast({
          title: 'Error',
          description: 'Invitation was not sent.',
          status: 'error',
          isClosable: true,
          duration: 2000
        })
    }
  )

  const { data: counts } = useQuery(
    [
      selectedClinic,
      selectedClinicianId,
      endpoints.getPatientCounts.getCacheId()
    ],
    () =>
      endpoints.getPatientCounts.request({
        clinic_id: selectedClinic.id,
        clinician_id:
          selectedClinicianId === '' ? undefined : selectedClinicianId
      })
  )

  const handleClincianOverrideChange = (clinicianid, clinic) => {
    selectClinicianId(clinicianid)
    selectClinic(clinic)
    setPage(1)
  }

  const sendPatientDownloadInvite = useCallback(async patientId => {
    await executeReinvite({ userId: patientId })
  }, [])

  // page must be decremented once due to a discrempency between where react-table and matierial-ui start counting pages (0 vx 1)
  const updatePage = (event, page) => {
    setPage(page)
  }

  const { isAdmin } = useExperienceManager()

  useEffect(() => {
    if (user) {
      setCanViewAllClinicians(
        isAdmin
          ? true
          : !user.roles.some(r => r.name === 'supervisor_clinician')
      )
    }
  }, [user])

  return (
    <Container my="large" bg="#ffffff" h="auto">
      <LayoutGrid>
        <GridItem colSpan={12}>
          <PatientsListHeader
            counts={counts}
            onClinicianOverrideChange={handleClincianOverrideChange}
            canViewAllClinicians={canViewAllClinicians}
            currentClinicianId={selectedClinicianId}
            currentClinic={selectedClinic}
            {...props}
          />
          <PatientListContainer
            currentClinicianId={selectedClinicianId}
            currentClinicId={selectedClinic.id}
            sendInvite={sendPatientDownloadInvite}
            currentPage={page}
            updatePage={updatePage}
            styles={props.styles}
          />
        </GridItem>
      </LayoutGrid>
    </Container>
  )
}
