import React, { useMemo } from 'react'
import { Box, Flex } from '@chakra-ui/react'
import { TextField, Select } from '@blueprinthq/joy'
import { TREATMENT_APPROACHES } from '@constants/index'
import { InterventionList } from './intervention-list'
import {
  useInterventionsControllerGetSavedForLaterInterventions,
  useInterventionsControllerV1GetAllInterventions
} from '~/clinician-api'
import { useTreatmentPlan } from '@hooks'
import { useAssistPanelContext } from '../../../in-session-assist-panel/context'

export const InterventionLibrary = () => {
  const {
    interventionLibraryData,
    saveInterventionLibraryData
  } = useAssistPanelContext()

  const { client } = useTreatmentPlan()

  const {
    data: savedForLaterInterventions,
    isLoading: savedForLaterLoading
  } = useInterventionsControllerGetSavedForLaterInterventions(
    client?.id ?? '',
    {
      query: {
        initialData: [],
        enabled: !!client?.id
      }
    }
  )

  const {
    data: allInterventions,
    isLoading: allInterventionsLoading
  } = useInterventionsControllerV1GetAllInterventions({ isGuidedOnly: 'true' })

  const filteredInterventions = useMemo(() => {
    const sortedInterventions = allInterventions?.sort(
      (a, b) => a.name.localeCompare(b.name) ?? []
    )

    let newInterventions = sortedInterventions
    if (interventionLibraryData.search.length) {
      newInterventions = newInterventions?.filter(
        (i: any) =>
          i.name
            .toLowerCase()
            .includes(interventionLibraryData.search.toLowerCase()) ||
          i?.shortDescription
            ?.toLowerCase()
            .includes(interventionLibraryData.search.toLowerCase())
      )
    }
    if (interventionLibraryData.approach.value !== '') {
      newInterventions = newInterventions?.filter((i: any) =>
        i.treatmentModality
          .toLowerCase()
          .includes(interventionLibraryData.approach.value.toLowerCase())
      )
    }

    const finalInterventions = newInterventions?.map(ni => {
      const isAlreadySaved = savedForLaterInterventions?.some(
        si => si.interventionId === ni.id
      )
      return { ...ni, isAlreadySaved }
    })

    return finalInterventions
  }, [
    interventionLibraryData.search,
    interventionLibraryData.approach,
    allInterventions,
    savedForLaterInterventions
  ])

  return (
    <Box h="calc(100vh - 135px)">
      <Flex px="24px" align="center" justify="space-evenly">
        <Box w="50%" mt="medium" mb="small" mr="12px">
          <TextField
            showClear
            onClear={() => saveInterventionLibraryData({ search: '' })}
            label="Search interventions"
            onChange={e =>
              saveInterventionLibraryData({ search: e.currentTarget.value })
            }
            value={interventionLibraryData.search}
          />
        </Box>
        <Box w="50%" mt="medium" mb="small" ml="12px">
          <Select
            value={interventionLibraryData.approach}
            label="Treatment Approach"
            options={TREATMENT_APPROACHES}
            valueKey="value"
            labelKey="label"
            onChange={option =>
              saveInterventionLibraryData({ approach: option })
            }
            menuListProps={{
              height: '30vh',
              overflowY: 'scroll'
            }}
          />
        </Box>
      </Flex>
      <InterventionList
        interventionList={filteredInterventions}
        clientId={client?.id ?? ''}
      />
    </Box>
  )
}
