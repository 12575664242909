import React, { useState } from 'react'
import { Box, Text, Link } from '@chakra-ui/react'
import { motion, AnimatePresence } from 'framer-motion'
import InSessionAssistPanel from './in-session-assist-panel'
import { ClientAccountResponse } from '@api'
import { useExperienceManager, usePanelManager } from '@hooks'
import { AssistDrawerHeader } from './assist-drawer-header'
import queryString from 'query-string'
import {useLocation } from 'react-router-dom'

const slideAnimation = {
  initial: { y: '100%' },
  animate: {
    y: 0,
    transition: {
      type: 'spring',
      damping: 30,
      stiffness: 300
    }
  },
  exit: {
    y: '100%',
    transition: {
      type: 'spring',
      damping: 30,
      stiffness: 300
    }
  }
}

const backdropAnimation = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 }
}

interface MobileCollapsedAssistDrawerProps {
  client?: ClientAccountResponse
  sessionId: string
}

export const MobileAssistDrawer = ({
  client,
  sessionId
}: MobileCollapsedAssistDrawerProps) => {
  const {
    isPlusPlanEnabled,
  } = useExperienceManager()

  const {
    isMobileAssistOpen,
    toggleMobileAssist
  } = usePanelManager()

  const toggleAssistFullScreen = () => {
    toggleMobileAssist()
  }

  const location = useLocation()

  const queryParams = queryString.stringify({
    redirectPath: location.pathname,
    plan: 'plus',
    prepGenerationSessionId: sessionId
  })

  const upgradeUrl = `/settings/update-your-plan?${queryParams}`

  return (
    <>
      <AssistDrawerHeader
        firstName={client?.first_name}
        lastName={client?.last_name}
        toggleAssistFullScreen={toggleMobileAssist}
        isExpanded={false}
        showShadow={true}
      />

      <AnimatePresence>
        {isMobileAssistOpen && (
          <>
            {!isPlusPlanEnabled ? (
              <>
                <Box
                  position="fixed"
                  top={0}
                  left={0}
                  right={0}
                  bottom={0}
                  mt="0px !important"
                  bg="blackAlpha.600"
                  zIndex="modal"
                  as={motion.div}
                  {...backdropAnimation}
                  onClick={toggleAssistFullScreen}
                />
                <Box
                  data-testid="mobile-assist-upgrade-drawer"
                  as={motion.div}
                  position="fixed"
                  top="50%"
                  left={0}
                  right={0}
                  bottom={0}
                  zIndex={'modal'}
                  bg="white"
                  borderTopRadius="24px"
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  {...slideAnimation}
                >
                  <AssistDrawerHeader
                    firstName={client?.first_name}
                    lastName={client?.last_name}
                    toggleAssistFullScreen={toggleAssistFullScreen}
                    isExpanded={true}
                    showShadow={false}
                  />
                  <Box px="24px" pb="32px" pt="8px">
                    <Text textAlign="left" fontSize="16px">
                      Assist is your AI-enabled clinical companion supporting you before, during, and after each session. Full Assist features are available with a Blueprint Pro plan.
                    </Text>
                    <Link href={upgradeUrl} color="blue.500" mt="32px" display="inline-block">
                      Upgrade to Pro
                    </Link>
                  </Box>
                </Box>
              </>
            ) : (
              <Box
                data-testid="mobile-open-assist-drawer"
                as={motion.div}
                position="fixed"
                top={0}
                left={0}
                right={0}
                bottom={0}
                zIndex={'modal'}
                bg="white"
                {...slideAnimation}
                style={{
                  boxShadow: '0px -4px 25px rgba(0, 0, 0, 0.1)',
                  borderTopLeftRadius: '12px',
                  borderTopRightRadius: '12px',
                  marginTop: '0px'
                }}
              >
                <InSessionAssistPanel
                  client={client}
                  toggleAssist={toggleAssistFullScreen}
                  fullScreen={true}
                />
              </Box>
            )}
          </>
        )}
      </AnimatePresence>
    </>
  )
}