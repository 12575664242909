import React, { useMemo } from 'react'
import { useQuery } from 'react-query'
import {
  Box,
  Flex,
  VStack,
  HStack,
  Text,
  Select,
  Radio,
  RadioGroup,
  Link,
  Button
} from '@chakra-ui/react'
import InputMask from 'react-input-mask'
import { TextField, NewTabIcon } from '@blueprinthq/joy'
import { Formik, Form, Field, FieldProps, FormikProps } from 'formik'
import { useCadenceOptions } from '../use-cadence-options'
import { Clipboard } from '@components/icons'
import { endpoints } from '@api'
import * as Yup from 'yup'
import { YupPhone } from '@constants/formValidation'
import {
  AssignWorksheetFieldsSchema,
  AssignWorksheetFields
} from './assign-worksheets-form'
import {
  formatCadence,
  buildAssessmentPreviewUrl,
  formatPhone
} from '@utilities'
import {
  AssignableAssessment,
  AssignableWorksheet,
  Patient,
  AssistEnrollmentFormValues
} from '@handlers/sessions/types'

interface AssistEnrollmentFormProps {
  assessment?: AssignableAssessment | null
  client: Patient
  onSubmit: (values: any) => void
  children: (props: any) => React.ReactNode
  checkIn?: AssignableWorksheet | null
}

interface AssistEnrollmentFormFieldsProps {
  client: Patient
  formikProps: any
  assessments: any[]
  checkInId?: string
}

interface ActionButtonsProps {
  onCancel: () => void
  onSubmit: () => void
  isSubmitting: boolean
}

export const ActionButtons = ({
  onCancel,
  onSubmit,
  isSubmitting
}: ActionButtonsProps) => {
  return (
    <HStack 
      spacing={4} 
      w="100%"
      position={{ base: "fixed", md: "static" }}
      bottom={{ base: 0, md: "auto" }}
      left={{ base: 0, md: "auto" }}
      right={{ base: 0, md: "auto" }}
      p={{ base: "24px", md: 0 }}
      bg={{ base: "white", md: "transparent" }}
      borderTop={{ base: "1px solid", md: "none" }}
      borderColor="pale_gray"
      zIndex={{ base: 999, md: "auto" }}
      boxShadow={{ base: "0px -4px 12px rgba(0, 0, 0, 0.05)", md: "none" }}
      transform="translate3d(0,0,0)"
      willChange="transform"
    >
      <Box flex="1" display={{ base: "block", md: "block" }}>
        <Text display={{ base: "block", md: "block" }}>What your client will see:</Text>
        <Link
          mr={2}
          color="primary"
          href={`${process.env.REACT_APP_HELP_URL}/en/articles/9821278-understanding-the-client-experience-assessments-and-worksheets`}
          target="_blank"
          _hover={{ textDecoration: 'none' }}
          _focus={{ outline: 'none' }}
          display={{ base: "block", md: "inline" }}
          mb={{ base: 2, md: 0 }}
        >
          Blueprint Client Guide
        </Link>
      </Box>
      <Flex flex="1">
        <Button onClick={onCancel} variant="outline" isFullWidth bg="white">
          Cancel
        </Button>
        <Button isLoading={isSubmitting} onClick={onSubmit} isFullWidth>
          Confirm
        </Button>
      </Flex>
    </HStack>
  )
}

export const AssistEnrollmentForm = ({
  assessment,
  client,
  onSubmit,
  children,
  checkIn
}: AssistEnrollmentFormProps) => {
  const {
    isLoading: areAssessmentsLoading,
    data: clientAssessmentsGroupedByAssignee
  } = useQuery(
    [endpoints.getAllAssessmentsForClientByAssignee.getCacheId(), client.id],
    () =>
      endpoints.getAllAssessmentsForClientByAssignee.request({ id: client.id }),
    {
      placeholderData: [{ assessments: [] }],
      enabled: !!client.id
    }
  )

  const handleSubmit = (values: AssistEnrollmentFormValues) => {
    onSubmit(values)
  }

  // @ts-ignore
  const allAssessments = clientAssessmentsGroupedByAssignee[0].assessments

  const schema = useMemo(() => {
    const shape: any = {
      deliveryMethod: Yup.string().required('Required'),
      email: Yup.string().when('deliveryMethod', {
        is: (value: string) => value === 'email' || value === 'both',
        then: Yup.string()
          .email('Invalid email')
          .required('Email is required')
      }),
      phone: Yup.string().when('deliveryMethod', {
        is: (value: string) => value === 'text' || value === 'both',
        then: YupPhone.required('Phone is required')
      })
    }

    if (assessment) {
      shape.assessment = Yup.object().shape({
        clinicAssessmentId: Yup.string(),
        cadence: Yup.string(),
        suggestionId: Yup.string()
      })
    }
    if (checkIn) {
      shape.checkIn = AssignWorksheetFieldsSchema
    }

    return Yup.object().shape(shape)
  }, [assessment, checkIn])

  if (areAssessmentsLoading) return null

  const initialValues = {
    deliveryMethod:
      client.email && client.phone_number
        ? 'both'
        : !client.email && client.phone_number
        ? 'text'
        : 'email',
    email: client.email || '',
    phone: client.phone_number ? formatPhone(client.phone_number, true) : '',
    checkIn: checkIn ? AssignWorksheetFieldsSchema.getDefault() : null,
    assessment: assessment
      ? {
          clinicAssessmentId: assessment.clinicAssessmentId,
          cadence: formatCadence(
            assessment.cadenceValue,
            assessment.cadenceUnit
          ),
          contentSuggestionId: assessment.contentSuggestionId
        }
      : null
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {(formikProps: FormikProps<AssistEnrollmentFormValues>) => (
        <Form style={{ height: "100%" }}>
          <Box 
            height={{ base: "calc(100vh - 180px)", md: "auto" }}
            overflowY={{ base: "auto", md: "visible" }}
            pb={{ base: "100px", md: 0 }}
            position="relative"
            zIndex={1}
          >
            {children({
              formikProps,
              allAssessments,
              checkInId: checkIn?.checkInId
            })}
          </Box>
        </Form>
      )}
    </Formik>
  )
}

export const AssistEnrollmentFormFields = ({
  client,
  formikProps,
  assessments = [],
  checkInId
}: AssistEnrollmentFormFieldsProps) => {
  const { cadenceOptions } = useCadenceOptions()
  const { values, errors, setFieldValue, touched } = formikProps

  const assessment = assessments.find(
    (a: any) => values.assessment?.clinicAssessmentId === a.id
  )

  if (!assessment && !checkInId) return null

  return (
    <VStack align="stretch" spacing={6}>
      {assessment && (
        <>
          <Flex
            flexDir="row"
            justifyContent="left"
            gap="small"
            border="1px solid"
            borderColor="pale_gray"
            borderRadius="4px"
            p="small"
          >
            <Flex flexDir="column" justifyContent="center">
              <Clipboard />
            </Flex>
            <Flex flex="1" flexDir="column" justifyContent="center">
              <Text fontWeight="bold">{assessment?.name}</Text>
              <Text>{assessment?.full_name}</Text>
              <Text textColor="gray">
                {assessment?.disorder} |{' '}
                {assessment?.content?.sections[0]?.questions?.length} questions
              </Text>
            </Flex>
            <Flex alignItems="center" color="primary">
              <Link
                mr={2}
                href={buildAssessmentPreviewUrl(assessment.assessment_id)}
                target="_blank"
                _hover={{ textDecoration: 'none' }}
                _focus={{ outline: 'none' }}
              >
                Preview
              </Link>
              <NewTabIcon h="16px" w="16px" fill="#2d54e8" />
            </Flex>
          </Flex>
          <Box>
            <Text fontWeight="bold">Frequency:</Text>
            <Field name="assessment.cadence">
              {({ field }: FieldProps) => (
                <Select
                  border="1px solid"
                  borderColor="pale_gray"
                  borderRadius="4px"
                  h="48px"
                  _focus={{
                    outline: 'none'
                  }}
                  {...field}
                >
                  {cadenceOptions.map(cadence => {
                    return (
                      <option key={cadence} value={cadence}>
                        {cadence}
                      </option>
                    )
                  })}
                </Select>
              )}
            </Field>
          </Box>
        </>
      )}
      <Box>
        {checkInId && (
          <AssignWorksheetFields
            checkInId={checkInId}
            parentPath="checkIn"
            client={client}
          />
        )}
      </Box>
      <VStack align="stretch" spacing={4}>
        <Text fontWeight="bold">Delivery Method:</Text>
        <RadioGroup
          name="deliveryMethod"
          value={values.deliveryMethod}
          onChange={value => setFieldValue('deliveryMethod', value)}
        >
          <HStack spacing={5}>
            <Field as={Radio} name="deliveryMethod" value="email">
              Email
            </Field>
            <Field as={Radio} name="deliveryMethod" value="text">
              Text Message
            </Field>
            <Field as={Radio} name="deliveryMethod" value="both">
              Both
            </Field>
          </HStack>
        </RadioGroup>
        {['email', 'both'].includes(values.deliveryMethod) && (
          <Field name="email">
            {({ field }: FieldProps) => (
              <TextField
                label="email"
                simple
                placeholder="Client's email address"
                isInvalid={errors.email && touched.email}
                errorText={errors.email}
                {...field}
              />
            )}
          </Field>
        )}
        {['text', 'both'].includes(values.deliveryMethod) && (
          <Field name="phone">
            {({ field }: FieldProps) => (
              <Box width="100%">
                <InputMask
                  mask="(999) 999-9999"
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  value={field.value}
                >
                  {() => (
                    <TextField
                      {...field}
                      simple
                      type="tel"
                      placeholder="Client's cell phone"
                      errorText={errors[field.name]}
                      isInvalid={errors[field.name] && touched[field.name]}
                      label="Phone Number"
                    />
                  )}
                </InputMask>
              </Box>
            )}
          </Field>
        )}
      </VStack>
      {assessment && (
        <Box
          px="22px"
          py={4}
          bg="rgba(45, 84, 232, 0.04)"
          borderRadius="8px"
          border="1px solid #E4E5E6"
        >
          <Text as="span">
            <Text as="span" fontWeight="bold">
              {client.first_name} {client.last_name}
            </Text>{' '}
            will receive a delivery of the
            <Text fontWeight="bold" as="span">
              {' '}
              {assessment?.name}
            </Text>{' '}
            assessment
            <Text as="span" fontWeight="bold">
              {' '}
              {values.assessment?.cadence?.toLocaleLowerCase()}
            </Text>{' '}
            starting
            <Text fontWeight="bold" as="span">
              {' '}
              today.
            </Text>
          </Text>
        </Box>
      )}
    </VStack>
  )
}
