import React from 'react'
import { Flex, Text, Button } from '@chakra-ui/react'
import { useExperienceManager } from '@hooks'
import {
  useClinicControllerGetPlanOptions,
  useClinicControllerGetCurrentPlan
} from '~/clinician-api'
import { useStoreState } from 'easy-peasy'
import moment from 'moment'

const SessionNotesRemainingWarning = () => {
  const { user } = useStoreState((state: any) => state.auth)
  const {
    isDocumentationAutomationFreePlan,
    isAdmin,
    documentationAutomationSessionsRemaining,
    documentationAutomationSessionsAllotted,
    isEvidenceBasedCareEnabled,
    isDocumentationAutomationEnterprise,
    showPartnerUI
  } = useExperienceManager()

  const {
    data: currentPlan,
    isLoading: isLoadingCurrentPlan
  } = useClinicControllerGetCurrentPlan(user.clinic.id)

  const {
    data: planOptions,
    isLoading: isLoadingPlanOptions
  } = useClinicControllerGetPlanOptions(user.clinic.id)

  if (isLoadingPlanOptions || isLoadingCurrentPlan) {
    return null
  }

  if (
    isEvidenceBasedCareEnabled ||
    !isAdmin ||
    isDocumentationAutomationFreePlan ||
    isDocumentationAutomationEnterprise ||
    documentationAutomationSessionsRemaining > 6 ||
    showPartnerUI
  ) {
    return null
  }

  const sortedPlanOptions = planOptions?.sort(
    (a, b) => a.sessionCount - b.sessionCount
  )

  const nextPlan = sortedPlanOptions?.find(
    option =>
      option.planType === currentPlan?.planType &&
      option.sessionCount > currentPlan?.sessionCount
  )

  return (
    <Flex
      flexDirection="column"
      p="12px 16px"
      bg="rgba(45, 84, 232, 0.04)"
      borderRadius="8px"
    >
      <Text fontWeight="bold">Your plan will auto-upgrade soon</Text>
      <Text>
        If you exceed {documentationAutomationSessionsAllotted} sessions before
        your next renewal date{' '}
        {moment(currentPlan?.billingEnd).format('MMM D, YYYY')}, we'll
        auto-upgrade you to the next tier ({nextPlan?.sessionCount} sessions per
        month) and extend your billing cycle to ensure uninterrupted use of
        Blueprint.{' '}
        <Button
          as="a"
          href={`${process.env.REACT_APP_HELP_URL}/en/articles/9953904-blueprint-plans-pricing`}
          target="_blank"
          rel="noopener noreferrer"
          variant="link"
          textDecoration="none"
          _focus={{ outline: 'none' }}
          m="0"
        >
          Learn more
        </Button>
      </Text>
    </Flex>
  )
}

export default SessionNotesRemainingWarning
