import {
  Formik,
  Form,
  Field,
  FieldArray,
  useFormikContext,
  FieldProps,
  FormikHelpers
} from 'formik'
import React, { useEffect, useRef, useMemo } from 'react'
import {
  Box,
  Input,
  Flex,
  VStack,
  HStack,
  Select,
  useBoolean,
  Textarea,
  Text,
  Container,
  GridItem,
  Stack,
  RadioGroup,
  Radio,
  Divider,
  Switch,
  FormControl,
  FormLabel,
  useToast,
  Alert,
  AlertIcon,
  Link
} from '@chakra-ui/react'
import {
  IconButton,
  Button,
  LayoutGrid,
  TrashcanIcon,
  ArrowDownIcon,
  ArrowUpIcon,
  CloseIcon,
  Checkbox,
  ArrowLeftIcon
} from '@blueprinthq/joy'
import { CirclePlus } from '@icons'
import * as Yup from 'yup'
import * as uuid from 'uuid'

import { CheckInDtoV2 } from '~/clinician-api/models'
import {
  useCheckinsControllerV1GetAllCheckIns,
  useCheckinsControllerV1SaveDraftCheckIn,
  useCheckinsControllerV1GetDraftCheckIn,
  useCheckinsControllerV1PublishDraftCheckIn,
  getCheckinsControllerV1GetDraftCheckInQueryKey,
  useCheckinsControllerV1CreateCheckIn,
  useCheckinsControllerV1ToggleCheckInIsCore,
  useCheckinsControllerV1ToggleCheckinIsAssist
} from '~/clinician-api'
import { useQueryParams } from '@hooks'
import { useHistory } from 'react-router-dom'
import { useQueryClient } from 'react-query'
import { getCheckinsControllerV1GetAllCheckInsQueryKey } from '~/clinician-api'

import { StickyFooter } from './components/sticky-footer'
import { buildCheckInPreviewUrl } from '@utilities'

interface CheckInEditorProps {
  checkIn: CheckInDtoV2 | undefined
  type: string | undefined | null
  isDraft: boolean
  draftVersionId?: string
}

const CheckInEditorSchema = Yup.object().shape({
  title: Yup.string(),
  description: Yup.string(),
  type: Yup.string(),
  questions: Yup.array().of(
    Yup.object().shape({
      type: Yup.string(),
      label: Yup.string(),
      multiselect: Yup.boolean(),
      answers: Yup.array().of(
        Yup.object().shape({
          label: Yup.string()
        })
      )
    })
  )
})

interface CheckInQuestionAnswer {
  id: string
  value?: number
  label: string
}

interface CheckInQuestion {
  id: string
  type:
    | 'free_text'
    | 'multiple_choice'
    | 'heading'
    | 'likert'
    | 'slider_vertical'
  label: string
  multiselect?: boolean
  answers?: CheckInQuestionAnswer[]
}

interface Worksheet {
  title: string
  type: string
  description: string
  nodes: CheckInQuestion[]
}

const NodeTextArea = () => {
  return <Textarea minHeight={150} />
}

const NodeHeading = ({ name }: { name: string }) => {
  return (
    <Box>
      <Text fontSize="lg">{name}</Text>
    </Box>
  )
}

const NodeMultipleChoice = ({
  isMultiselect = false,
  isMobileOnly = false,
  answers = []
}: {
  isMultiselect?: boolean
  isMobileOnly?: boolean
  answers?: CheckInQuestionAnswer[]
}) => {
  return (
    <RadioGroup>
      <Stack
        direction={'column'}
        spacing="0px"
        border="1px solid"
        borderColor="pale_gray"
        borderRadius="4px"
        divider={<Divider />}
      >
        {answers.map((option, i) => (
          <Box
            key={i}
            w="100%"
            _hover={{
              bg: !isMobileOnly ? 'blue.50' : 'initial'
            }}
          >
            {isMultiselect ? (
              <Checkbox p="xsmall" value={option.label.toString()} w="100%">
                {option.label.toString()}
              </Checkbox>
            ) : (
              <Radio p="xsmall" value={option.label.toString()} w="100%">
                {option.label.toString()}
              </Radio>
            )}
          </Box>
        ))}
      </Stack>
    </RadioGroup>
  )
}

interface CheckInNodeProps {
  index: number
  node: CheckInQuestion
  remove: () => void
  replace: (props: object) => void
  swap: (index: number) => void
  totalNodes: number
  number: number
  inputsTypes: string[]
}

const inputOptions = [
  {
    value: 'free_text',
    label: 'Freetext'
  },
  {
    value: 'multiple_choice',
    label: 'Multiple Choice'
  },
  {
    value: 'heading',
    label: 'Heading'
  },
  {
    value: 'likert',
    label: 'Likert Scale'
  }
]

const DEFAULT_LIKERT_ANSWERS = () => [
  {
    id: uuid.v4(),
    label: 'Not at all',
    value: 0
  },
  {
    id: uuid.v4(),
    label: 'Rarely',
    value: 1
  },
  {
    id: uuid.v4(),
    label: 'Somewhat',
    value: 2
  },
  {
    id: uuid.v4(),
    label: 'Often',
    value: 3
  },
  {
    id: uuid.v4(),
    label: 'Very much',
    value: 4
  }
]

const CheckInNode = ({
  index,
  node,
  remove,
  replace,
  swap,
  totalNodes,
  number,
  inputsTypes
}: CheckInNodeProps) => {
  const ref = useRef<HTMLDivElement | null>(null)
  const [isFocused, setFocus] = useBoolean()
  const { setFieldValue } = useFormikContext<Worksheet>()

  useEffect(() => {
    function handleClick(event: MouseEvent) {
      if (event.target && ref.current?.contains(event.target as Node)) {
        setFocus.on()
      } else {
        setFocus.off()
      }
    }

    document.addEventListener('click', handleClick)

    document.addEventListener('click', event => {
      if (event.target && !ref.current?.contains(event.target as Node)) {
        setFocus.off()
      }
    })
  }, [])

  return (
    <Box
      ref={ref}
      w="100%"
      _hover={
        isFocused
          ? {}
          : {
              outline: '2px solid',
              outlineOffset: '8px',
              borderRadius: '1px',
              outlineColor: 'gray.200',
              cursor: 'pointer'
            }
      }
    >
      {!isFocused ? (
        <Box pointerEvents="none">
          {node.type !== 'heading' && (
            <Text fontWeight="bold" mb="small">
              {number}. {node.label}
            </Text>
          )}
          <Box>
            {node.type === 'free_text' && <NodeTextArea />}
            {(node.type === 'multiple_choice' || node.type === 'likert') && (
              <NodeMultipleChoice
                answers={node.answers}
                isMultiselect={node.multiselect}
              />
            )}
            {node.type === 'heading' && <NodeHeading name={node.label} />}
          </Box>
        </Box>
      ) : (
        <VStack
          alignItems="normal"
          spacing="small"
          p="medium"
          bg="gray.50"
          w="100%"
          borderRadius="md"
        >
          <HStack justify="flex-end">
            <Field name={`nodes.${index}.type`}>
              {({ field }: FieldProps) => (
                <Select
                  w={200}
                  size="xs"
                  variant="outline"
                  {...field}
                  onChange={e => {
                    const value = e.target.value
                    if (value === 'free_text') {
                      replace({
                        id: node.id,
                        type: value,
                        label: node.label
                      })
                    } else if (value === 'multiple_choice') {
                      replace({
                        id: node.id,
                        type: value,
                        label: node.label,
                        answers: [
                          {
                            id: uuid.v4(),
                            label: 'Option 1'
                          }
                        ]
                      })
                    } else if (value === 'heading') {
                      replace({
                        id: node.id,
                        type: value,
                        label: node.label
                      })
                    } else if (value === 'likert') {
                      replace({
                        id: node.id,
                        type: value,
                        label: node.label,
                        answers: DEFAULT_LIKERT_ANSWERS()
                      })
                    }
                  }}
                >
                  {inputOptions
                    .filter(io => inputsTypes.includes(io.value))
                    .map(io => (
                      <option value={io.value}>{io.label}</option>
                    ))}
                </Select>
              )}
            </Field>
          </HStack>
          <Box>
            <Field name={`nodes.${index}.label`}>
              {({ field }: FieldProps) => (
                <Input
                  defaultValue="Random Title"
                  variant="filled"
                  {...field}
                />
              )}
            </Field>
            {node.type === 'multiple_choice' && (
              <>
                <FieldArray name={`nodes.${index}.answers`}>
                  {({ insert, push, remove, replace }) => (
                    <Box>
                      <VStack spacing="xsmall" w="100%" mt="medium" mb="xsmall">
                        {node.answers?.map((answer, i) => (
                          <Field name={`nodes.${index}.answers.${i}.label`}>
                            {({ field }: FieldProps) => (
                              <HStack w="100%">
                                <Input
                                  placeholder={`Option ${i + 1}`}
                                  size="md"
                                  {...field}
                                />
                                <IconButton
                                  aria-label="Remove"
                                  variant="ghost"
                                  disabled={node.answers?.length === 1}
                                  onClick={e => {
                                    e.stopPropagation()
                                    remove(i)
                                  }}
                                  icon={<CloseIcon />}
                                />
                              </HStack>
                            )}
                          </Field>
                        ))}
                      </VStack>
                      <Box>
                        <Button
                          variant="outline"
                          onClick={() =>
                            push({
                              id: uuid.v4(),
                              label: ''
                            })
                          }
                        >
                          + Option
                        </Button>
                      </Box>
                    </Box>
                  )}
                </FieldArray>
                <Box mt="small">
                  <Field name={`nodes.${index}.multiselect`}>
                    {({ field }: FieldProps) => (
                      <FormControl display="flex" alignItems="center">
                        <Switch
                          isChecked={field.value}
                          onChange={e => {
                            setFieldValue(field.name, e.target.checked)
                          }}
                        />
                        <FormLabel margin="0px" pl="xsmall">
                          Multi-select
                        </FormLabel>
                      </FormControl>
                    )}
                  </Field>
                </Box>
              </>
            )}
            {node.type === 'likert' && (
              <>
                <FieldArray name={`nodes.${index}.answers`}>
                  {({ insert, push, remove, replace }) => (
                    <Box>
                      <VStack spacing="xsmall" w="100%" mt="medium" mb="xsmall">
                        {node.answers?.map((answer, i) => (
                          <Field name={`nodes.${index}.answers.${i}.label`}>
                            {({ field }: FieldProps) => (
                              <HStack w="100%">
                                <Box width={'40px'}>{i + 1}</Box>
                                <Input
                                  placeholder={`Option ${i + 1}`}
                                  size="md"
                                  {...field}
                                />
                                <IconButton
                                  aria-label="Remove"
                                  variant="ghost"
                                  disabled={node.answers?.length === 1}
                                  onClick={e => {
                                    e.stopPropagation()
                                    remove(i)
                                  }}
                                  icon={<CloseIcon />}
                                />
                              </HStack>
                            )}
                          </Field>
                        ))}
                      </VStack>
                      <Box>
                        <Button
                          variant="outline"
                          onClick={() =>
                            push({
                              id: uuid.v4(),
                              label: '',
                              value: node.answers?.length || 0
                            })
                          }
                        >
                          + Option
                        </Button>
                      </Box>
                    </Box>
                  )}
                </FieldArray>
              </>
            )}
          </Box>
          <HStack justify="flex-end">
            <IconButton
              aria-label="Up"
              disabled={index === 0}
              variant="ghost"
              icon={<ArrowUpIcon />}
              onClick={() => {
                setFocus.off()
                swap(index - 1)
              }}
            />
            <IconButton
              aria-label="Down"
              disabled={totalNodes - 1 === index}
              variant="ghost"
              icon={<ArrowDownIcon />}
              onClick={() => {
                setFocus.off()
                swap(index + 1)
              }}
            />
            <IconButton
              aria-label="Delete"
              variant="ghost"
              icon={<TrashcanIcon />}
              onClick={() => {
                setFocus.off()
                remove()
              }}
            />
          </HStack>
        </VStack>
      )}
    </Box>
  )
}

const CheckInEditorForm = ({
  checkIn,
  type,
  isDraft,
  draftVersionId
}: CheckInEditorProps) => {
  const toast = useToast()
  const history = useHistory()

  const queryClient = useQueryClient()

  const initValues = useMemo((): Worksheet => {
    return {
      title: checkIn?.title || 'Example Title',
      description: checkIn?.subtitle || 'Example Description',
      type: checkIn?.type || type || 'treatment_activity',
      nodes: checkIn?.content.nodes || []
    }
  }, [checkIn, type])

  const {
    mutateAsync: updateCheckIn
  } = useCheckinsControllerV1SaveDraftCheckIn()
  const {
    mutateAsync: publishCheckIn
  } = useCheckinsControllerV1PublishDraftCheckIn()
  const { mutateAsync: createCheckIn } = useCheckinsControllerV1CreateCheckIn()

  const { mutateAsync: toggleIsCore } = useCheckinsControllerV1ToggleCheckInIsCore()

  const { mutateAsync: toggleIsAssist } = useCheckinsControllerV1ToggleCheckinIsAssist()

  const onSubmit = async (
    values: Worksheet,
    helpers: FormikHelpers<Worksheet>
  ) => {
    if (!checkIn) {
      const newCheckIn = (await createCheckIn({
        data: {
          title: values.title,
          subtitle: values.description,
          type: values.type,
          nodes: values.nodes.map(n => ({ ...n, isRequired: true }))
        }
      })) as CheckInDtoV2

      await queryClient.invalidateQueries(
        getCheckinsControllerV1GetAllCheckInsQueryKey()
      )

      history.replace({
        pathname: '/library/checkin-editor',
        search: `worksheetId=${newCheckIn!.id}`
      })

      toast({
        status: 'success',
        title: 'Created!',
        duration: 1500,
      })
    } else {
      await updateCheckIn({
        checkInId: checkIn!.id,
        data: {
          title: values.title,
          subtitle: values.description,
          type: values.type,
          nodes: values.nodes.map(n => ({ ...n, isRequired: true }))
        }
      })

      toast({
        status: 'success',
        title: 'Saved!',
        duration: 1500,
      })

      await queryClient.invalidateQueries(
        getCheckinsControllerV1GetDraftCheckInQueryKey(checkIn!.id)
      )
      await queryClient.invalidateQueries(
        getCheckinsControllerV1GetAllCheckInsQueryKey()
      )
    }
  }

  const onPublish = async () => {
    await publishCheckIn({
      checkInId: checkIn!.id
    })

    await queryClient.invalidateQueries(
      getCheckinsControllerV1GetDraftCheckInQueryKey(checkIn!.id)
    )

    await queryClient.invalidateQueries(
      getCheckinsControllerV1GetAllCheckInsQueryKey()
    )

    toast({
      status: 'success',
      title: 'Published!'
    })
  }

  const onToggleCore = async (isCore: boolean) => {
    await toggleIsCore({
      checkInId: checkIn!.id
    })

    toast({
      status: 'success',
      title: isCore ? 'Added to the core library.' : 'Removed from the core library.'
    })

    await queryClient.invalidateQueries(
      getCheckinsControllerV1GetDraftCheckInQueryKey(checkIn!.id)
    )
    await queryClient.invalidateQueries(
      getCheckinsControllerV1GetAllCheckInsQueryKey()
    )
  }

  const onToggleAssist = async (isAssist: boolean) => {
    await toggleIsAssist({
      checkInId: checkIn!.id
    })

    toast({
      status: 'success',
      title: isAssist ? 'Added to Assist.' : 'Removed from Assist.',
      duration: 1500,
      isClosable: true
    })

    await queryClient.invalidateQueries(
      getCheckinsControllerV1GetDraftCheckInQueryKey(checkIn!.id)
    )
    await queryClient.invalidateQueries(
      getCheckinsControllerV1GetAllCheckInsQueryKey()
    )
  }

  return (
    <Formik
      validationSchema={CheckInEditorSchema}
      initialValues={initValues}
      onSubmit={onSubmit}
    >
      {({ values, submitForm, isSubmitting, dirty }) => (
        <Form>
          <Flex w="100%" mb="200px">
            <VStack w="100%" alignItems="flex-start" spacing="medium">
              <HStack w="100%" spacing="small">
                <Box w="100%">
                  <Field name="title">
                    {({ field }: FieldProps) => (
                      <Input
                        placeholder="Title"
                        w="100%"
                        fontWeight="bold"
                        variant="filled"
                        {...field}
                      />
                    )}
                  </Field>
                  <Field name="description">
                    {({ field }: FieldProps) => (
                      <Input
                        mt="small"
                        placeholder="Description"
                        w="100%"
                        variant="filled"
                        {...field}
                      />
                    )}
                  </Field>
                </Box>
              </HStack>
              <FieldArray name="nodes">
                {({ insert, remove, push, replace, swap }) => (
                  <>
                    <VStack w="100%" spacing="medium">
                      {(function() {
                        let questionNumber = 0

                        return values.nodes.map((node, index) => {
                          if (node.type !== 'heading') {
                            questionNumber += 1
                          }

                          return (
                            <CheckInNode
                              inputsTypes={
                                values.type === 'symptom'
                                  ? ['likert', 'heading']
                                  : ['free_text', 'multiple_choice', 'heading']
                              }
                              node={node}
                              index={index}
                              number={questionNumber}
                              remove={() => remove(index)}
                              replace={(props: object) => replace(index, props)}
                              swap={(indexB: number) => swap(index, indexB)}
                              totalNodes={values.nodes.length}
                            />
                          )
                        })
                      })()}
                    </VStack>
                    <Flex justify="center" w="100%">
                      <IconButton
                        mt="medium"
                        icon={<CirclePlus />}
                        aria-label="create"
                        onClick={() => {
                          if (values.type === 'symptom') {
                            push({
                              id: uuid.v4(),
                              type: 'likert',
                              label: 'Default Question Name',
                              answers: DEFAULT_LIKERT_ANSWERS()
                            })
                          } else {
                            push({
                              id: uuid.v4(),
                              type: 'free_text',
                              label: 'Default Question Name'
                            })
                          }
                        }}
                      />
                    </Flex>
                  </>
                )}
              </FieldArray>
            </VStack>
          </Flex>
          <StickyFooter>
            <HStack w="100%" justify="space-between">
              {!checkIn ? (
                <Button isLoading={isSubmitting} type="submit" size="lg">
                  Create New
                </Button>
              ) : (
                <>
                  <HStack spacing="medium">
                    <Button
                      isDisabled={!dirty}
                      size="lg"
                      isLoading={isSubmitting}
                      type="submit"
                    >
                      Save Draft
                    </Button>
                    <Link
                      as="a"
                      target="_blank"
                      mr="xsmall"
                      color="primary"
                      textDecoration="underline"
                      href={buildCheckInPreviewUrl(checkIn.id, draftVersionId)}
                    >
                      Preview Draft
                    </Link>
                  </HStack>
                  <Flex alignItems="center">
                    <FormControl display='flex' alignItems='center' mr="small">
                      <FormLabel htmlFor='core-toggle' mb='0' fontWeight="bold">
                        Is Core?
                      </FormLabel>
                      <Switch isChecked={checkIn.isCore} id='core-toggle' onChange={(e) => onToggleCore(e.target.checked)}/>
                    </FormControl>
                    <FormControl display='flex' alignItems='center' mr="small">
                      <FormLabel htmlFor='assist-toggle' mb='0' fontWeight="bold">
                        Is Assist?
                      </FormLabel>
                      <Switch isChecked={checkIn.approvedForAssist} id='assist-toggle' onChange={(e) => onToggleAssist(e.target.checked)}/>
                    </FormControl>
                    <Button
                      bg="black"
                      size="lg"
                      onClick={onPublish}
                      isDisabled={!isDraft}
                    >
                      Publish Draft
                    </Button>
                  </Flex>
                </>
              )}
            </HStack>
          </StickyFooter>
        </Form>
      )}
    </Formik>
  )
}

export const CheckInEditor = () => {
  const { data = [], isLoading } = useCheckinsControllerV1GetAllCheckIns()

  const queryParams = useQueryParams()

  const worksheetId = queryParams.get('worksheetId')
  const checkInType = queryParams.get('type')

  const {
    isLoading: isDraftLoading,
    data: checkInDraft
  } = useCheckinsControllerV1GetDraftCheckIn(worksheetId || '', {
    query: {
      enabled: !!worksheetId
    }
  })

  const checkIn = useMemo(() => {
    return checkInDraft || data.find(w => w.id === worksheetId)
  }, [worksheetId, data, checkInDraft])

  const history = useHistory()

  return (
    <Container
      marginTop={{
        base: '24px'
      }}
      paddingLeft={{
        base: '8px',
        sm: '0px'
      }}
      paddingRight={{
        base: '8px',
        sm: '0px'
      }}
    >
      <LayoutGrid>
        <GridItem
          colStart={{
            base: 1,
            sm: 2,
            md: 4
          }}
          colEnd={{
            base: 5,
            sm: 8,
            md: 10
          }}
        >
          <Box w="100%">
            <Button
              mb="medium"
              variant="link"
              aria-label="Go back"
              onClick={() => history.goBack()}
            >
              <ArrowLeftIcon fill="primary" />
              Back
            </Button>
            {checkInDraft && (
              <Alert mb="small">
                <AlertIcon />
                This is a draft version
              </Alert>
            )}
            {!isLoading && !isDraftLoading && (
              <CheckInEditorForm
                type={
                  checkInType === 'worksheet' ? 'treatment_activity' : 'symptom'
                }
                checkIn={checkIn}
                isDraft={!!checkInDraft}
                draftVersionId={checkInDraft?.versionId}
              />
            )}
          </Box>
        </GridItem>
      </LayoutGrid>
    </Container>
  )
}
