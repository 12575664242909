import React from 'react'
import { Switch, Route } from 'react-router-dom'
import EBCSessionDashboard from './ebc-session-dashboard'
import SessionDashboardV2 from './session-dashboard-v2'
import { useComponentRenderTracker } from '@hooks/utilities/use-component-render-tracker'
import { useExperienceManager } from '@hooks'

const SessionDashboard = () => {
  useComponentRenderTracker('Viewed Session Dashboard')
  const { isDocumentationAutomationEnabled } = useExperienceManager()

  return (
    <Switch>
      <Route
        path="/sessions"
        component={
          isDocumentationAutomationEnabled
            ? SessionDashboardV2
            : EBCSessionDashboard
        }
        exact
      />
    </Switch>
  )
}

export default SessionDashboard
