import React, { useState, useEffect } from 'react'
import { useHistory, Redirect } from 'react-router-dom'
import { useStoreActions, useStoreState } from 'easy-peasy'
import { useQueryClient } from 'react-query'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import {
  Link,
  VStack,
  Container,
  GridItem,
  Box,
  Text,
  Heading
} from '@chakra-ui/react'
import { Button, LayoutGrid, TextField } from '@blueprinthq/joy'
import { Formik, Field, Form } from 'formik'
import * as Yup from 'yup'
import config from '../config'
import qs from 'query-string'
import { Wrapper, NavBar } from './integration-ui/components'
import { getCookie } from '@utilities'

const ConditionalWrapper = ({ isExtension, isWidget, children }) => {
  if (isExtension || isWidget) {
    return (
      <Wrapper>
        <NavBar />
        <Box background="white" h="100%" mt="0px !important" p="medium">
          {children}
        </Box>
      </Wrapper>
    )
  }

  return (
    <Container
      marginTop={{
        base: '24px'
      }}
      paddingLeft={{
        base: '8px',
        sm: '0px'
      }}
      paddingRight={{
        base: '8px',
        sm: '0px'
      }}
    >
      <LayoutGrid>
        <GridItem
          colStart={{
            base: 1,
            sm: 2,
            md: 4
          }}
          colEnd={{
            base: 5,
            sm: 8,
            md: 10
          }}
        >
          <Box mb="medium">
            <Heading size="lg">Log in to Clinical Portal</Heading>
          </Box>
          {children}
        </GridItem>
      </LayoutGrid>
    </Container>
  )
}

export default function Login() {
  const history = useHistory()
  const location = useLocation()
  const isExtension = location.pathname.includes('/extension')
  const isWidget = location.pathname.includes('/widget')
  const authLogin = useStoreActions(actions => actions.auth.login)
  const widgetLogin = useStoreActions(actions => actions.auth.widgetLogin)
  const resumeSession = useStoreActions(actions => actions.auth.resumeSession)
  const { isAuthenticated } = useStoreState(state => state.auth)
  const queryClient = useQueryClient()
  const [loginError, setLoginError] = useState(null)
  const { next: nextUrl, ...searchQuery } = qs.parse(location.search)
  const queryParams = qs.stringify(searchQuery)
  const redirectUrl = isExtension
    ? '/extension'
    : isWidget
    ? '/widget'
    : nextUrl
    ? `${nextUrl}?${queryParams}`
    : `/dashboard?${queryParams}`

  const submitLogin = async (credentials, isWidget) => {
    try {
      // Triggers cognito login
      if (isWidget) {
        await widgetLogin(credentials)
      } else {
        await authLogin(credentials)
      }
      // Remove cache from any previous sessions
      await queryClient.clear()
      // Redirect to url if login and account are valid
      history.push(redirectUrl)
    } catch (error) {
      setLoginError(error.message)
    }
  }

  useEffect(() => {
    const handleWidgetAuthentication = event => {
      submitLogin(
        { authTokens: event.data.authTokens, username: event.data.username },
        true
      )
    }

    window.addEventListener('message', event => {
      if (event.data.type === 'BP_AUTHENTICATE')
        handleWidgetAuthentication(event)
    })

    return () => {
      window.removeEventListener('message', handleWidgetAuthentication)
    }
  }, [])

  useEffect(() => {
    if (isExtension) {
      const interval = setInterval(() => {
        const ssoAuthToken = getCookie('ssoAccessToken')
        if (ssoAuthToken) {
          clearInterval(interval)
          resumeSession()
        }
      }, 1000)

      return () => {
        clearInterval(interval)
      }
    }
  }, [])

  if (isAuthenticated) {
    return (
      <Redirect to={isExtension ? '/extension' : isWidget ? '/widget' : '/'} />
    )
  }

  return (
    <ConditionalWrapper isExtension={isExtension} isWidget={isWidget}>
      <Formik
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email('Invalid email')
            .required('Required'),
          password: Yup.string().required('Required')
        })}
        initialValues={{
          email:
            (location.state && location.state.email) ||
            searchQuery?.email ||
            '',
          password: ''
        }}
        validateOnBlur={false}
        validateOnChange={false}
        validate={() => setLoginError(null)}
        onSubmit={async values => {
          const credentials = {
            email: values.email,
            password: values.password
          }
          try {
            submitLogin(credentials)
          } catch (error) {
            setLoginError(error.message)
          }
        }}
      >
        {({ values, errors, touched, isSubmitting }) => (
          <Form>
            <VStack spacing="small" mb="medium" alignItems="flex-start">
              {loginError && <Text color="error">{loginError}</Text>}
              <Box
                w="100%"
                mb={errors.email && touched.email && 'small'}
                id="bp-extension-loaded"
              >
                <Field name="email">
                  {({ field }) => (
                    <TextField
                      {...field}
                      isRequired
                      label="Email"
                      isInvalid={errors.email && touched.email}
                      errorText={errors.email}
                    />
                  )}
                </Field>
              </Box>
              <Box w="100%" pb={errors.password && touched.password && 'small'}>
                <Field name="password">
                  {({ field }) => (
                    <TextField
                      {...field}
                      isRequired
                      label="Password"
                      type="password"
                      autoFocus={Boolean(
                        location.state && location.state.email
                      )}
                      isInvalid={errors.password && touched.password}
                      errorText={errors.password}
                    />
                  )}
                </Field>
              </Box>
              {!isExtension && !isWidget && (
                <Link
                  color="primary"
                  alignSelf="flex-start"
                  as={RouterLink}
                  to={{
                    pathname: '/forgot-password',
                    state: {
                      email: values.email
                    }
                  }}
                >
                  Forgot your password?
                </Link>
              )}
              <Button
                size="lg"
                my="small"
                isFullWidth
                isLoading={isSubmitting}
                type="submit"
              >
                Log in
              </Button>
              {!isWidget &&
                (isExtension ? (
                  <Link
                    color="primary"
                    alignSelf="center"
                    as="a"
                    href={`${window.location.origin.replace(
                      'extension.',
                      ''
                    )}/sso${location.search}`}
                    target="_blank"
                  >
                    Log in with Single Sign-On (SSO)
                  </Link>
                ) : (
                  <Link
                    color="primary"
                    alignSelf="center"
                    as={RouterLink}
                    to={`/sso${location.search}`}
                  >
                    Log in with Single Sign-On (SSO)
                  </Link>
                ))}
              {// Keeping this around for later in case but neither BP or tempus have this on
              config.showLinkToSignUp && !isExtension && !isWidget && (
                <Link color="primary" as={RouterLink} to="/signup">
                  Or, create your clinician account
                </Link>
              )}
              <Text mb="small">
                By logging in, you agree to our{' '}
                <Link
                  color="primary"
                  isExternal
                  href={config.termsAndConditionsURL}
                  rel="noopener noreferrer"
                >
                  Terms of Service
                </Link>{' '}
                and{' '}
                <Link
                  color="primary"
                  isExternal
                  href={config.privacyPolicyURL}
                  rel="noopener noreferrer"
                >
                  Privacy Policy
                </Link>
                .
              </Text>
              {!isExtension && !isWidget && (
                <Text>
                  Are you a client?{' '}
                  <Link
                    color="primary"
                    href={`${process.env.REACT_APP_CLIENT_APP_URL}/login`}
                  >
                    Log in
                  </Link>
                </Text>
              )}
            </VStack>
          </Form>
        )}
      </Formik>
    </ConditionalWrapper>
  )
}
