import React from 'react'
import {
  Box,
  VStack,
  HStack,
  Flex,
  Text,
  Button,
  Menu,
  MenuList,
  MenuItem,
  MenuButton,
  Tooltip,
  Portal
} from '@chakra-ui/react'
import {
  ArrowDownIcon,
  NoHeadsetIcon,
  Headphones,
  InfoFilledIcon
} from '@blueprinthq/joy'
import { trackEvent } from '@lib/clinician-tracking'
import { useAudioCapture } from '@hooks'

interface TelehealthToggleProps {
  isDictation?: boolean
}

const HeadphonesMenu = ({ isVisible, children }: { isVisible: boolean, children: React.ReactNode }) => {
  return (
    <Box
      w="100%"
      data-testid="headphones-menu"
      className="headphones-menu"
      sx={{
        height: isVisible ? '48px' : '0px',
        opacity: isVisible ? 1 : 0,
        marginTop: isVisible ? '16px' : '0px !important',
        transition: 'all 0.4s cubic-bezier(0.57, 0.1, 0, 1)',
        overflow: 'hidden'
      }}
    >
      {children}
    </Box>
  )
}

export const TelehealthToggle: React.FC<TelehealthToggleProps> = ({
  isDictation = false
}) => {
  const {
    isUsingHeadphones,
    setIsUsingHeadphones,
    isTelehealth,
    setIsTelehealth,
  } = useAudioCapture()

  const handleSettingChange = (newTelehealthValue: boolean) => {
    if (!newTelehealthValue) {
      setIsUsingHeadphones(false)
    }
    setIsTelehealth(newTelehealthValue)
    trackEvent('Confirm Audio Settings Dialog -> Changed Setting Input', {
      newSettingSelection: newTelehealthValue ? 'telehealth' : 'in_person'
    })
  }

  const handleHeadphonesChange = (event: any) => {
    setIsUsingHeadphones(event.target.value === 'headphones')
    trackEvent('Confirm Audio Settings Dialog -> Changed Headphones Input', {
      newHeadphonesSelection: event.target.value
    })
  }

  const HeadphonesButton = () => {
    if (isUsingHeadphones) {
      return (
        <HStack spacing={3}>
          <Headphones />
          <Text color="black">Using headphones</Text>
        </HStack>
      )
    }
    return (
      <HStack spacing={3}>
        <NoHeadsetIcon />
        <Text color="black">Not using headphones</Text>
      </HStack>
    )
  }

  return (
    <VStack spacing={4} width="100%" mt="0px !important" data-testid="telehealth-toggle">
      <Box w="100%" data-testid="telehealth-toggle-button">
        <Flex
          w="100%"
          h="48px"
          align="center"
          bg="#EAECF4"
          borderRadius="8px"
          _hover={{ cursor: 'pointer' }}
          px={1}
          position="relative"
        >
          <Box
            position="absolute"
            top="5px"
            h="38px"
            w="50%"
            bg="white"
            border="1px solid #D2D4DC"
            borderRadius="4px"
            transform={isTelehealth ? 'translateX(96%)' : 'translateX(0)'}
            transition="transform 0.3s ease-in-out"
          />

          <HStack
            justifyContent="center"
            w="50%"
            h="38px"
            borderRadius="4px"
            onClick={() => handleSettingChange(false)}
            position="relative"
            cursor="pointer"
          >
            <Text color={isTelehealth ? '#757575' : 'black'} transition="color 0.3s">
              In-Person
            </Text>
          </HStack>
          <HStack
            justifyContent="center"
            spacing={3}
            w="50%"
            h="38px"
            borderRadius="4px"
            onClick={() => handleSettingChange(true)}
            cursor="pointer"
            position="relative"
          >
            <Text color={isTelehealth ? 'black' : '#757575'} transition="color 0.3s">
              Telehealth
            </Text>
            <Tooltip label="Audio is optimized to improve note quality based on your session setting" placement="top">
              <Box position="absolute" right="16px">
                <InfoFilledIcon size={'sm'} />
              </Box>
            </Tooltip>
          </HStack>
        </Flex>
      </Box>

      <HeadphonesMenu isVisible={!!isTelehealth && !isDictation}>
        <Menu placement="bottom-start" matchWidth>
          <MenuButton
            border="1px solid #E4E5E6"
            m="0"
            h="48px"
            w="100%"
            bg="white"
            borderRadius="8px"
            as={Button}
            rightIcon={<ArrowDownIcon />}
          >
            <HeadphonesButton />
          </MenuButton>
          <Portal>
            <MenuList w="100%">
              <MenuItem
                isDisabled={isUsingHeadphones}
                onClick={handleHeadphonesChange}
                value="headphones"
                color="black"
                w="100%"
                icon={<Headphones />}
              >
                Using headphones
              </MenuItem>
              <MenuItem
                isDisabled={!isUsingHeadphones}
                onClick={handleHeadphonesChange}
                value="no_headphones"
                color="black"
                w="100%"
                icon={<NoHeadsetIcon />}
              >
                Not using headphones
              </MenuItem>
            </MenuList>
          </Portal>
        </Menu>
      </HeadphonesMenu>
    </VStack>
  )
}