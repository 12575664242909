import React from 'react'
import { Box, Heading, Text, Flex, Link } from '@chakra-ui/react'
import { Button, Select as JoySelect } from '@blueprinthq/joy'
import { SuperAdminClinicSearch } from '@components/clinic-selector/super-admin-clinic-search'
import { Link as RouterLink } from 'react-router-dom'

import { usePermissions } from '../../../../hooks'
import { useClinicSelectControl } from '@hooks'
import { useExperienceManager } from '@hooks'

const AddUserButton = ({ openUpsertClinicianModal }) => {
  const { hasPermission } = usePermissions()

  return (
    hasPermission('create:org:providers:account') && (
      <Button size={'lg'} onClick={() => openUpsertClinicianModal(null)}>
        Invite User
      </Button>
    )
  )
}

const HeaderText = ({ boxWidth, user, openUpsertClinicianModal, show }) => {
  const { hasPermission } = usePermissions()
  return (
    <Box>
      <Flex justify="space-between" alignItems="center" flexDirection="row">
        <Box w="100%">
          <Heading mb="xxsmall">Users</Heading>
          <Flex display="inline-flex" alignItems="center">
            <Text>Manage who has access to this organization</Text>
            {hasPermission('*:any:*:*') && (
              <>
                <Text as="span">.</Text>{' '}
                <Link
                  as={RouterLink}
                  color="primary"
                  ml="5px"
                  to={{
                    pathname: `/settings/users/upload`
                  }}
                >
                  <Box as="span">Bulk Uploader</Box>
                </Link>
              </>
            )}
          </Flex>
        </Box>
        {show && !user.organization.isDirectorySync ? (
          <AddUserButton openUpsertClinicianModal={openUpsertClinicianModal} />
        ) : null}
      </Flex>
    </Box>
  )
}

export const UsersHeader = ({
  setSelectedClinic,
  selectedClinic,
  clinics,
  openUpsertClinicianModal,
  user
}) => {
  const { hasPermission } = usePermissions()
  const { clinicSelectEnabled } = useClinicSelectControl(clinics.length)
  const { showPartnerUI } = useExperienceManager()

  const isAdmin =
    user &&
    user?.roles?.some(
      r =>
        r.name === 'orgadmin' ||
        r.name === 'clinicadmin' ||
        r.name === 'superadmin'
    )

  const isSuperAdmin = hasPermission('*:any:*:*')
  const orgAdminSelectComponent = (
    <JoySelect
      value={selectedClinic}
      options={clinics}
      valueKey="id"
      labelKey="name"
      onChange={value => setSelectedClinic(value)}
    />
  )

  const superAdminSelectComponent = (
    <SuperAdminClinicSearch
      onClinicSelected={value => {
        setSelectedClinic(value)
      }}
      selectedClinicName={selectedClinic.name}
    />
  )

  let selectComponent = null
  if (isSuperAdmin) {
    selectComponent = superAdminSelectComponent
  } else if (clinicSelectEnabled) {
    selectComponent = orgAdminSelectComponent
  }

  const showAddUserButton =
    isSuperAdmin ||
    (!showPartnerUI &&
      isAdmin &&
      (!user.organization.isDirectorySync ||
        user.organization.showInviteUserOverride))

  if (selectComponent) {
    return (
      <>
        <HeaderText
          boxWidth="100%"
          openUpsertClinicianModal={openUpsertClinicianModal}
          user={user}
          show={true}
        />

        <Flex
          justify="space-between"
          alignItems="center"
          flexDirection="column"
        >
          <Flex justify="space-between" alignItems="center" w="100%">
            <Box w="100%" mb="small" mt="medium">
              {selectComponent}
            </Box>
          </Flex>
        </Flex>
      </>
    )
  }

  return (
    <Flex justify="space-between" alignItems="center" mb="medium">
      <HeaderText
        user={user}
        openUpsertClinicianModal={openUpsertClinicianModal}
        show={false}
        boxWidth="auto"
      />
      {showAddUserButton ? (
        <AddUserButton openUpsertClinicianModal={openUpsertClinicianModal} />
      ) : null}
    </Flex>
  )
}
