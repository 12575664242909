import React from 'react'
import { Heading, Flex, Text, Box, Button, Link } from '@chakra-ui/react'
import { useLocation, useHistory } from 'react-router-dom'

export const PartnerWelcome = () => {
  const history = useHistory()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)

  const externalOrgId = searchParams.get('externalOrgId')
  const signupToken = searchParams.get('signupToken')

  return (
    <Flex
      w="100%"
      justify="center"
      align="center"
      direction="column"
      mt="10"
      textAlign="center"
    >
      <Heading mb="5">Welcome to Blueprint!</Heading>
      <Box maxW="500px" textAlign="center">
        <Text mb="5">
          NextGen has partnered with Blueprint to assist you in getting hours of{' '}
          <Text as="b">time</Text> and <Text as="b">focus</Text> back each week
          — so you can deliver exceptional care every session without spending
          nights & weekends on documentation.
        </Text>
        <Button
          mb="2"
          onClick={() =>
            history.push(`/partner/signup?externalOrgId=${externalOrgId}&signupToken=${signupToken}`)
          }
        >
          Create an account
        </Button>
        <Text fontSize="sm">
          Already have a Blueprint account through NextGen?{' '}
          <Link color="primary" onClick={() => history.push('/login')}>
            Login
          </Link>
        </Text>
      </Box>
    </Flex>
  )
}
