import React, { useMemo, useState, useCallback } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Flex,
  Box
} from '@chakra-ui/react'
import { Button, TextField, Select } from '@blueprinthq/joy'
import { useStoreActions, useStoreState } from 'easy-peasy'

import { TREATMENT_APPROACHES } from '@constants/index'
import { ClientInterventionList } from './client-intervention-list'
import { StoreModel } from 'src/store/types'

interface ClientInterventionListModalProps {
  isOpen: boolean
  interventions: any[]
}

export const ClientInterventionListModal = ({
  isOpen,
  interventions
}: ClientInterventionListModalProps) => {
  const handleCloseModal = useStoreActions(
    //@ts-ignore
    actions => actions.modals.clientInterventionList.closeModal
  )
  const [searchText, setSearchText] = useState('')
  const [modalityOption, setModalityOption] = useState(TREATMENT_APPROACHES[0])

  const filteredInterventions = useMemo(() => {
    let newInterventions = interventions
    if (searchText.length) {
      newInterventions = newInterventions.filter(
        (i: any) =>
          i.name.toLowerCase().includes(searchText.toLowerCase()) ||
          i?.short_description?.toLowerCase().includes(searchText.toLowerCase())
      )
    }
    if (modalityOption.value !== '') {
      newInterventions = newInterventions.filter((i: any) =>
        i.treatment_modality
          .toLowerCase()
          .includes(modalityOption.value.toLowerCase())
      )
    }

    return newInterventions
  }, [searchText, modalityOption, interventions])

  const _handleCloseModal = useCallback(() => {
    setSearchText('')
    setModalityOption(TREATMENT_APPROACHES[0])
    handleCloseModal()
  }, [handleCloseModal])

  return (
    <Modal
      size="2xl"
      scrollBehavior="inside"
      isOpen={isOpen}
      onClose={_handleCloseModal}
    >
      <ModalOverlay />
      <ModalContent h="80vh">
        <ModalHeader
          textTransform="capitalize"
          fontSize="24px"
          borderBottom="1px solid #E4E5E6"
        >
          Interventions
        </ModalHeader>
        <ModalCloseButton mr="xsmall" mt="10px" borderRadius="25px" />
        <ModalBody pt="0" overflowX="hidden">
          <Flex align="center" justify="space-between">
            <Box w="50%" mt="medium" mb="small" mr="small">
              <TextField
                showClear
                onClear={() => setSearchText('')}
                label="Search interventions"
                onChange={e => setSearchText(e.currentTarget.value)}
                value={searchText}
              />
            </Box>
            <Box w="50%" mt="medium" mb="small" ml="small">
              <Select
                value={modalityOption}
                label="Treatment Approaches"
                options={TREATMENT_APPROACHES}
                valueKey="value"
                labelKey="label"
                onChange={option => setModalityOption(option)}
                menuListProps={{
                  height: '30vh',
                  overflowY: 'scroll'
                }}
              />
            </Box>
          </Flex>

          {/* All interventions */}
          <ClientInterventionList
            interventionList={filteredInterventions}
            title="A to Z"
            onClick={_handleCloseModal}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export const ClientInterventionListModalConnected = () => {
  const { isOpen, interventions } = useStoreState(
    (state: StoreModel) => state.modals.clientInterventionList
  )

  return isOpen ? (
    <ClientInterventionListModal
      isOpen={isOpen}
      interventions={interventions}
    />
  ) : null
}
