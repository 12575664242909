import React, { useState, useCallback, useEffect } from 'react'
import { useQuery, useMutation } from 'react-query'
import { Box, useToast, Flex } from '@chakra-ui/react'
import Pagination from '@material-ui/lab/Pagination'
import { useStoreState } from 'easy-peasy'

import { endpoints } from '../../../api'
import { usePermissions, useExperienceManager } from '../../../hooks'
import { Loading } from '../../../components'
import {
  UpsertClinicianModal,
  DeleteClinicianModal,
  UsersTable,
  UsersHeader
} from './components'

const perPage = 50

export const UsersTab = ({ clinics, selectedClinic, setSelectedClinic }) => {
  const [page, setPage] = useState(1)
  const toast = useToast()
  const [clinicianSearchQuery, setClinicianNameSearch] = useState(null)
  const [localProviders, setLocalProviders] = useState([])
  const { user } = useStoreState(state => state.auth)

  const [upsertModal, setUpsertModal] = useState({
    isOpen: false,
    selectedClinician: null
  })

  const [deleteModal, setDeleteModal] = useState({
    isOpen: false,
    selectedClinician: null
  })

  const {
    data: {
      providers,
      meta: { totalCount }
    },
    isFetching: isProvidersLoading,
    isPlaceholderData,
    refetch: refetchProviders
  } = useQuery(
    [
      endpoints.getOrganizationProvidersV2.getCacheId(),
      page,
      clinicianSearchQuery,
      selectedClinic && selectedClinic.organization_id,
      selectedClinic && selectedClinic.id
    ],
    () =>
      endpoints.getOrganizationProvidersV2.request({
        organizationId: selectedClinic.organization_id,
        clinicId: selectedClinic.id,
        page,
        perPage,
        clinicianSearchQuery
      }),
    {
      placeholderData: {
        providers: [],
        meta: {
          page: 1,
          perPage,
          totalCount: 0
        }
      },
      enabled: !!selectedClinic
    }
  )

  useEffect(() => {
    if (!clinicianSearchQuery && !isProvidersLoading && !upsertModal.isOpen) {
      setLocalProviders(providers)
    }
  }, [clinicianSearchQuery, isProvidersLoading, upsertModal.isOpen])

  const { paywallData } = useExperienceManager()
  const { isSoloPlan, trialType } = paywallData

  const {
    isEvidenceBasedCareEnabled,
    hasDocumentationAutomationSubscription
  } = useExperienceManager()

  const { mutateAsync: inviteProvider } = useMutation(
    endpoints.postOrganizationProviderInvite.request
  )

  const handlePageChange = (evt, page) => {
    setPage(page)
  }

  const handleClinicianSearch = searchQuery => {
    if (searchQuery.length >= 2) {
      setClinicianNameSearch(searchQuery)
    } else {
      setClinicianNameSearch(null)
    }
  }

  const onOpenUpsertClinicianModal = useCallback(clinician => {
    if (
      isSoloPlan &&
      !hasDocumentationAutomationSubscription &&
      trialType !== 'trial' &&
      trialType !== 'gatedTrial'
    ) {
      toast({
        title: 'Please upgrade your plan to add additional clinicians.',
        description: 'Your Solo plan only allows for one clinician user.',
        status: 'error',
        duration: 3000,
        isClosable: true
      })
    } else {
      refetchProviders()
      setUpsertModal({
        selectedClinician: clinician,
        isOpen: true
      })
    }
  })

  const onCloseUpsertClinicianModal = useCallback(() => {
    setUpsertModal({
      selectedClinician: null,
      isOpen: false
    })
  }, [])

  const onOpenDeleteClinicianModal = useCallback(clinician => {
    setDeleteModal({
      selectedClinician: clinician,
      isOpen: true
    })
  })

  const onCloseDeleteClinicianModal = useCallback(() => {
    setDeleteModal({
      selectedClinician: null,
      isOpen: false
    })
  }, [])

  const onInviteProvider = useCallback(
    async provider => {
      await inviteProvider(
        {
          providerId: provider.id,
          organizationId: selectedClinic.organization_id
        },
        {
          onSuccess() {
            toast({
              title: 'Invite',
              description: `Invite email sent to ${provider.first_name} ${provider.last_name}!`,
              status: 'info',
              isClosable: true
            })
          },
          onError() {
            toast({
              title: 'Invite',
              description: `Invite failed for ${provider.first_name} ${provider.last_name}.`,
              status: 'error',
              isClosable: true
            })
          }
        }
      )
    },
    [selectedClinic]
  )

  const { isAdmin } = useExperienceManager()

  const onEditProvider = useCallback(
    provider => {
      if (isAdmin) {
        onOpenUpsertClinicianModal(provider)
      } else {
        toast({
          title: 'Error',
          description: 'You do not have permission to edit this user.',
          status: 'error',
          isClosable: true
        })
      }
    },
    [user]
  )

  return (
    <>
      <Box mb="xxlarge">
        <Box
          width={{
            base: '100%',
            sm: 'auto'
          }}
        >
          <UsersHeader
            setSelectedClinic={setSelectedClinic}
            selectedClinic={selectedClinic}
            clinics={clinics}
            openUpsertClinicianModal={onOpenUpsertClinicianModal}
            user={user}
          />
        </Box>
        <Box
          overflowX={{
            base: 'auto',
            md: 'initial'
          }}
        >
          {isProvidersLoading &&
          isPlaceholderData &&
          !clinicianSearchQuery &&
          !upsertModal.isOpen ? (
            <Loading />
          ) : (
            <UsersTable
              data={!upsertModal.isOpen ? providers : localProviders}
              user={user}
              onRowClick={onEditProvider}
              onInviteProvider={onInviteProvider}
              isEvidenceBasedCareEnabled={isEvidenceBasedCareEnabled}
            />
          )}
        </Box>
        {!isProvidersLoading &&
          localProviders.length !== 1 &&
          Math.ceil(totalCount / perPage) !== 1 && (
            <Flex justifyContent="center">
              <Pagination
                page={page}
                count={Math.ceil(totalCount / perPage)}
                size="large"
                variant="outlined"
                showFirstButton
                showLastButton
                onChange={handlePageChange}
              />
            </Flex>
          )}
      </Box>
      <UpsertClinicianModal
        user={user}
        isOpen={upsertModal.isOpen}
        onClose={onCloseUpsertClinicianModal}
        onClickDeleteUser={onOpenDeleteClinicianModal}
        selectedClinic={selectedClinic}
        clinicList={clinics}
        provider={upsertModal.selectedClinician}
        isUpgradingToGroup={isSoloPlan ? true : false}
        page={page}
        providerListForPage={providers}
        perPage={perPage}
        totalCount={totalCount}
        handlePageChange={handlePageChange}
        isProvidersLoading={isProvidersLoading}
        handleClinicianSearch={handleClinicianSearch}
        clinicianSearchQuery={clinicianSearchQuery}
        refetchProviders={refetchProviders}
        fullProviderList={localProviders}
      />
      <DeleteClinicianModal
        isOpen={deleteModal.isOpen}
        onClose={onCloseDeleteClinicianModal}
        selectedClinic={selectedClinic}
        provider={deleteModal.selectedClinician}
        providerList={providers}
      />
    </>
  )
}
