import React from 'react'
import { Box, Icon } from '@chakra-ui/react'
import WarningIcon from '@material-ui/icons/Warning'

interface AlertBoxProps {
  children?: React.ReactNode | React.ReactNode[]
  [key: string]: any
}

const AlertBox = ({ children, icon, ...props }: AlertBoxProps) => {
  return (
    <Box
      bg="#FFF2F2"
      borderRadius="md"
      p={4}
      pr={8}
      display="flex"
      justifyContent="space-between"
      border="1px solid rgba(40, 40, 40, 0.05)"
      {...props}
    >
      {icon || (
        <Icon as={WarningIcon} color="red.500" mr="10px" mt="2px" boxSize={5} />
      )}
      <Box>{children}</Box>
    </Box>
  )
}

export default AlertBox
