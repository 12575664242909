import React, { useEffect, useState } from 'react'
import { Flex } from '@chakra-ui/react'
import { User } from 'src/store/types'
import { ClinicSelector } from '../clinic-selector'
import { ClinicianSelect, Clinician } from './clinician-select'
import { Clinic } from '../clinic-selector/models'

const ClinicianOverride = ({
  onChange,
  user,
  selections,
  canViewOrgDropdown = false,
  allowAllClinicians = false,
  hideIfOnlyOneClinician = false,
  containerProps = {}
}: {
  onChange: (changedClinicianId: string, changedClinic: Clinic) => void
  user: User
  selections?: {
    clinic: Clinic
    clinicianId: string
  }
  canViewOrgDropdown?: boolean
  allowAllClinicians?: boolean
  hideIfOnlyOneClinician?: boolean
  containerProps?: any
}) => {
  const [clinic, setClinic] = useState<Clinic>(
    selections?.clinic ?? user.clinic
  )
  const [clinicianId, setClinicianId] = useState(
    selections?.clinicianId ?? ''
  )

  useEffect(() => {
    setClinic(selections?.clinic ?? user.clinic)
    setClinicianId(selections?.clinicianId ?? '')
  }, [selections])

  const handleClinicSelect = (clinic: Clinic) => {
    setClinic(clinic)
  }

  const handleClinicianSelect = (clinicianId: string) => {
    onChange(clinicianId, clinic)
    setClinicianId(clinicianId)
  }

  const onCliniciansLoaded = (clinicians: Clinician[]) => {
    // wait until clinics are loaded in order to find the proper selected clinician
    if (clinicians.length === 0) return

    // if the current selected clinician is '' or "all" AND select all clinicians is allowed, then leave as is
    // if the selection from props is in the list, select it
    // if the current user is in the clinician list
    // otherwise, select the first clinician
    var selectedClinicianId = ''
    if (clinicianId === '' && allowAllClinicians) {
      selectedClinicianId = ''
    } else if (clinicians.find(c => c.id === clinicianId)) {
      selectedClinicianId = clinicianId
    } else if (clinicians.find(c => c.id === user.id)) {
      selectedClinicianId = user.id
    } else {
      selectedClinicianId = clinicians[0].id
    }

    onChange(selectedClinicianId, clinic)
    setClinicianId(selectedClinicianId)
  }

  return (
    <Flex w="100%" {...containerProps}>
      <ClinicSelector
        onClinicSelected={handleClinicSelect}
        selectedClinic={clinic}
        canViewOrgDropdown={canViewOrgDropdown}
        user={user}
      />
      <ClinicianSelect
        onClinicianSelected={handleClinicianSelect}
        onCliniciansLoaded={onCliniciansLoaded}
        selectedClinicianId={clinicianId}
        clinicDisplayId={clinic.display_id}
        allowAllCliniciansOption={allowAllClinicians}
        hideIfOnlyOneClinician={hideIfOnlyOneClinician}
      />
    </Flex>
  )
}

export default ClinicianOverride
