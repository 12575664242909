import React from 'react'

const CoolKidsFeedback = () => (
  <svg
    width="231"
    height="195"
    viewBox="0 0 231 195"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="231" height="195" fill="white" />
    <path
      d="M186.902 124.861H154.131V157.416H186.902V124.861Z"
      fill="white"
      stroke="#C0CCF8"
      stroke-miterlimit="10"
    />
    <path
      d="M163.033 144.604V147.7H166.149L175.341 138.569L172.225 135.474L163.033 144.604ZM165.463 146.05H164.694V145.286L172.225 137.804L172.995 138.569L165.463 146.05ZM177.759 135.008L175.81 133.072C175.641 132.904 175.437 132.833 175.22 132.833C175.004 132.833 174.799 132.916 174.643 133.072L173.127 134.578L176.243 137.673L177.759 136.167C178.084 135.844 178.084 135.33 177.759 135.008Z"
      fill="#C0CCF8"
      stroke="white"
      stroke-width="0.75"
      stroke-miterlimit="10"
    />
    <path
      d="M71.2151 44.8383H38.4435V77.3925H71.2151V44.8383Z"
      fill="white"
      stroke="#C0CCF8"
      stroke-miterlimit="10"
    />
    <path
      d="M47.7673 65.2624L52.7601 60.3027L56.0805 63.6012L63.1425 55.7017L61.9756 54.5424L56.0805 61.1274L52.7601 57.8289L46.5282 64.0314L47.7794 65.2743L47.7673 65.2624Z"
      fill="#C0CCF8"
      stroke="white"
      stroke-width="0.75"
      stroke-miterlimit="10"
    />
    <path
      d="M92.1124 28.2625H59.3409V60.8166H92.1124V28.2625Z"
      fill="#F6F7FE"
      stroke="#C0CCF8"
      stroke-miterlimit="10"
    />
    <path
      d="M80.7194 42.0298L79.5524 40.8587L74.0785 46.2963L71.925 44.1691L70.758 45.3283L74.0785 48.6267L80.7314 42.0179L80.7194 42.0298ZM81.5495 37.0702H78.0726C77.289 34.9227 74.1643 34.9227 73.3807 37.0702H69.9038C69.7835 37.0702 69.6752 37.0702 69.5669 37.1061C69.2421 37.1778 68.9534 37.3331 68.7248 37.5602C68.5804 37.7036 68.4481 37.8948 68.3639 38.0861C68.2797 38.2773 68.2315 38.4924 68.2315 38.7195V50.276C68.2315 50.503 68.2797 50.7181 68.3639 50.9213C68.4481 51.1245 68.5684 51.2918 68.7248 51.4471C68.9534 51.6742 69.2421 51.8296 69.5669 51.9013C69.6752 51.9132 69.7835 51.9252 69.9038 51.9252H81.5375C82.4518 51.9252 83.1977 51.1842 83.1977 50.276V38.7195C83.1977 37.8112 82.4518 37.0702 81.5375 37.0702H81.5495ZM75.7267 36.8671C76.0635 36.8671 76.3522 37.1419 76.3522 37.4885C76.3522 37.8351 76.0755 37.2362 75.7267 37.2362C75.3778 37.2362 75.1011 37.8351 75.1011 37.4885C75.1011 37.1419 75.3778 36.8671 75.7267 36.8671ZM81.5495 50.2879H69.9158V38.7314H81.5495V50.2879Z"
      fill="#C0CCF8"
      stroke="#F6F7FE"
      stroke-width="0.75"
      stroke-miterlimit="10"
    />
    <path
      d="M174.27 38.5043H141.498V71.0585H174.27V38.5043Z"
      fill="#F6F7FE"
      stroke="#C0CCF8"
      stroke-miterlimit="10"
    />
    <path
      d="M163.731 46.0573L164.898 47.2165L163.406 48.6984L162.239 47.5392L163.731 46.0573Z"
      fill="#C0CCF8"
      stroke="#F6F7FE"
      stroke-width="0.75"
      stroke-miterlimit="10"
    />
    <path
      d="M157.054 46.4755H158.714V44.0017H157.054V46.4755Z"
      fill="#C0CCF8"
      stroke="#F6F7FE"
      stroke-width="0.75"
      stroke-miterlimit="10"
    />
    <path
      d="M164.525 53.909H167.015V52.2598H164.525V53.909Z"
      fill="#C0CCF8"
      stroke="#F6F7FE"
      stroke-width="0.75"
      stroke-miterlimit="10"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M160.375 60.47H155.382V57.3269C153.89 56.4665 152.891 54.877 152.891 53.0366C152.891 50.2998 155.129 48.077 157.884 48.077C160.639 48.077 162.877 50.2998 162.877 53.0366C162.877 54.8651 161.866 56.4665 160.387 57.3269V60.47H160.375ZM157.054 56.3709V58.8208H158.714V56.3709L159.544 55.8928C160.567 55.2953 161.205 54.2078 161.205 53.0366C161.205 51.2081 159.713 49.7381 157.884 49.7381C156.056 49.7381 154.564 51.22 154.564 53.0366C154.564 54.2078 155.201 55.2953 156.224 55.8928L157.054 56.3709Z"
      fill="#C0CCF8"
      stroke="#F6F7FE"
      stroke-width="0.75"
      stroke-miterlimit="10"
    />
    <path
      d="M148.741 53.909H151.231V52.2598H148.741V53.909Z"
      fill="#C0CCF8"
      stroke="#F6F7FE"
      stroke-width="0.75"
      stroke-miterlimit="10"
    />
    <path
      d="M152.037 46.0573L153.529 47.5392L152.362 48.6984L150.87 47.2165L152.037 46.0573Z"
      fill="#C0CCF8"
      stroke="#F6F7FE"
      stroke-width="0.75"
      stroke-miterlimit="10"
    />
    <path
      d="M155.382 62.9917C155.382 63.4458 155.755 63.8163 156.212 63.8163H159.532C159.99 63.8163 160.362 63.4458 160.362 62.9917V62.1671H155.37V62.9917H155.382Z"
      fill="#C0CCF8"
      stroke="#F6F7FE"
      stroke-width="0.75"
      stroke-miterlimit="10"
    />
    <path
      d="M46.4199 150.711L48.8621 150.604C48.9824 150.604 49.0907 150.639 49.1749 150.735L50.8472 152.516C51.0998 152.779 51.557 152.587 51.545 152.217L51.4246 149.779C51.4246 149.66 51.4607 149.552 51.557 149.468L53.3375 147.807C53.6022 147.556 53.4097 147.102 53.0368 147.114L50.5945 147.222C50.4742 147.222 50.3659 147.186 50.2817 147.09L48.6095 145.309C48.3568 145.046 47.8997 145.238 47.9117 145.608L48.032 148.046C48.032 148.166 47.9959 148.273 47.8997 148.357L46.1191 150.018C45.8544 150.269 46.0469 150.723 46.4199 150.711Z"
      fill="#C0CCF8"
    />
    <path
      d="M192.869 166.008L193.603 164.036C193.639 163.941 193.712 163.869 193.808 163.833L195.793 163.104C196.094 162.997 196.094 162.578 195.793 162.459L193.808 161.73C193.712 161.694 193.639 161.622 193.603 161.527L192.869 159.555C192.761 159.256 192.328 159.256 192.208 159.555L191.474 161.527C191.438 161.622 191.366 161.694 191.269 161.73L189.284 162.459C188.983 162.566 188.983 162.985 189.284 163.104L191.269 163.833C191.366 163.869 191.438 163.941 191.474 164.036L192.208 166.008C192.316 166.307 192.749 166.307 192.869 166.008Z"
      fill="#C0CCF8"
      stroke="#C0CCF8"
      stroke-miterlimit="10"
    />
    <path
      d="M17.3297 129.379L21.4562 127.503C21.6728 127.419 21.8773 127.419 22.0939 127.503L26.2324 129.403C26.846 129.69 27.4956 129.044 27.231 128.411L25.3181 124.3C25.2218 124.085 25.2218 123.881 25.3181 123.666L27.2069 119.567C27.4956 118.958 26.846 118.312 26.2083 118.575L22.0818 120.452C21.8653 120.535 21.6608 120.535 21.4442 120.452L17.3056 118.551C16.6921 118.265 16.0424 118.91 16.3071 119.543L18.22 123.654C18.3162 123.87 18.3162 124.073 18.22 124.288L16.3312 128.387C16.0424 128.996 16.6921 129.642 17.3297 129.379Z"
      fill="#F6F7FE"
      stroke="#C0CCF8"
      stroke-miterlimit="10"
    />
    <path
      d="M168.628 89.5585L170.962 90.8134C171.082 90.8851 171.154 90.9807 171.178 91.1121L171.744 93.7055C171.828 94.0999 172.357 94.1716 172.562 93.825L173.825 91.4826C173.897 91.3631 173.993 91.2914 174.126 91.2555L176.724 90.6939C177.109 90.6102 177.193 90.0844 176.845 89.8812L174.511 88.6264C174.39 88.5546 174.318 88.459 174.294 88.3276L173.729 85.7342C173.644 85.3399 173.115 85.2682 172.91 85.6147L171.647 87.9571C171.575 88.0766 171.479 88.1483 171.347 88.1842L168.748 88.7459C168.363 88.8295 168.279 89.3554 168.628 89.5585Z"
      fill="#F6F7FE"
      stroke="#C0CCF8"
      stroke-miterlimit="10"
    />
    <path
      d="M73.4047 173.274L76.4124 171.9C76.5688 171.84 76.7252 171.84 76.8696 171.9L79.8893 173.286C80.3464 173.49 80.8156 173.023 80.6111 172.569L79.2155 169.57C79.1434 169.414 79.1434 169.259 79.2155 169.116L80.5991 166.128C80.8036 165.686 80.3344 165.208 79.8652 165.399L76.8575 166.773C76.7011 166.833 76.5447 166.833 76.4004 166.773L73.3807 165.387C72.9235 165.184 72.4543 165.65 72.6588 166.104L74.0544 169.104C74.1266 169.259 74.1266 169.414 74.0544 169.558L72.6709 172.545C72.4663 172.988 72.9355 173.466 73.4047 173.274Z"
      fill="white"
      stroke="#C0CCF8"
      stroke-miterlimit="10"
    />
    <path
      d="M203.372 58.4504L201.568 56.8131C201.483 56.7294 201.435 56.6219 201.435 56.5024L201.508 54.0644C201.52 53.6939 201.062 53.5146 200.81 53.7776L199.162 55.5821C199.077 55.6658 198.969 55.7136 198.849 55.7136L196.406 55.6419C196.046 55.6419 195.853 56.0841 196.118 56.347L197.922 57.9843C198.007 58.0679 198.055 58.1755 198.055 58.295L197.983 60.733C197.97 61.1035 198.428 61.2827 198.68 61.0198L200.328 59.2152C200.413 59.1316 200.521 59.0838 200.641 59.0838L203.084 59.1555C203.444 59.1555 203.637 58.7133 203.372 58.4504Z"
      fill="#C0CCF8"
    />
    <path
      d="M122.815 17.4469L120.89 18.3193C120.794 18.3552 120.697 18.3552 120.589 18.3193L118.664 17.4349C118.375 17.3035 118.075 17.6023 118.207 17.8891L119.097 19.8012C119.145 19.8968 119.145 19.9924 119.097 20.1L118.219 22.0121C118.087 22.2989 118.387 22.5977 118.688 22.4782L120.613 21.6058C120.709 21.5699 120.806 21.5699 120.914 21.6058L122.839 22.4902C123.128 22.6216 123.428 22.3228 123.296 22.036L122.406 20.1239C122.358 20.0283 122.358 19.9327 122.406 19.8251L123.284 17.913C123.416 17.6262 123.116 17.3274 122.815 17.4469Z"
      fill="#C0CCF8"
      stroke="#C0CCF8"
      stroke-miterlimit="10"
    />
    <path
      d="M208.75 93.9564L204.491 92.3789C204.275 92.2952 204.13 92.1399 204.046 91.9367L202.47 87.6822C202.241 87.0488 201.315 87.0488 201.062 87.6822L199.486 91.9367C199.402 92.1518 199.258 92.2952 199.041 92.3789L194.782 93.9564C194.145 94.1954 194.145 95.1037 194.782 95.3547L199.041 96.9322C199.258 97.0158 199.402 97.1712 199.486 97.3744L201.062 101.629C201.291 102.262 202.217 102.262 202.47 101.629L204.046 97.3744C204.13 97.1592 204.275 97.0158 204.491 96.9322L208.75 95.3547C209.388 95.1156 209.388 94.2074 208.75 93.9564Z"
      fill="white"
      stroke="#C0CCF8"
      stroke-miterlimit="10"
    />
    <path
      d="M41.0903 95.0679L40.3324 92.5343C40.2963 92.4028 40.3204 92.2833 40.3805 92.1638L41.8242 89.929C42.0408 89.5944 41.7159 89.1761 41.3309 89.2717L38.7804 90.0366C38.6481 90.0724 38.5278 90.0605 38.4075 89.9888L36.1698 88.5666C35.8329 88.3515 35.3998 88.6742 35.5081 89.0566L36.266 91.5902C36.3021 91.7216 36.2781 91.8411 36.2179 91.9607L34.7742 94.1955C34.5577 94.5301 34.8825 94.9484 35.2675 94.8528L37.818 94.0879C37.9503 94.0521 38.0706 94.064 38.1909 94.1357L40.4286 95.5579C40.7655 95.773 41.1986 95.4503 41.0903 95.0679Z"
      fill="white"
      stroke="#C0CCF8"
      stroke-miterlimit="10"
    />
    <path
      d="M186.998 22.0002L183.895 20.8529C183.738 20.7931 183.63 20.6856 183.57 20.5302L182.427 17.435C182.258 16.9689 181.585 16.9689 181.404 17.435L180.261 20.5302C180.201 20.6856 180.093 20.7931 179.936 20.8529L176.832 22.0002C176.363 22.1675 176.363 22.8367 176.832 23.028L179.936 24.1752C180.093 24.235 180.201 24.3426 180.261 24.4979L181.404 27.5932C181.573 28.0593 182.246 28.0593 182.427 27.5932L183.57 24.4979C183.63 24.3426 183.738 24.235 183.895 24.1752L186.998 23.028C187.468 22.8607 187.468 22.1914 186.998 22.0002Z"
      fill="#F6F7FE"
      stroke="#C0CCF8"
      stroke-miterlimit="10"
    />
    <path
      d="M90.3199 111.166H57.5483V143.72H90.3199V111.166Z"
      fill="#F6F7FE"
      stroke="#C0CCF8"
      stroke-miterlimit="10"
    />
    <path
      d="M79.7569 120.798V128.232H75.6063V132.355H68.1232V120.798H79.7569ZM79.7569 119.149H68.1232C67.2089 119.149 66.463 119.89 66.463 120.798V132.355C66.463 133.263 67.2089 134.004 68.1232 134.004H76.4364L81.4292 129.044V120.786C81.4292 119.878 80.6833 119.137 79.7689 119.137L79.7569 119.149ZM73.9341 128.232H69.7835V126.582H73.9341V128.232ZM78.0847 124.921H69.7715V123.272H78.0847V124.921Z"
      fill="#C0CCF8"
      stroke="#F6F7FE"
      stroke-width="0.75"
      stroke-miterlimit="10"
    />
    <path
      d="M156.266 144.807L93.3092 155.061L74 73.5682L75.1068 72.3373L135.97 64.7963L157.373 143.576L156.266 144.807Z"
      fill="#C0CCF8"
      stroke="#C0CCF8"
      stroke-miterlimit="10"
    />
    <path
      d="M127.007 69.7439L86.4758 76.3408L84.4547 67.8318L124.986 61.2349L127.007 69.7439Z"
      fill="#C0CCF8"
    />
    <path
      d="M157.373 143.576L94.4161 153.818L75.1068 72.3373L138.063 62.0954L157.373 143.576Z"
      fill="#F6F7FE"
      stroke="#C0CCF8"
      stroke-miterlimit="10"
    />
    <path
      d="M150.708 139.74L96.3771 148.584L79.6664 78.0379L133.997 69.1942L150.708 139.74Z"
      fill="white"
    />
    <path
      d="M149.853 127.395L96.5575 136.071L82.1207 75.1218L135.417 66.4455L149.853 127.395Z"
      fill="white"
      stroke="#C0CCF8"
      stroke-miterlimit="10"
    />
    <path
      d="M101.021 142.405C99.7577 142.107 98.6749 141.055 97.9771 139.848C97.2793 138.641 96.9305 137.302 96.5816 135.964L149.877 127.287C150.226 128.614 150.587 129.964 151.273 131.171C151.959 132.378 153.042 133.43 154.317 133.729"
      fill="white"
    />
    <path
      d="M101.021 142.405C99.7577 142.107 98.6749 141.055 97.9771 139.848C97.2793 138.641 96.9305 137.302 96.5816 135.964L149.877 127.287C150.226 128.614 150.587 129.964 151.273 131.171C151.959 132.378 153.042 133.43 154.317 133.729"
      stroke="#C0CCF8"
      stroke-miterlimit="10"
    />
    <path
      d="M149.553 129.964L97.7245 137.075L96.8944 133.98L148.518 125.578L149.553 129.964Z"
      fill="white"
    />
    <path
      d="M154.702 133.789C156.555 133.514 158.179 131.088 157.734 127.431C157.734 127.419 157.721 127.407 157.709 127.407L104.45 136.071C104.45 136.071 104.438 136.071 104.438 136.095C105.508 139.513 103.836 141.688 101.562 142.405C101.538 142.405 101.021 142.525 101.045 142.525L154.69 133.789H154.702Z"
      fill="white"
      stroke="#C0CCF8"
      stroke-miterlimit="10"
    />
    <path
      d="M128.126 69.2659L87.5826 75.8628L85.5735 67.3538L126.105 60.7569L128.126 69.2659Z"
      fill="#C0CCF8"
    />
    <path
      d="M110.285 63.0156C110.79 65.1548 109.43 67.1745 107.253 67.533C105.075 67.8916 102.886 66.4455 102.38 64.3063C101.875 62.1671 103.235 60.1474 105.412 59.7889C107.59 59.4304 109.779 60.8764 110.285 63.0156Z"
      fill="#C0CCF8"
    />
    <path
      d="M108.901 63.2427C109.226 64.6409 108.348 65.9555 106.928 66.1826C105.508 66.4096 104.089 65.4655 103.752 64.0792C103.415 62.6929 104.305 61.3664 105.725 61.1393C107.145 60.9122 108.564 61.8564 108.901 63.2427Z"
      fill="#F6F7FE"
      stroke="#C0CCF8"
      stroke-miterlimit="10"
    />
    <path
      d="M111.548 84.6826C102.092 86.2242 96.1966 94.9962 98.3982 104.27C100.6 113.544 110.056 119.818 119.5 118.276C128.956 116.735 134.851 107.963 132.65 98.689C130.448 89.4151 120.992 83.1409 111.548 84.6826ZM118.706 114.918C111.151 116.149 103.571 111.118 101.815 103.708C100.058 96.2988 104.786 89.2717 112.33 88.0408C119.873 86.8098 127.464 91.8411 129.221 99.2507C130.977 106.66 126.249 113.687 118.706 114.918ZM108.287 112.241L119.765 104.282L122.76 90.6938L111.283 98.6531L108.287 112.241ZM115.085 99.6331C116.131 99.4658 117.166 100.147 117.407 101.175C117.647 102.203 117.01 103.159 115.963 103.326C114.916 103.493 113.882 102.812 113.641 101.784C113.4 100.756 114.038 99.8004 115.085 99.6331Z"
      fill="#C0CCF8"
    />
  </svg>
)

export default CoolKidsFeedback
