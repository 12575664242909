import { ClientAccountResponse } from '@api'
import {
  CloseIcon,
  Headphones,
  InPersonFilled,
  NoHeadsetIcon,
  Online
} from '@blueprinthq/joy'
import {
  Alert,
  AlertDescription,
  Box,
  Button,
  Fade,
  HStack,
  Icon,
  Stack,
  Text,
  useBreakpointValue,
  VStack,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  useDisclosure
} from '@chakra-ui/react'
import { useAudioCapture, useExperienceManager, usePanelManager } from '@hooks'
import { trackEvent } from '@lib/clinician-tracking'
import WarningIcon from '@material-ui/icons/Warning'
import React, { useEffect } from 'react'
import { isMobile, isSafari } from 'react-device-detect'
import { AudioLevelV2 } from './audio-level-v2'
import { AutoStopTimer } from './auto-stop-timer'
import { FreeTierSessionLimitReached } from './free-tier-session-limit-reached'
import { MicrophonePermissionsModal } from './mic-permissions-modal'
import { MicrophoneBlockedWarning } from './microphone-blocked-warning'
import { MicrophoneSelectorV2 } from './microphone-selector-v2'
import { MobileTelehealthToggle } from './mobile-telehealth-toggle'
import { SessionControlsFooter } from './session-controls-footer'
import { SettingsDialog } from './settings-dialog'
import StopRecordingAfter from './stop-recording-after'
import { TelehealthToggle } from './telehealth-toggle'

type AINotetakerPanelV2Props = {
  sessionId: string
  hasStartedRecording: boolean
  startRecording: () => void
  isRecordingLoading: boolean
  recordingDuration: number
  isDictation: boolean
  recordingStartedAt: string | null | undefined
  onOpenEndModal: () => void
  client: ClientAccountResponse | undefined
  showRecordingLengthWarning: boolean
}

const formatTime = (seconds: number) => {
  const hours = Math.floor(seconds / 3600)
  const remainingMinutes = Math.floor((seconds % 3600) / 60)
  const remainingSeconds = seconds % 60

  if (hours > 0) {
    return `${hours < 10 ? '0' : ''}${hours}:${
      remainingMinutes < 10 ? '0' : ''
    }${remainingMinutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`
  }

  return `${remainingMinutes < 10 ? '0' : ''}${remainingMinutes}:${
    remainingSeconds < 10 ? '0' : ''
  }${remainingSeconds}`
}

export const AINotetakerPanelV2: React.FC<AINotetakerPanelV2Props> = ({
  hasStartedRecording,
  startRecording,
  isRecordingLoading,
  recordingDuration,
  isDictation,
  recordingStartedAt,
  onOpenEndModal,
  client,
  showRecordingLengthWarning,
}) => {
  const {
    documentationAutomationFreeTierSessionLimitReached
  } = useExperienceManager()

  const {
    audioInputs,
    isTelehealth,
    setIsTelehealth,
    isUsingHeadphones,
    isMuted,
    hasDetectedAudio,
    setIsUsingHeadphones,
    testAudioInputs,
    permissionStatus,
    isSettingsOpen,
    isStopRecordingAfterEnabled,
    stopRecordingAfter,
    setIsSettingsOpen,
    setIsStopRecordingAfterEnabled,
    setStopRecordingAfter,
    startContentShare,
    toggleMute,
    promptForDevicePermissions
  } = useAudioCapture()

  const {
    isMobileAssistOpen,
  } = usePanelManager()

  const needAudioSharing = isTelehealth && isUsingHeadphones && !isMobile
  const showSafariWarning =
    isSafari && isTelehealth && !isMobile && !isDictation

  const isMobileBreakpoint = useBreakpointValue({ base: true, md: false })

  const [showPermissionModal, setShowPermissionModal] = React.useState(false)
  const [showRecordingWarning, setShowRecordingWarning] = React.useState(false)
  const [lastInteractionAt, setLastInteractionAt] = React.useState<number>(
    Date.now()
  )
  const [startRecordingButtonWidth, setStartRecordingButtonWidth] = React.useState<number>(0)
  const startRecordingButtonRef = React.useRef<HTMLButtonElement>(null)
  const [hasDismissedRecordingWarning, setHasDismissedRecordingWarning] = React.useState(false)

  const { isOpen: isPopoverOpen, onClose: closePopover, onOpen: openPopover } = useDisclosure()

  const handleSessionTypeChange = (isTelehealth: boolean) => {
    if (!isTelehealth) {
      setIsUsingHeadphones(false)
    }
    setIsTelehealth(isTelehealth)
    trackEvent('Confirm Audio Settings Dialog -> Changed Setting Input', {
      newSettingSelection: isTelehealth ? 'telehealth' : 'in_person'
    })
  }

  useEffect(() => {
    if (
      (isTelehealth === null || isTelehealth === undefined) &&
      client?.is_demo
    ) {
      handleSessionTypeChange(false)
    }
  }, [isTelehealth, client])

  const handleStartRecording = async () => {
    if (needAudioSharing) {
      await startContentShare()
    }

    window.scrollTo(0, 0)

    startRecording()
  }

  const handleMuteToggle = () => {
    toggleMute()
  }

  const handleSettingsClick = () => {
    trackEvent('Session -> Clicked Settings')
    setIsSettingsOpen(true)
  }

  useEffect(() => {
    testAudioInputs()
  }, [testAudioInputs])

  useEffect(() => {
    // Telehealth not possible on mobile, and we hide the option
    if (isMobile) {
      setIsTelehealth(false)
    }
  }, [isMobile])

  useEffect(() => {
    const handlePageInteraction = () => {
      setLastInteractionAt(Date.now())
    }

    document.addEventListener('click', handlePageInteraction)
    document.addEventListener('touchstart', handlePageInteraction)

    return () => {
      document.removeEventListener('click', handlePageInteraction)
      document.removeEventListener('touchstart', handlePageInteraction)
    }
  }, [])

  useEffect(() => {
    const checkBannerConditions = () => {
      if (showRecordingWarning) {
        return
      }

      const timeSinceLastInteraction = Date.now() - lastInteractionAt

      const shouldShow =
        !hasStartedRecording &&
        hasDetectedAudio &&
        !client?.is_demo &&
        timeSinceLastInteraction > 10000 // 10 seconds

      setShowRecordingWarning(shouldShow)
    }

    const intervalId = setInterval(checkBannerConditions, 1000)

    return () => {
      clearInterval(intervalId)
    }
  }, [hasStartedRecording, hasDetectedAudio, lastInteractionAt])

  useEffect(() => {
    if (permissionStatus === 'unknown') {
      setShowPermissionModal(true)
    }
  }, [permissionStatus])

  const handlePermissionModalClose = async () => {
    setShowPermissionModal(false)
    await promptForDevicePermissions()
  }

  useEffect(() => {
    const updateWidth = () => {
      if (startRecordingButtonRef.current) {
        setStartRecordingButtonWidth(startRecordingButtonRef.current.offsetWidth)
      }
    }

    updateWidth()
    window.addEventListener('resize', updateWidth)
    
    return () => window.removeEventListener('resize', updateWidth)
  }, [])

  useEffect(() => {
    if (showRecordingWarning && !hasDismissedRecordingWarning && !isMobileAssistOpen) {
      // Briefly close and reopen the popover to force position recalculation
      closePopover()
      const timer = setTimeout(() => {
        openPopover()
      }, 400)
      return () => clearTimeout(timer)
    }
  }, [isTelehealth])

  if (documentationAutomationFreeTierSessionLimitReached) {
    return <FreeTierSessionLimitReached onUpgrade={startRecording} />
  }

  if (audioInputs.length === 0 && permissionStatus === 'denied') {
    return <MicrophoneBlockedWarning />
  }

  if (hasStartedRecording) {
    return (
      <VStack
        data-testid="session-timer-controls-container"
        justifyContent="center"
        color="white"
        height="100%"
        minWidth="320px"
        textAlign="center"
        display="flex"
      >
        <Box flex={isMobileBreakpoint ? '5' : '1'} />
        <VStack
          flex={isMobileBreakpoint ? '5' : '1'}
          data-testid="centered-session-timer-controls"
        >
          <Text
            fontSize="16px"
            style={{ marginTop: 0 }}
            color={isMuted ? '#282828' : 'primary'}
          >
            {isMuted ? 'Recording paused' : 'Recording in progress'}
          </Text>
          <Text
            width="100%"
            color={isMuted ? '#282828' : 'primary'}
            fontSize="88px"
            fontWeight="300"
          >
            {formatTime(recordingDuration)}
          </Text>
          <AudioLevelV2 />
          {!isMuted && (
            <Text fontSize="16px" paddingTop="8px" color="#757575">
              A progress note will be generated after you
              <br />
              end the session.
            </Text>
          )}
          {isMuted && (
            <Text fontSize="16px" paddingTop="8px" color="#757575">
              Your notes won't include the conversation
              <br />
              that took place during this period.
            </Text>
          )}
          {isStopRecordingAfterEnabled && (
            <AutoStopTimer recordingStartedAt={recordingStartedAt} />
          )}
        </VStack>
        <SessionControlsFooter
          isMuted={isMuted}
          onMuteToggle={handleMuteToggle}
          onSettingsClick={handleSettingsClick}
          onEndSession={onOpenEndModal}
          showRecordingLengthWarning={showRecordingLengthWarning}
        />
        <MicrophonePermissionsModal
          isOpen={showPermissionModal}
          onClose={handlePermissionModalClose}
        />
        <SettingsDialog
          isOpen={isSettingsOpen}
          onClose={() => setIsSettingsOpen(false)}
          onShareAudio={startContentShare}
          isDictation={isDictation}
        />
      </VStack>
    )
  }

  return (
    <HStack
      justifyContent="center"
      w="100%"
      px={{ base: 4, sm: 0 }}
      data-testid="ai-notetaker-panel-hstack"
      mb={{ base: '40px', sm: '80px' }}
    >
      <Stack
        gap="13px"
        alignItems="center"
        textAlign="center"
        maxW={{
          base: '100%',
          sm: '400px'
        }}
        w="100%"
      >
        <Box>
          <Text fontSize="16px" style={{ marginTop: 0 }} color="#757575">
            Not yet recording
          </Text>
          <Text
            width="100%"
            color={hasStartedRecording ? 'primary' : '#2D54E833'}
            fontSize="88px"
            fontWeight="300"
          >
            {formatTime(recordingDuration)}
          </Text>
        </Box>
        <MicrophoneSelectorV2 />
        {isMobile && <MobileTelehealthToggle />}
        {!isMobile && <TelehealthToggle isDictation={isDictation} />}
        <Popover
          isOpen={isPopoverOpen && showRecordingWarning && !hasDismissedRecordingWarning && !isMobileAssistOpen}
          placement="bottom"
          autoFocus={false}
          closeOnBlur={false}
          closeOnEsc={false}
        >
          <PopoverTrigger>
            <Button
              ref={startRecordingButtonRef}
              bg="primary"
              color="white"
              fontWeight="400"
              height="48px"
              width="100%"
              variant="body"
              isLoading={isRecordingLoading}
              data-click-event-name="Session -> Clicked Start Recording"
              onClick={() => {
                handleStartRecording()
              }}
              _hover={{
                bg: 'primary',
                opacity: 0.8
              }}
              _focus={{
                outline: 'none'
              }}
              id="pendo-session-panel-start-recording"
              sx={{
                transition: 'transform 100ms cubic-bezier(0.23, 1, 0.32, 1)',
                '&:active': {
                  transform: 'scale(0.98)'
                }
              }}
            >
              {isDictation
                ? 'Dictate'
                : needAudioSharing
                  ? 'Share browser audio + begin recording'
                  : 'Start Recording'}
            </Button>
          </PopoverTrigger>
          <PopoverContent
            bg="white"
            border="none"
            boxShadow="0px 4px 6px -2px #0000000D, 0px 10px 15px -3px #0000001A"
            p={4}
            width={`${startRecordingButtonWidth}px`}
            maxWidth="100vw"
            mx="auto"
            onClick={() => {
              setHasDismissedRecordingWarning(true)
            }}
            cursor={'pointer'}
          >
            <PopoverArrow bg="white" />
            <HStack justify="space-between">
              <HStack>
                <Icon as={WarningIcon} color="primary" boxSize={6} />
                <Text color="#282828" textAlign="left" ml={4}>
                  Don’t forget to hit <b>'Start Recording'</b> to begin your session!
                </Text>
              </HStack>
              <CloseIcon
                ml="small"
              />
            </HStack>
          </PopoverContent>
        </Popover>
        {!isDictation && (
          <StopRecordingAfter
            isEnabled={isStopRecordingAfterEnabled}
            setIsEnabled={setIsStopRecordingAfterEnabled}
            value={stopRecordingAfter}
            onChange={setStopRecordingAfter}
            useV2Styles={true}
          />
        )}
        {showSafariWarning && (
          <Alert
            status="error"
            bg="#FFF2F2"
            borderRadius="8px"
            p={4}
            border={`1px solid rgba(40, 40, 40, 0.05)`}
          >
            <Box mr={2} w="20px" h="20px">
              <Icon
                as={WarningIcon}
                color="red.500"
                mr="16px"
                mt="2px"
                boxSize={6}
              />
            </Box>
            <AlertDescription
              color="#282828"
              textAlign="left"
              marginLeft="16px"
            >
              <b>Important:</b> Due to a limitation of the Safari browser, the
              AI Notetaker feature will not be able to capture participant audio
              duing a telehealth session. For the best results, please use
              Chrome.
            </AlertDescription>
          </Alert>
        )}
      </Stack>
      <MicrophonePermissionsModal
        isOpen={showPermissionModal}
        onClose={handlePermissionModalClose}
      />
    </HStack>
  )
}
