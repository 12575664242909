import React, { Fragment } from 'react'
import { Select } from '@chakra-ui/react'
import { useQuery } from 'react-query'
import { endpoints } from '@api'
import { Clinic } from './models'

export const OrgClinicSelect = ({
  onClinicSelected,
  selectedClinicId,
  organizationId
}: {
  onClinicSelected: (selectedClinic: Clinic) => void
  selectedClinicId: string
  organizationId: string
}) => {
  const handleOrgSelectChange = (clinicId: string) => {
    const foundClinic = (orgClinicList ?? []).find(c => c.id === clinicId)
    if (foundClinic) {
      onClinicSelected(
        (({ id, name, display_id }) => ({ id, name, display_id }))(foundClinic)
      )
    }
  }

  const { data: orgClinicList, isLoading: clinicsLoading } = useQuery(
    [endpoints.getOrganizationClinics.getCacheId(), organizationId],
    () =>
      endpoints.getOrganizationClinics.request({
        organizationId: organizationId
      }),
    {
      initialData: [],
      select: data =>
        (data as Clinic[]).map(c => ({
          id: c.id,
          name: c.name,
          display_id: c.display_id
        }))
    }
  )

  return (
    <Fragment>
      {!clinicsLoading && (orgClinicList?.length ?? 0) > 1 && (
        <Select
          mr="16px"
          value={selectedClinicId}
          onChange={e => handleOrgSelectChange(e.target.value)}
          borderColor="gray.300"
        >
          {(orgClinicList ?? []).map(clinic => (
            <option key={clinic.id} value={clinic.id}>
              {clinic.name}
            </option>
          ))}
        </Select>
      )}
    </Fragment>
  )
}
