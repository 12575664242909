import React, { useMemo, useCallback } from 'react'
import { useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'
import { Loading } from '../../components'
import { PatientsList } from './PatientsList'
import { endpoints } from '../../api'
import { AssignClinicianModal } from '../../containers'
import {
  pendingColumns,
  awaitingInviteColumns,
  inviteSentColumns,
  activeColumns,
  dischargedColumns
} from './column-layouts'
import { makeStyles } from '@material-ui/core'
import patientListStyles from './PatientsListStyles'
import { TABS as CLIENT_LIST_TABS } from '@constants/clientList'
import { SortBy } from '@constants/sortBy'
import { useStoreState, useStoreActions } from 'easy-peasy'
import { getClientStatusLabel } from '@utilities'

const useStyles = makeStyles(patientListStyles)

export function PatientListContainer(props) {
  const {
    currentClinicianId,
    currentClinicId,
    styles,
    currentPage,
    ...rest
  } = props
  const {
    selectedPatientTypeOption,
    isAssignClinicianModalOpen,
    selectedClinician,
    sortValues
  } = useStoreState(state => state.patientList)
  const { user } = useStoreState(state => state.auth)
  const {
    setSortValue,
    setIsAssignClinicianModalOpen,
    setAssignClinicianModalClient
  } = useStoreActions(actions => actions.patientList)

  const history = useHistory()
  const classes = useStyles()

  const { isLoading, data, error, isError } = useQuery(
    [
      endpoints.getPatientList.getCacheId(),
      currentPage,
      selectedPatientTypeOption,
      sortValues[selectedPatientTypeOption.value],
      currentClinicId,
      currentClinicianId === '' ? undefined : currentClinicianId
    ],
    () =>
      endpoints.getPatientList.request({
        page: currentPage,
        status: selectedPatientTypeOption.status,
        clinician_id:
          currentClinicianId === '' ? undefined : currentClinicianId,
        clinic_id: currentClinicId,
        sortBy: sortValues[selectedPatientTypeOption.value].sortBy,
        order: sortValues[selectedPatientTypeOption.value].order
      }),
    {
      enabled: Boolean(currentClinicId),
      keepPreviousData: true
    }
  )

  const emptyStateMessage = useMemo(() => {
    const statusLabel = getClientStatusLabel(selectedPatientTypeOption.status)
    if (selectedClinician && user) {
      if (!selectedClinician.id || selectedClinician.id === user.id) {
        return `You have no ${statusLabel} clients.`
      }

      if (selectedClinician.id === 'all') {
        return `There are no ${statusLabel} clients at this clinic.`
      }

      return `${selectedClinician.display} has no ${statusLabel} clients.`
    }

    return 'No clients to display'
  }, [user, selectedClinician, selectedPatientTypeOption])

  const formattedData = useMemo(() => {
    if (!data) return []

    let filteredData = data.nodes.map(patient => {
      return {
        icon: patient.platform,
        name: `${patient.first_name} ${patient.last_name}`,
        date_of_birth: patient.date_of_birth,
        created_at: patient.created_at,
        email: patient.email,
        phone: patient.phone_number,
        assessment_date: patient.assessment_created_at,
        check_in_date: patient.check_in_created_at,
        path: `/patient/${patient.patient_id}`,
        patient_id: patient.patient_id,
        is_archived: patient.is_archived,
        has_overdue_assessments: patient.has_overdue_assessments,
        is_high_risk: patient.is_high_risk,
        clinic_id: patient.clinic_id,
        date_of_next_appt: patient.date_of_next_appt,
        date_of_invite: patient.date_of_invite,
        clinician_ids: patient.clinician_ids,
        active_screener_assessment_score_id:
          patient.active_screener_assessment_score_id,
        clinician: {
          first_name: patient.clinician_first_name,
          last_name: patient.clinician_last_name
        },
        report_status: {
          status: patient.report_status,
          updated_at: patient.report_status_updated_at
        },
        next_administration_date: patient.next_administration_date,
        source: patient.creation_source,
        archived_date: patient.archived_date,
        archived_reason: patient.archived_reason
      }
    })
    return filteredData
  }, [data])

  const onPatientClick = useCallback(
    row => {
      if (row && row.original) {
        let path = row.original.path
        if (selectedPatientTypeOption.value === CLIENT_LIST_TABS.PENDING) {
          path = `/pending-client/${row.original.patient_id}`
        }
        history.push(path)
      }
    },
    [selectedPatientTypeOption]
  )

  const onSortChange = useCallback(
    options => {
      const [{ desc, id }] = options
      const order = desc ? 'desc' : 'asc'
      const setSortValueForSelectedOption = sortBy =>
        setSortValue({
          name: selectedPatientTypeOption.value,
          sort: { sortBy, order }
        })

      switch (id) {
        case 'assessment_date':
          setSortValueForSelectedOption(SortBy.ASSESSMENT)
          break
        case 'check_in_date':
          setSortValueForSelectedOption(SortBy.CHECK_IN)
          break
        case 'name':
          setSortValueForSelectedOption(SortBy.PATIENT_NAME)
          break
        case 'date_of_next_appt':
          setSortValueForSelectedOption(SortBy.DATE_OF_NEXT_APPT)
          break
        case 'date_of_invite':
          setSortValueForSelectedOption(SortBy.DATE_OF_INVITE)
          break
        case 'created_at':
          setSortValueForSelectedOption(SortBy.CREATED_AT)
          break
        case 'archived_date':
          setSortValueForSelectedOption(SortBy.ARCHIVED_DATE)
          break
        default:
          break
      }
    },
    [selectedPatientTypeOption.value]
  )

  if (isLoading) {
    return (
      <div style={{ height: '50vh' }}>
        <Loading />
      </div>
    )
  }

  if (!formattedData.length) {
    return <h1 className={styles.no_archived_patients}>{emptyStateMessage}</h1>
  }

  if (isError) {
    return <p className={styles.error}>{error.message}</p>
  }

  const openAssignClinicianModal = client => {
    setIsAssignClinicianModalOpen(true)
    setAssignClinicianModalClient(client)
  }

  const closeAssignClinicianModal = () => {
    setIsAssignClinicianModalOpen(false)
  }

  const determineColumns = () => {
    switch (selectedPatientTypeOption.value) {
      case CLIENT_LIST_TABS.PENDING:
        return pendingColumns({
          classes,
          onRowClick: onPatientClick,
          openAssignClinicianModal
        })
      case CLIENT_LIST_TABS.AWAITING_INVITE:
        return awaitingInviteColumns({ classes, onRowClick: onPatientClick })
      case CLIENT_LIST_TABS.INVITE_SENT:
        return inviteSentColumns({
          classes,
          onRowClick: onPatientClick,
          sendInvite: props.sendInvite
        })
      case CLIENT_LIST_TABS.ACTIVE:
        return activeColumns({
          classes,
          onRowClick: onPatientClick,
          sendInvite: props.sendInvite
        })
      case CLIENT_LIST_TABS.DISCHARGED:
        return dischargedColumns({ classes, onRowClick: onPatientClick })
      case CLIENT_LIST_TABS.UNKNOWN:
        return []
      default:
        return []
    }
  }

  return (
    <>
      <PatientsList
        onRowClick={onPatientClick}
        patientList={formattedData}
        columns={determineColumns()}
        totalPages={data.meta.totalPageCount}
        pageSize={data.meta.pageSize}
        currentPage={currentPage}
        onSortChange={onSortChange}
        {...rest}
      />
      <AssignClinicianModal
        isModalOpen={isAssignClinicianModalOpen}
        closeModal={closeAssignClinicianModal}
      />
    </>
  )
}
