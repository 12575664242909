import React, { useState, useEffect, useRef, useCallback } from 'react'
import { AudioCaptureContext } from './audio-capture-context'
import { Actions, useStoreActions, useStoreState } from 'easy-peasy'
import { useMutation, useQueryClient } from 'react-query'
import { endpoints } from '@api'
import { useExperienceManager } from '@hooks'
import moment from 'moment'
import { datadogLogs } from '@datadog/browser-logs'
import * as uuid from 'uuid'
import { isMobile } from 'react-device-detect'
import { useToast, Box } from '@chakra-ui/react'
import { StoreModel } from 'src/store/types'

interface ManualAudioCaptureProviderProps {
  sessionId?: string
  refetchRecap?: () => void
  children: React.ReactNode
}

interface UploadPayload {
  timestamp: number
  chunks: BlobPart[]
}

const LOCAL_STORAGE_KEY = 'sessionRecordings'

const ManualAudioCaptureProviderV2: React.FC<ManualAudioCaptureProviderProps> = ({
  sessionId,
  refetchRecap,
  children
}) => {
  const [isRecording, setIsRecording] = useState(false)
  const [isMuted, setIsMuted] = useState(false)
  const [devices, setDevices] = useState<MediaDeviceInfo[]>([])
  const [selectedDevice, setSelectedDevice] = useState<MediaDeviceInfo | null>(
    null
  )
  const [selectedDeviceLabel, setSelectedDeviceLabel] = useState('')
  const [permissionStatus, setPermissionStatus] = useState<string | null>(null)
  const [hasMicAccess, setHasMicAccess] = useState(true)
  const [localRecordingId, _setLocalRecordingId] = useState<string>(uuid.v4())
  const [isSettingsOpen, setIsSettingsOpen] = useState<boolean>(false)
  const [
    isStopRecordingAfterEnabled,
    setIsStopRecordingAfterEnabled
  ] = useState<boolean>(false)
  const [stopRecordingAfter, setStopRecordingAfter] = useState<string>('')

  const [uploadQueue, setUploadQueue] = useState<UploadPayload[]>([])
  const [isLastChunkSet, setIsLastChunkSet] = useState(false)
  const [isUploading, setIsUploading] = useState(false)
  const [isUploadComplete, setIsUploadComplete] = useState(false)

  const [isTesting, setIsTesting] = useState<boolean>(false)
  const [disableEchoCancelation, setDisableEchoCancelation] = useState(false)
  const [hasDetectedAudio, setHasDetectedAudio] = useState<boolean>(false)
  const [audioLastDetectedAt, setAudioLastDetectedAt] = useState<number | null>(
    null
  )
  const [showMicWarning, setShowMicWarning] = useState<boolean>(false)

  const [isTelehealth, setIsTelehealth] = useState<boolean | null>(null)
  const [isUsingHeadphones, setIsUsingHeadphones] = useState(false)
  const [isOnline, setIsOnline] = useState(navigator.onLine)
  const [
    hasResolvedInputDevicePermissions,
    setHasResolvedInputDevicePermissions
  ] = useState<boolean>(false)
  const [uploadCount, setUploadCount] = useState(0)
  const [uploadUrl, setUploadUrl] = useState<string | null>(null)
  const [uploadToken, setUploadToken] = useState<string | null>(null)
  const audioStreamRef = useRef<MediaStream | null>(null)
  const selectedDeviceLabelRef = useRef<string>(selectedDeviceLabel)
  const streamsRef = useRef<MediaStream[]>([])
  const tabStreamRef = useRef<MediaStream | null | undefined>(null)
  const mediaRecorderRef = useRef<MediaRecorder | null>(null)
  const isRecordingRef = useRef<boolean>(isRecording)
  const isLastChunkSetRef = useRef<boolean>(false)
  const isUploadCompleteRef = useRef<boolean>(isUploadComplete)
  const isTestingRef = useRef<boolean>(isTesting)
  const audioContextRef = useRef<AudioContext | null>(null)
  const uploadQueueRef = useRef<UploadPayload[]>(uploadQueue)
  const recordingCutoffTimeRef = useRef<string | null>(null)
  const mediaRecordersRef = useRef<MediaRecorder[]>([])
  const timerRef = useRef<number | null>(null)
  const selectedDeviceRef = useRef<MediaDeviceInfo | null>(selectedDevice)
  const maxVolumeRef = useRef<number>(0)
  const volumeActions = useStoreActions(
    (actions: Actions<StoreModel>) => actions.volume
  )

  const queryClient = useQueryClient()

  const toast = useToast()

  const { user } = useStoreState((state: StoreModel) => state.auth)

  const {
    documentationAutomationFreeTierSessionLimitReached,
    isAdmin,
    isPresignedUrlV2Enabled
  } = useExperienceManager()

  const selectedAudioInputLabel = devices.find(
    i => i.deviceId === selectedDevice?.deviceId
  )?.label

  const logDataRef = useRef({})

  const {
    mutateAsync: updateSession,
    isLoading: isUpdateSessionLoading
  } = useMutation(endpoints.startAudioCapture.request, {
    onSuccess: (data: any) => {
      if (data.audioUpload?.url) {
        setUploadUrl(data.audioUpload.url)
        setUploadToken(data.audioUpload.token)
      }
      refetchRecap && refetchRecap()
    }
  })

  const { mutateAsync: getPresignedUrl } = useMutation(
    endpoints.getPresignedUrl.request
  )

  const { mutateAsync: completeScribeOnboarding } = useMutation(
    endpoints.postUserAccount.request,
    {
      onSuccess() {
        queryClient.invalidateQueries(endpoints.getUserAccount.getCacheId())
      }
    }
  )

  const handleMarkOnboardingComplete = async () => {
    if (!user?.has_onboarded_to_scribe) {
      await completeScribeOnboarding({
        data: { has_onboarded_to_scribe: true }
      })
    }
  }

  logDataRef.current = {
    isV2: true,
    sessionId,
    isRecording,
    isTesting,
    hasMicAccess,
    uploadQueue: uploadQueue.length,
    hasDetectedAudio,
    audioLastDetectedAt,
    isTelehealth,
    isUsingHeadphones,
    isMuted,
    isUploading,
    isUploadComplete,
    isLastChunkSet: isLastChunkSetRef.current,
    showMicWarning,
    disableEchoCancelation,
    devices,
    selectedAudioInput: selectedDevice?.deviceId,
    selectedAudioInputLabel,
    documentationAutomationFreeTierSessionLimitReached,
    isAdmin,
    isOnline,
    recordingCutoffTime: recordingCutoffTimeRef.current,
    stopRecordingAfter,
    isStopRecordingAfterEnabled,
    rollingUploadCount: uploadCount,
    uploadUrl,
    maxVolume: maxVolumeRef.current
  }

  useEffect(() => {
    if (devices.length && !selectedDevice) {
      const lastDeviceId = localStorage.getItem('microphoneDeviceId')
      const device =
        devices.find(d => d.deviceId === lastDeviceId) || devices[0]

      setSelectedDevice(device)
      setSelectedDeviceLabel(device.label)
      datadogLogs.logger.info('[Audio Capture] Device Set', {
        ...logDataRef.current,
        deviceId: device.deviceId
      })
    }
  }, [devices, selectedDevice])

  useEffect(() => {
    if (
      isRecordingRef.current &&
      !isTestingRef.current &&
      !isSettingsOpen &&
      selectedDeviceLabel &&
      selectedDeviceLabel !== selectedDeviceLabelRef.current &&
      selectedDeviceLabel !== 'Default'
    ) {
      datadogLogs.logger.info('[Audio Capture] Showing device changed', {
        ...logDataRef.current,
        newDevice: selectedDeviceLabel
      })
      toast({
        position: 'top',
        isClosable: true,
        duration: 4000,
        containerStyle: {
          width: '800px',
          maxWidth: '100%'
        },
        render: () => (
          <Box
            p={2}
            bg="black"
            border="1px solid white"
            color="white"
            borderRadius="8px"
            textAlign="center"
          >
            Your default microphone has changed to {selectedDeviceLabel} and
            will now be used.
          </Box>
        )
      })
    }
  }, [selectedDeviceLabel])

  useEffect(() => {
    const handleDeviceChange = async () => {
      datadogLogs.logger.info(
        '[Audio Capture] Device Changed Event',
        logDataRef.current
      )
      const microphoneDevices = await getInputDevices()
      const isSelectedDeviceRemoved =
        microphoneDevices.filter(
          d =>
            d.deviceId === selectedDeviceRef.current?.deviceId &&
            d.groupId === selectedDeviceRef.current?.groupId
        ).length === 0

      if (isSelectedDeviceRemoved) {
        setSelectedDevice(null)
      }
    }

    navigator.mediaDevices.addEventListener('devicechange', handleDeviceChange)
    return () => {
      navigator.mediaDevices.removeEventListener(
        'devicechange',
        handleDeviceChange
      )
    }
  }, [])

  useEffect(() => {
    const handleVisibilityChange = async () => {
      if (!document.hidden && isRecordingRef.current && isMobile) {
        datadogLogs.logger.info(
          '[Audio Capture] Mobile tab visible',
          logDataRef.current
        )
        await refreshMediaRecorder()
      }
    }

    document.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [])

  useEffect(() => {
    const handleOnlineStatusChange = () => {
      setIsOnline(navigator.onLine)
    }

    window.addEventListener('online', handleOnlineStatusChange)
    window.addEventListener('offline', handleOnlineStatusChange)

    return () => {
      window.removeEventListener('online', handleOnlineStatusChange)
      window.removeEventListener('offline', handleOnlineStatusChange)
    }
  }, [])

  useEffect(() => {
    const handleStorageChange = async (event: any) => {
      if (event.key === LOCAL_STORAGE_KEY) {
        try {
          const values = JSON.parse(event.newValue) || {}
          const value = values[sessionId!]
          if (value && value !== localRecordingId) {
            datadogLogs.logger.info(
              '[Audio Capture] Auto-stopping duplicate tab recording',
              logDataRef.current
            )
            await stopRecording()
          }
        } catch (err) {
          datadogLogs.logger.warn(
            '[Audio Capture] Unable to parse localStorage',
            {
              ...logDataRef.current
            },
            err as Error
          )
        }
      }
    }

    window.addEventListener('storage', handleStorageChange)

    return () => {
      window.removeEventListener('storage', handleStorageChange)
    }
  }, [])

  // keep refs in sync w/ state
  useEffect(() => {
    selectedDeviceLabelRef.current = selectedDeviceLabel
  }, [selectedDevice])

  useEffect(() => {
    selectedDeviceRef.current = selectedDevice
  }, [selectedDevice])

  useEffect(() => {
    isTestingRef.current = isTesting
  }, [isTesting])

  useEffect(() => {
    uploadQueueRef.current = uploadQueue
  }, [uploadQueue])

  useEffect(() => {
    datadogLogs.logger.info('[Audio Capture] Online status changed', {
      ...logDataRef.current,
      isOnline
    })
  }, [isOnline])

  useEffect(() => {
    // timer to track how many chunks of audio are uploaded in a 60 second window
    if (!timerRef.current) {
      timerRef.current = window.setInterval(() => {
        setUploadCount(0)
      }, 60000)
    }

    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current)
        timerRef.current = null
      }
    }
  }, [])

  // clean up audio streams on unmount
  useEffect(() => {
    return () => {
      datadogLogs.logger.info('[Audio Capture] Unmounted', {
        ...logDataRef.current
      })
      stopAudioStream()
      stopRecording()
    }
  }, [])

  useEffect(() => {
    async function resolveInputDevicePermissionStatus() {
      try {
        const permissionStatus = await navigator.permissions.query({
          name: 'microphone' as PermissionName
        })

        if (permissionStatus.state === 'denied') {
          setPermissionStatus('denied')
          setHasMicAccess(false)
        } else if (permissionStatus.state === 'granted') {
          setPermissionStatus('granted')
          await getInputDevices()
        } else {
          setPermissionStatus('unknown')
        }

        setHasResolvedInputDevicePermissions(true)
      } catch (error) {
        console.error('Error querying microphone permission:', error)

        // Fallback for browsers that don't support the Permissions API
        try {
          const stream = await navigator.mediaDevices.getUserMedia({
            audio: true
          })
          if (stream) {
            setPermissionStatus('granted')
            setHasMicAccess(true)
            await getInputDevices()
          } else {
            setPermissionStatus('denied')
            setHasMicAccess(false)
          }

          setHasResolvedInputDevicePermissions(true)
        } catch (e) {
          console.error('Error granting microphone permission with fallback', e)
        }
      }
    }
    resolveInputDevicePermissionStatus()
  }, [])

  useEffect(() => {
    const interval = setInterval(async () => {
      if (isRecordingRef.current && recordingCutoffTimeRef.current) {
        const cutoffTime = moment(recordingCutoffTimeRef.current)

        if (cutoffTime.isBefore(moment())) {
          datadogLogs.logger.info(
            '[Audio Capture] Auto-stopping long running session',
            logDataRef.current
          )
          await stopRecording()
        }
      }
    }, 1000)

    return () => clearInterval(interval)
  }, [isRecordingRef.current, recordingCutoffTimeRef.current])

  useEffect(() => {
    if (uploadQueue.length > 0 && !isUploading) {
      uploadChunk(uploadQueue[0])
    }
  }, [uploadQueue, isUploading])

  useEffect(() => {
    const refresh = async () => {
      try {
        await refreshMediaRecorder()
      } catch (error) {
        datadogLogs.logger.error(
          '[Audio Capture] Error refreshing media recorder',
          {
            ...logDataRef.current,
            error
          }
        )
      }
    }

    if (selectedDevice && isRecording) {
      refresh()
    }
  }, [selectedDevice, isRecording, disableEchoCancelation])

  useEffect(() => {
    if (selectedDevice && isTesting) {
      const initStream = async () => {
        try {
          await createAudioStream()
        } catch (error) {
          datadogLogs.logger.error(
            '[Audio Capture] Error creating audio stream',
            {
              ...logDataRef.current,
              error
            }
          )
        }
      }
      initStream()
    }
  }, [selectedDevice, isTesting])

  useEffect(() => {
    if (audioStreamRef.current) {
      audioStreamRef.current
        .getAudioTracks()
        .forEach(t => (t.enabled = !isMuted))
    }
  }, [isMuted])

  useEffect(() => {
    // after starting recording, check for audio after 15 seconds
    const interval = setInterval(() => {
      if (isRecording && !audioLastDetectedAt && !isMuted) {
        setShowMicWarning(true)
      }
    }, 15000)

    return () => clearInterval(interval)
  }, [audioLastDetectedAt, isRecording, isMuted])

  useEffect(() => {
    // after the first audio check, continue to check every 60 seconds
    const interval = setInterval(() => {
      if (
        isRecording &&
        !isMuted &&
        audioLastDetectedAt &&
        Date.now() - audioLastDetectedAt > 60
      ) {
        setShowMicWarning(true)
      }
    }, 60000)

    return () => clearInterval(interval)
  }, [audioLastDetectedAt, isRecording, isMuted])

  const getInputDevices = async () => {
    datadogLogs.logger.info(
      '[Audio Capture] Getting input devices',
      logDataRef.current
    )
    const devices = await navigator.mediaDevices.enumerateDevices()
    const microphoneDevices = devices.filter(
      device => device.kind === 'audioinput' && !!device.deviceId
    )

    setDevices(microphoneDevices)
    return microphoneDevices
  }

  const handlePromptForDevicePermissions = async () => {
    try {
      datadogLogs.logger.info('[Audio Capture] Prompting for permissions')
      setPermissionStatus('requested')
      await createAudioStream()
      await getInputDevices()
      setPermissionStatus('granted')
      setIsTesting(true)
    } catch (err) {
      datadogLogs.logger.info(
        '[Audio Capture] Prompting for permissions error',
        { err, ...logDataRef.current }
      )
      setPermissionStatus('denied')
      setHasMicAccess(false)
    }
  }

  const testAudioInputs = useCallback(async () => {
    if (permissionStatus === 'granted') {
      setIsTesting(true)
    }
  }, [permissionStatus])

  const createAudioStream = async () => {
    let constraints: MediaStreamConstraints = {
      audio: {
        echoCancellation: !disableEchoCancelation,
        autoGainControl: !disableEchoCancelation
      }
    }

    if (selectedDevice) {
      constraints = {
        audio: {
          deviceId: { exact: selectedDevice.deviceId },
          echoCancellation: !disableEchoCancelation,
          autoGainControl: !disableEchoCancelation
        }
      }
    }

    datadogLogs.logger.info('[Audio Capture] Creating audio stream', {
      constraints,
      ...logDataRef.current
    })

    const stream = await navigator.mediaDevices.getUserMedia(constraints)
    streamsRef.current = [...streamsRef.current, stream]

    const audioContext = new (window.AudioContext ||
      // @ts-ignore
      window.webkitAudioContext)()
    audioContextRef.current = audioContext

    const analyzer = audioContext.createAnalyser()
    const micSource = audioContext.createMediaStreamSource(stream)
    const dest = audioContext.createMediaStreamDestination()

    const gainNode = audioContext.createGain()

    micSource.connect(gainNode)
    micSource.connect(analyzer)
    micSource.connect(dest)

    if (tabStreamRef.current) {
      datadogLogs.logger.info('[Audio Capture] Attempting to mix streams', {
        ...logDataRef.current
      })
      const tabSource = audioContext.createMediaStreamSource(
        tabStreamRef.current
      )
      tabStreamRef.current.getAudioTracks().forEach(track => {
        datadogLogs.logger.info('[Audio Capture] Adding track to main stream', {
          ...logDataRef.current
        })
        stream.addTrack(track)
      })
      tabSource.connect(gainNode)
      tabSource.connect(analyzer)
      tabSource.connect(dest)
    }

    const array = new Uint8Array(analyzer.fftSize)

    let lastDetected = Date.now()

    function getPeakLevel() {
      analyzer.getByteTimeDomainData(array)
      return (
        array.reduce(
          (max, current) => Math.max(max, Math.abs(current - 127)),
          0
        ) / 128
      )
    }

    function tick() {
      if (isRecordingRef.current || isTestingRef.current) {
        const peak = getPeakLevel()
        if (peak && peak > maxVolumeRef.current) {
          maxVolumeRef.current = peak
        }
        if (peak && peak > 0.01) {
          if (disableEchoCancelation) {
            volumeActions.setAudioLevel(Math.min(peak * 10, 0.99))
          } else {
            volumeActions.setAudioLevel(Math.min(peak, 0.99))
          }
          setHasDetectedAudio(true)
          if (Math.abs(Date.now() - lastDetected) >= 5000) {
            if (isRecordingRef.current) {
              setAudioLastDetectedAt(Date.now())
              setShowMicWarning(false)
            }
            lastDetected = Date.now()
          }
        } else {
          volumeActions.setAudioLevel(0)
        }
        requestAnimationFrame(tick)
      }
    }
    tick()
    audioStreamRef.current = dest.stream
    return dest.stream
  }

  const stopAudioStream = () => {
    streamsRef.current.forEach(s => s.getTracks().forEach(t => t.stop()))
    volumeActions.setAudioLevel(0)
  }

  const stopAudioShare = () => {
    if (tabStreamRef.current) {
      tabStreamRef.current.getTracks().forEach(t => t.stop())
    }
  }

  const stopRecording = async () => {
    if (isRecordingRef.current) {
      datadogLogs.logger.info('[Audio Capture] Stop recording', {
        ...logDataRef.current
      })
      setIsRecording(false)
      isRecordingRef.current = false

      if (
        mediaRecorderRef.current &&
        mediaRecorderRef.current.state === 'recording'
      ) {
        mediaRecorderRef.current.requestData()
        mediaRecorderRef.current?.stop()
        setIsLastChunkSet(true)
        isLastChunkSetRef.current = true
        stopAudioStream()
        stopAudioShare()
        setIsTesting(false)
      }

      await new Promise(resolve => {
        // wait for the upload to complete so the UI can respond
        const interval = setInterval(() => {
          if (isUploadCompleteRef.current) {
            clearInterval(interval)
            resolve(true)
          }
        }, 100)
      })
    } else {
      setIsTesting(false)
      stopAudioStream()
    }
  }

  useEffect(() => {
    if (
      !isRecording &&
      !isTesting &&
      isLastChunkSet &&
      uploadQueue.length === 0 &&
      !isUploadComplete &&
      !isUploading
    ) {
      datadogLogs.logger.info('[Audio Capture] Stuck Audio upload', {
        ...logDataRef.current
      })
    }
  }, [
    isRecording,
    uploadQueue,
    isUploadComplete,
    isTesting,
    isLastChunkSet,
    isUploading
  ])

  useEffect(() => {
    if (uploadQueue.length === 0 && isLastChunkSetRef.current) {
      isUploadCompleteRef.current = true
      setIsUploadComplete(true)
    }
  }, [uploadQueue])

  const getPresignedUrlV2 = async ({
    sessionId,
    timestamp
  }: {
    sessionId: string
    timestamp: number
  }) => {
    if (uploadUrl) {
      const response: any = await fetch(uploadUrl, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${uploadToken}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          sessionId,
          timestamp
        })
      })

      if (response.ok) {
        const data = await response.json()
        datadogLogs.logger.debug('[Audio Capture] Fetched v2 presigned URL', {
          ...logDataRef.current,
          presignedUrl: data.presignedUrl,
          payload: {
            timestamp
          }
        })
        return data.presignedUrl
      } else {
        const errorData = await response.json()
        datadogLogs.logger.error(
          '[Audio Capture] Failed to fetch v2 presigned URL',
          {
            ...logDataRef.current,
            payload: {
              timestamp
            },
            errorData: errorData
          }
        )
        throw new Error(errorData.message)
      }
    }
  }

  const uploadChunk = async (
    payload: UploadPayload,
    retryCount: number = 0
  ) => {
    const baseDelay = 1000

    setIsUploading(true)

    const { timestamp, chunks } = payload
    const blob = new Blob(chunks)

    try {
      if (chunks.length) {
        datadogLogs.logger.debug('[Audio Capture] Fetching presigned URL', {
          ...logDataRef.current,
          payload: {
            timestamp
          }
        })

        let presignedUrl

        if (uploadUrl) {
          presignedUrl = await getPresignedUrlV2({
            sessionId: sessionId!,
            timestamp
          })
        } else {
          const data = await getPresignedUrl({
            sessionId,
            timestamp
          })
  
          // @ts-ignore
          presignedUrl = data.url
        }

        datadogLogs.logger.debug('[Audio Capture] Uploading chunk', {
          ...logDataRef.current,
          presignedUrl,
          payload: {
            timestamp
          }
        })

        await fetch(presignedUrl, {
          method: 'PUT',
          headers: {
            'Content-Type': 'audio/ogg'
          },
          body: blob
        })
      }
      setUploadCount(prevCount => prevCount + 1)

      setUploadQueue(prevQueue => prevQueue.slice(1))

      setTimeout(() => {
        setIsUploading(false) // add a buffer so server doesn't get slammed if client goes offline and queue needs to drain
      }, 500)
    } catch (error) {
      const delay = baseDelay * Math.min(Math.pow(2, retryCount), 20)
      datadogLogs.logger.error(
        `[Audio Capture] Upload failed. Retry attempt ${retryCount +
          1}. Retrying in ${delay} milliseconds.`,
        {
          ...logDataRef.current,
          uploadQueue: uploadQueueRef.current.length
        },
        error as Error
      )
      setTimeout(() => {
        uploadChunk(payload, retryCount + 1)
      }, delay)
    }
  }

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null
    if (isRecording && selectedDevice) {
      if (interval) {
        clearInterval(interval)
      }
      interval = setInterval(() => {
        if (
          mediaRecorderRef.current &&
          mediaRecorderRef.current.state === 'recording'
        ) {
          mediaRecorderRef.current.stop()
          if (isRecordingRef.current) {
            startMediaRecorder()
          }
        }
      }, 5000)
    } else {
      if (interval) {
        clearInterval(interval)
      }
    }

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [isRecording, selectedDevice])

  const createMediaRecorder = async (audioStream: MediaStream) => {
    datadogLogs.logger.info('[Audio Capture] Create media recorder', {
      ...logDataRef.current
    })

    let audioChunks: BlobPart[] = []

    const mediaRecorder = new MediaRecorder(audioStream)
    mediaRecordersRef.current.push(mediaRecorder)

    mediaRecorderRef.current = mediaRecorder

    mediaRecorder.addEventListener('dataavailable', async function(event) {
      if (event.data.size > 0) {
        audioChunks.push(event.data)
      }
    })

    mediaRecorder.addEventListener('stop', async () => {
      const chunks = audioChunks.splice(0, audioChunks.length)
      const payload = {
        timestamp: Date.now(),
        chunks
      }
      datadogLogs.logger.debug('[Audio Capture] Add chunk to queue', {
        ...logDataRef.current,
        activeMediaRecorders: mediaRecordersRef.current.filter(
          mr => mr.state === 'recording'
        ).length
      })

      setUploadQueue(prevQueue => [...prevQueue, payload])
    })

    return mediaRecorder
  }

  const refreshMediaRecorder = async () => {
    datadogLogs.logger.info('[Audio Capture] Refresh media recorder', {
      ...logDataRef.current
    })
    mediaRecordersRef.current.forEach(mr => {
      mr.stop()
      mr.ondataavailable = null
      mr.onstop = null
    })
    stopAudioStream()
    const stream = await createAudioStream()
    await createMediaRecorder(stream)
    startMediaRecorder()
  }

  const handleInputChange = async (deviceId: string) => {
    datadogLogs.logger.info('[Audio Capture] Changing inputs', {
      ...logDataRef.current,
      deviceId
    })
    const device = devices.find(d => d.deviceId === deviceId)
    if (device) {
      setSelectedDevice(device)
      setSelectedDeviceLabel(device.label)
      localStorage.setItem('microphoneDeviceId', deviceId)
      if (audioContextRef.current) {
        try {
          await audioContextRef.current.close()
        } catch (err) {
          datadogLogs.logger.warn(
            '[Audio Capture] Unable to close audio context',
            logDataRef.current,
            err as Error
          )
        }
      }
      setAudioLastDetectedAt(null)
    }
    maxVolumeRef.current = 0
  }

  const startMediaRecorder = () => {
    if (mediaRecorderRef.current) {
      if (mediaRecorderRef.current.state !== 'recording') {
        mediaRecorderRef.current.start()
      }
    }
  }

  const toggleMute = () => {
    setIsMuted(!isMuted)
  }

  const startRecording = async ({
    isUsingHeadphones,
    isTelehealth
  }: {
    isUsingHeadphones: boolean
    isTelehealth: boolean
  }) => {
    if (isRecording) return

    datadogLogs.logger.info('[Audio Capture] Start recording', {
      ...logDataRef.current
    })

    if (!selectedDevice) {
      datadogLogs.logger.info(
        '[Audio Capture] Start recording - Prompting for permissions',
        { ...logDataRef.current }
      )
      // safari requires access on each page load
      await handlePromptForDevicePermissions()
    }

    await updateSession({
      sessionId,
      isTelehealth,
      isUsingHeadphones,
      useV2PresignedUrl: isPresignedUrlV2Enabled
    })

    await handleMarkOnboardingComplete()

    try {
      const values = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY) || '{}')
      values[sessionId!] = localRecordingId
      localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(values))
    } catch (err) {
      datadogLogs.logger.info('[Audio Capture] Unable to check localStorage', {
        ...logDataRef.current,
        error: err
      })
    }

    isRecordingRef.current = true
    setIsRecording(true)
    setIsTesting(false)
  }

  const startContentShare = async () => {
    try {
      datadogLogs.logger.info(
        '[Audio Share] Attempting audio share',
        logDataRef.current
      )
      const mediaStream = await navigator.mediaDevices.getDisplayMedia({
        audio: true,
        video: true,
        // @ts-ignore
        selfBrowserSurface: ['include']
      })
      mediaStream.getTracks().forEach(track => {
        track.onended = () => {
          tabStreamRef.current = null
          refreshMediaRecorder()
        }
      })

      tabStreamRef.current = mediaStream // do not set on state to make sure we don't end it

      if (isRecordingRef.current) {
        await refreshMediaRecorder()
      }
    } catch (error) {
      datadogLogs.logger.warn(
        '[Audio Share] Error sharing audio',
        {
          ...logDataRef.current
        },
        error as Error
      )
    }
  }

  const handleSetIsStopRecordingAfterEnabled = (enabled: boolean) => {
    if (enabled) {
      datadogLogs.logger.info(
        '[Audio Capture] Enable auto-stop recording',
        logDataRef.current
      )
    } else {
      datadogLogs.logger.info(
        '[Audio Capture] Disable auto-stop recording',
        logDataRef.current
      )
    }
    setIsStopRecordingAfterEnabled(enabled)
  }

  const contextValue = {
    isReady: true,
    audioInputs: devices,
    selectedAudioInput: selectedDevice?.deviceId || '',
    setSelectedAudioInput: handleInputChange,
    testAudioInputs,
    hasMicAccess,
    setHasMicAccess,
    promptForDevicePermissions: handlePromptForDevicePermissions,
    isMuted,
    toggleMute,
    disableEchoCancelation: setDisableEchoCancelation,
    isRecording,
    isRecordingLoading: isUpdateSessionLoading,
    startRecording,
    stopRecording,
    startContentShare,
    stopContentShare: () => {},
    logData: logDataRef.current,
    hasDetectedAudio,
    showMicWarning,
    setShowMicWarning,
    isUsingHeadphones,
    setIsUsingHeadphones,
    isTelehealth,
    setIsTelehealth,
    isUploadComplete,
    setRecordingCutoffTime: (timestamp: string) =>
      (recordingCutoffTimeRef.current = timestamp),
    selectedAudioInputLabel,
    isSettingsOpen,
    setIsSettingsOpen,
    stopRecordingAfter,
    setStopRecordingAfter,
    isStopRecordingAfterEnabled,
    setIsStopRecordingAfterEnabled: handleSetIsStopRecordingAfterEnabled,
    hasResolvedInputDevicePermissions,
    permissionStatus,
    uploadQueueSize: uploadQueue.length
  }

  return (
    <AudioCaptureContext.Provider value={contextValue}>
      {children}
    </AudioCaptureContext.Provider>
  )
}

export default ManualAudioCaptureProviderV2
