import React, { useState } from 'react'
import { NavBar } from './nav-bar'
import { SelectClient } from './select-client'
import { Wrapper, PreviousSessionsMenu } from '../components'
import { useExperienceManager } from '@hooks'
import { AudioCaptureProvider } from '@context'
import { TherapyNotesMessage } from './therapy-notes-message'

export const ExtensionStartSession = () => {
  const { isExtensionMinimized } = useExperienceManager()
  const [sessionId, setSessionId] = useState<string | undefined>()
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const isTherapyNotes = document.referrer.includes('therapynotes.com')

  const toggleMenu = () =>
    setIsMenuOpen((setIsMenuOpen: boolean) => !setIsMenuOpen)

  return (
    <Wrapper>
      <NavBar showMenuButton onMenuButtonClicked={toggleMenu} />
      {isTherapyNotes ? (
        <TherapyNotesMessage />
      ) : (
        <AudioCaptureProvider sessionId={sessionId}>
          {!isExtensionMinimized && (
            <SelectClient sessionId={sessionId} setSessionId={setSessionId} />
          )}
        </AudioCaptureProvider>
      )}
      <PreviousSessionsMenu isOpen={isMenuOpen} onClose={toggleMenu} />
    </Wrapper>
  )
}
