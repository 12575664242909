import React from 'react'
import { OrgClinicSelect } from './org-clinic-select'
import { SuperAdminClinicSearch } from './super-admin-clinic-search'
import { usePermissions } from '../../hooks'
import { Clinic } from './models'
import { User } from 'src/store/types'

export const ClinicSelector = ({
  onClinicSelected,
  selectedClinic,
  canViewOrgDropdown,
  user
}: {
  onClinicSelected: (selectedClinic: Clinic) => void
  selectedClinic: Clinic
  canViewOrgDropdown: boolean
  user: User
}) => {
  const { hasPermission } = usePermissions()
  const isSuperAdmin = hasPermission('*:any:*:*')

  if (!isSuperAdmin && !canViewOrgDropdown) return null

  return isSuperAdmin ? (
    <SuperAdminClinicSearch
      onClinicSelected={onClinicSelected}
      selectedClinicName={selectedClinic.name}
    />
  ) : (
    <OrgClinicSelect
      organizationId={user.clinic.organization_id}
      onClinicSelected={onClinicSelected}
      selectedClinicId={selectedClinic.id}
    />
  )
}
