import React, { useCallback, useRef, useEffect, useMemo } from 'react'
import { Box, useToast, HStack, VStack, Flex } from '@chakra-ui/react'
import { Button } from '@blueprinthq/joy'
import { BlueForm } from '@blueprinthq/joy-business'
import { useQueryClient } from 'react-query'
import {
  useInterventionsControllerV1GetInterventionActivity,
  usePatientInterventionsControllerGetPatientInterventionById,
  usePatientInterventionsControllerCreatePatientIntervention,
  usePatientInterventionsControllerUpdatePatientIntervention,
  usePatientInterventionsControllerDeletePatientIntervention,
  getPatientInterventionsControllerGetAllPatientInterventionsQueryKey,
  getPatientInterventionsControllerGetPatientInterventionByIdQueryKey
} from '~/clinician-api'

import { useAssistPanelContext } from '../../../in-session-assist-panel/context'
import { Loading } from '@components'

import { useTreatmentPlan, useExperienceManager } from '@hooks'
interface InterventionActivityProps {
  isReadOnly: boolean
  interventionId: string
  patientInterventionId: string
  contentSuggestionId?: string | null
}
export function InterventionActivity({
  isReadOnly,
  interventionId,
  patientInterventionId,
  contentSuggestionId
}: InterventionActivityProps) {
  const { client } = useTreatmentPlan()
  const { setOverlayPanel } = useAssistPanelContext()
  const { isSessionRecordingV2Enabled } = useExperienceManager()

  const queryClient = useQueryClient()
  const toast = useToast()

  let formikRef = useRef(null)

  const {
    data: interventionItems,
    isLoading
  } = useInterventionsControllerV1GetInterventionActivity(interventionId)

  const {
    data: clientIntervention,
    isLoading: clientInterventionLoading
  } = usePatientInterventionsControllerGetPatientInterventionById(
    client?.id ?? '',
    patientInterventionId,
    {
      query: {
        enabled: !!patientInterventionId
      }
    }
  )

  const {
    mutateAsync: createClientIntervention
  } = usePatientInterventionsControllerCreatePatientIntervention()
  const {
    mutateAsync: updateClientIntervention
  } = usePatientInterventionsControllerUpdatePatientIntervention()
  const {
    mutateAsync: deleteClientIntervention
  } = usePatientInterventionsControllerDeletePatientIntervention()

  const handleDiscard = useCallback(async () => {
    deleteClientIntervention(
      { patientId: client?.id ?? '', patientInterventionId },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(
            getPatientInterventionsControllerGetAllPatientInterventionsQueryKey(
              client?.id ?? ''
            )
          )
          queryClient.invalidateQueries(
            getPatientInterventionsControllerGetPatientInterventionByIdQueryKey(
              client?.id ?? '',
              patientInterventionId
            )
          )
          setOverlayPanel(null)
        },
        onError: () => {
          toast({
            title: 'Error',
            description: 'There was an error discarding this activity',
            status: 'error',
            isClosable: true,
            duration: 2000
          })
        }
      }
    )
  }, [patientInterventionId])

  const turnOffUnloadAlert = () => {
    window.onbeforeunload = () => null
  }

  const turnOnUnloadAlert = () => {
    window.onbeforeunload = e => {
      e.preventDefault()
      return e.returnValue
    }
  }

  useEffect(() => {
    if (clientIntervention && clientIntervention.isComplete)
      turnOffUnloadAlert()
    else turnOnUnloadAlert()
  }, [clientIntervention])

  const handleSave = useCallback(
    async (isComplete: boolean) => {
      // @ts-ignore
      const formValues = formikRef?.current?.values
      if (!formValues) return

      const formattedResponses = Object.entries(formValues || {}).map(
        ([interventionItemId, value]) => ({
          interventionItemId,
          response: { value }
        })
      )

      const body = {
        isComplete,
        responses: formattedResponses
      }

      const mutationOptions = {
        onError: () => {
          toast({
            title: 'Error',
            description: 'There was an error saving this activity',
            status: 'error',
            isClosable: true,
            duration: 2000
          })
        },
        onSuccess: async () => {
          queryClient.invalidateQueries(
            getPatientInterventionsControllerGetAllPatientInterventionsQueryKey(
              client?.id ?? ''
            )
          )
          queryClient.invalidateQueries(
            getPatientInterventionsControllerGetPatientInterventionByIdQueryKey(
              client?.id ?? '',
              patientInterventionId
            )
          )
          turnOnUnloadAlert()
          toast({
            title: 'Success',
            description: isComplete
              ? 'Intervention completed'
              : 'Intervention saved as draft',
            status: 'success',
            isClosable: true,
            duration: 2000
          })

          setOverlayPanel(null)
        }
      }

      if (patientInterventionId) {
        await updateClientIntervention(
          {
            patientId: client?.id ?? '',
            patientInterventionId,
            // @ts-ignore
            data: body
          },
          mutationOptions
        )
      } else {
        await createClientIntervention(
          {
            patientId: client?.id ?? '',
            // @ts-ignore
            data: { ...body, interventionId, contentSuggestionId }
          },
          mutationOptions
        )
      }
    },
    [formikRef.current, patientInterventionId]
  )

  const initialValues = useMemo(() => {
    if (!interventionItems || !clientIntervention) {
      return {}
    }
    // @ts-ignore
    const interventionQuestionItems = interventionItems?.filter(
      (ii: any) => ii.type === 'question'
    )

    return interventionQuestionItems.reduce((acc: any, ii: any) => {
      acc[ii.id] = clientIntervention?.responses[ii.id] || null
      return acc
    }, {})
  }, [clientIntervention, interventionItems])

  const renderContent = useCallback(() => {
    if (isLoading || clientInterventionLoading) {
      return <Loading />
    }

    return (
      <BlueForm
        title={''}
        form={interventionItems}
        isDisabled={clientIntervention?.isComplete || isReadOnly}
        initialValues={initialValues}
        innerRef={formikRef}
        enableReinitialize={true}
      />
    )
  }, [
    isLoading,
    interventionItems,
    clientInterventionLoading,
    clientIntervention,
    isReadOnly,
    initialValues
  ])

  return (
    <VStack h={isSessionRecordingV2Enabled ? "calc(100vh - 115px)" : "calc(100vh - 173px)"}>
      <Box 
        px="24px" 
        h="100%" 
        maxH={{
          base: 'calc(100vh - 180px)',
          md: isSessionRecordingV2Enabled ? "calc(100vh - 180px)" : "calc(100vh - 238px)"
        }}
        pb={{ base: '96px', md: '24px' }}
        overflowY="auto"
      >
        {renderContent()}
      </Box>

      {/* Footer */}
      {!isReadOnly && (
        <Flex
          position={{ base: 'fixed', md: 'static' }}
          bottom={{ base: 0, md: 'auto' }}
          left={{ base: 0, md: 'auto' }}
          right={{ base: 0, md: 'auto' }}
          w="100%"
          px="24px"
          py={{ base: '4px', md: '18px' }}
          align="center"
          justify="space-between"
          borderTop="1px solid"
          borderColor="gray.200"
          bg="white"
          flexDir={{ base: 'column', md: 'row' }}
        >
          <Box w={{ base: '100%', md: 'auto' }}>
            {patientInterventionId && (
              <Button
                id={'pendo-intervention-cancel-tag'}
                onClick={handleDiscard}
                variant={'ghost'}
                textColor={'#C6162C'}
                _focus={{
                  boxShadow: 'none'
                }}
                p={0}
                w={{ base: '100%', md: 'auto' }}
              >
                Discard
              </Button>
            )}
          </Box>
          <HStack 
            spacing={'8px'} 
            w={{ base: '100%', md: 'auto' }}
            justify="flex-end"
          >
            <Button
              id={'pendo-intervention-save-draft-tag'}
              onClick={() => handleSave(false)}
              variant={'outline'}
              _focus={{
                boxShadow: 'none'
              }}
              fontSize={{ base: 'sm', md: 'md' }}
              px={{ base: '12px', md: '16px' }}
            >
              Save & Continue Later
            </Button>
            <Button
              id={'pendo-intervention-submit-tag'}
              onClick={() => handleSave(true)}
              textColor={'white'}
              background={'primary'}
              _focus={{
                boxShadow: 'none'
              }}
              fontSize={{ base: 'sm', md: 'md' }}
              px={{ base: '12px', md: '16px' }}
            >
              Complete Intervention
            </Button>
          </HStack>
        </Flex>
      )}
    </VStack>
  )
}
