import React from 'react'
import { Text, Box, Button, Flex, Link } from '@chakra-ui/react'
import { EmailFilled } from '@components/icons'
import { useStoreState } from 'easy-peasy'

const emailBody = `
Dear TherapyNotes Support Team,
%0D%0A
%0D%0A
I hope this message finds you well.
%0D%0A
%0D%0A
I am a dedicated user of TherapyNotes for managing my clinical documentation and practice operations. I recently started using Blueprint, a platform that significantly enhances mental health care through outcome tracking and patient engagement tools.
%0D%0A
%0D%0A
However, I've encountered a limitation: TherapyNotes currently restricts microphone access for third-party extensions like Blueprint. This restriction prevents me from fully utilizing Blueprint's voice-enabled features, which are designed to streamline documentation and improve the efficiency of my workflow.
%0D%0A
%0D%0A
I truly value the security measures TherapyNotes implements to protect sensitive data. I believe that collaborating with Blueprint to securely enable microphone access could offer clinicians like myself a more seamless and productive experience.
%0D%0A
%0D%0A
Please consider reviewing this integration opportunity. I would appreciate any updates or guidance on how this functionality might be supported in the future.
%0D%0A
%0D%0A
Thank you for your time and consideration.
%0D%0A
%0D%0A
Best regards,
[Your Full Name]
%0D%0A
[Your Credentials]
%0D%0A
[Your Practice Name]
%0D%0A
[Your Contact Information]
`

export const TherapyNotesMessage = () => {
  const { user } = useStoreState((state: any) => state.auth)

  const handleEmailClick = () => {
    const mailtoLink = `mailto:support@therapynotes.com?subject=Request for Microphone Access Support for Blueprint Integration&body=${emailBody}`
    const a = document.createElement('a')
    a.href = mailtoLink
    a.target = '_blank'
    a.click()
  }

  return (
    <>
      <Box bg="white" p="4" w="100%" id="bp-extension-loaded" mb="60px">
        <Box
          bg="#2d54e81a"
          w="100%"
          borderRadius="8px"
          border="1px solid #E2E8F0"
          p="4"
        >
          <Text mb="4">{`Hi ${user.first_name},`}</Text>
          <Text mb="4">
            TherapyNotes has implemented a security measure that prevents any
            outside tool—including Blueprint—from requesting microphone access
            on their platform. While this protects user security, it also blocks
            Blueprint's voice-enabled features that could help streamline your
            workflow.
          </Text>
          <Text mb="4">
            The good news is that TherapyNotes can make an exception and grant
            explicit permission for Blueprint to run on their site. However,
            this change requires some effort on their part, and your feedback
            can help make it happen.
          </Text>
          <Text mb="4">
            📧 Help Us Move Forward: Please email TherapyNotes support at{' '}
            <Link color="primary" onClick={handleEmailClick}>
              support@therapynotes.com
            </Link>{' '}
            and ask them to allow secure microphone access for Blueprint.
          </Text>
          <Text>
            Thank you for supporting this improvement to your workflow!
          </Text>
        </Box>
      </Box>
      <Box position="fixed" bottom="0" left="0" right="0">
        <Flex
          h="60px"
          bg="white"
          w="100%"
          align="center"
          justify="center"
          borderTop="1px solid #E2E8F0"
        >
          <Button
            borderRadius="4px"
            h="32px"
            leftIcon={<EmailFilled />}
            onClick={handleEmailClick}
          >
            Email Therapy Notes
          </Button>
        </Flex>
      </Box>
    </>
  )
}
