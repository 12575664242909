import { VStack, Text, Button } from '@chakra-ui/react'
import React from 'react'
import { AlertBox } from '@components'

export const MicrophoneBlockedWarning = () => {
  return (
    <VStack
      justifyContent="center"
      minWidth="320px"
      maxWidth="420px"
      gap="13px"
      alignItems="center"
      textAlign="center"
    >
      <Text fontSize="32px" color="#C6162C">
        Microphone access is currently blocked
      </Text>
      <Button
        bg="primary"
        fontWeight="430"
        disabled={true}
        height="56px"
        width="100%"
        color="white"
        variant="body"
        _hover={{ bg: 'primary' }}
      >
        Start Recording
      </Button>
      <AlertBox
        sx={{
          a: {
            color: 'primary'
          }
        }}
      >
        <Text mb="4px" textAlign="left">
          You will need to allow access in your browser and possibly reload the
          page before we can start recording
        </Text>
      </AlertBox>
    </VStack>
  )
}
