import React, { Fragment, useEffect, useState } from 'react'
import { endpoints } from '@api'
import { Select } from '@chakra-ui/react'
import { useQuery } from 'react-query'

export interface Clinician {
  id: string
  first_name: string
  last_name: string
}

interface CliniciansResponse {
  clinicians: Clinician[]
}

export const ClinicianSelect = ({
  onClinicianSelected,
  onCliniciansLoaded,
  selectedClinicianId,
  clinicDisplayId,
  allowAllCliniciansOption,
  hideIfOnlyOneClinician
}: {
  onClinicianSelected: (selectedClinician: string) => void
  onCliniciansLoaded: (clinicians: Clinician[]) => void
  selectedClinicianId: string | null
  clinicDisplayId: string
  allowAllCliniciansOption: boolean
  hideIfOnlyOneClinician: boolean
}) => {
  const [clinicians, setClinicians] = useState<Clinician[]>([])

  useEffect(() => {
    onCliniciansLoaded(clinicians)
  }, [clinicians])

  const clinicianQuery = {
    clinicDisplayId: encodeURIComponent(clinicDisplayId),
    showVisibleOnly: true
  }

  const { isLoading: cliniciansLoading } = useQuery(
    [endpoints.getClinicClinicians.getCacheId(), clinicianQuery],
    () => endpoints.getClinicClinicians.request(clinicianQuery),
    {
      initialData: { clinicians: [] },
      enabled: !!clinicDisplayId && clinicDisplayId !== '',
      onSuccess: (data: CliniciansResponse) => {
        setClinicians(data.clinicians)
      }
    }
  )
  const hideClinicSelect = clinicians.length <= 1 && hideIfOnlyOneClinician

  return (
    <Fragment>
      {!cliniciansLoading && !hideClinicSelect && (
        <Select
          value={selectedClinicianId ?? ''}
          onChange={e => {
            onClinicianSelected(e.target.value)
          }}
          borderColor="gray.300"
        >
          {allowAllCliniciansOption && (clinicians.length > 0) && (
            <option key="" value="">
              All Clinicians
            </option>
          )}
          {clinicians.map(clinician => (
            <option key={clinician.id} value={clinician.id}>
              {clinician.first_name} {clinician.last_name}
            </option>
          ))}
        </Select>
      )}
    </Fragment>
  )
}
