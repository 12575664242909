import React from 'react'
import { Box, HStack, Image, Link, Stack, useBreakpointValue } from '@chakra-ui/react'
import UnionSVG from '../../images/brand/union.svg'
import { HeaderV2 } from './components'
import { Wrapper, NavBar } from '../integration-ui/components'
import { useAudioCapture, useExperienceManager } from '@hooks'
import BlueprintLogo from '../../images/brand/blue_logo.svg'
import { ArrowDownIcon } from '@blueprinthq/joy'
import { ClientAccountResponse } from '@api'

type Props = {
  isExtensionMinimized: boolean
  hasStartedRecording: boolean
  isDictation: boolean
  client: ClientAccountResponse | undefined
  children: any
}

export const SessionWrapper = ({
  isExtensionMinimized,
  hasStartedRecording,
  isDictation,
  client,
  children
}: Props) => {
  const { isIntegratedUI, isSessionRecordingV2Enabled } = useExperienceManager()

  const { isMuted } = useAudioCapture()

  const isDesktop = useBreakpointValue({ base: false, md: true })

  let pageName = isDictation ? 'Dictate Audio' : 'Record Audio'
  if (client) {
    pageName = `${client.first_name} ${client.last_name}`
  }

  if (isIntegratedUI) {
    return (
      <Wrapper>
        <NavBar hasStartedRecording={hasStartedRecording} />
        {!isExtensionMinimized && children}
      </Wrapper>
    )
  }

  if (!isSessionRecordingV2Enabled) {
    return (
      <Stack
        bg="primary"
        backgroundRepeat="no-repeat"
        backgroundImage={UnionSVG}
        backgroundPosition="left top"
        height={{ base: '100%', md: '100vh' }}
      >
        <HeaderV2 />
        {children}
      </Stack>
    )
  }

  return (
    <Stack
      backgroundColor={isMuted ? '#E4E5E6' : '#F6F7FF'}
      height="100dvh"
      transition="background-color 0.5s"
    >
      <Box position="relative" style={{ margin: '0 16px' }}>
        <HStack
          style={{ margin: 0 }}
          justifyContent={{ base: 'center', md: 'space-between' }}
          alignItems="center"
          h={{
            base: '80px',
            sm: '80px',
            md: '16px'
          }}
          my="medium"
        >
          <HStack spacing={2} alignItems="center" position="absolute" top="25px">
            <Link
              href="/sessions"
            >
              <Image
                loading="eager"
                src={BlueprintLogo}
                paddingLeft="16px"
                cursor="pointer"
              />
            </Link>
            {isDesktop && (
              <>
                <Box as="span" color="#2D54E899" fontSize="16px">
                  <ArrowDownIcon
                    fill="#2D54E899"
                    style={{
                      transform: 'rotate(270deg)',
                      transition: 'transform 0.2s ease'
                    }}
                  />
                </Box>
                <Box as="span" color="#2D54E8CC" fontSize="16px">
                  {pageName}
                </Box>
              </>
            )}
          </HStack>
        </HStack>
      </Box>
      {children}
    </Stack>
  )
}
