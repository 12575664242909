import { endpoints } from '@api'
import { useToast } from '@chakra-ui/react'
import { Loading } from '@components'
import { AudioCaptureProvider, PanelManagerProvider } from '@context'
import { useComponentRenderTracker, useExperienceManager } from '@hooks'
import {
  MeetingManager, MeetingProvider
} from 'amazon-chime-sdk-component-library-react'
import { ConsoleLogger, LogLevel } from 'amazon-chime-sdk-js'
import React, { useEffect, useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { useHistory, useParams } from 'react-router-dom'
import {
  useSessionControllerGetSessionRecap
} from '~/clinician-api'
import { SessionActivityV2 } from './session-activity-v2'

const logger = new ConsoleLogger('chime', LogLevel.WARN)
const manager = new MeetingManager(logger)

export function SessionActivity({
  isDictation = false,
  flagsmithFlagsLoaded
}: {
  isDictation: boolean
  flagsmithFlagsLoaded: boolean
}) {
  useComponentRenderTracker('Viewed Session Page')

  const {
    sessionId,
    id: clientId
  }: { sessionId: string; id: string } = useParams()

  const history = useHistory()

  const {
    isDocumentationAutomationEnabled,
    isSessionRecordingV2Enabled,
  } = useExperienceManager()

  const {
    data: recap,
    isLoading: isLoadingSessionRecap,
    refetch: refetchRecap
  } = useSessionControllerGetSessionRecap(sessionId, {
    query: {
      enabled: !!sessionId
    }
  })

  const { data: client, isLoading: isClientLoading } = useQuery(
    [endpoints.getClinicianUserAccount.getCacheId(), clientId],
    () => endpoints.getClinicianUserAccount.request({ id: clientId }),
    { enabled: !!clientId }
  )

  const { data: waitlist, isLoading: isWaitlistLoading } = useQuery(
    endpoints.getFeatureWaitlist.getCacheId(),
    endpoints.getFeatureWaitlist.request
  )

  useEffect(() => {
    if (recap && recap.completedAt) {
      history.push(`/patient/${clientId}/completed-session/${sessionId}`)
    }
  }, [recap])

  if (
    !flagsmithFlagsLoaded ||
    (!isSessionRecordingV2Enabled && clientId && isClientLoading) ||
      (sessionId && isLoadingSessionRecap) ||
      isWaitlistLoading
  ) {
    return <Loading />
  }

  const isOnAssistWaitlist = (waitlist as any)?.includes('assist')

  return (
    <MeetingProvider meetingManager={manager}>
      <PanelManagerProvider
        isDictation={isDictation}
        isOnAssistWaitlist={isOnAssistWaitlist}
      >
        <AudioCaptureProvider sessionId={sessionId} refetchRecap={refetchRecap}>
          <SessionActivityV2
            recap={recap}
            refetchRecap={refetchRecap}
            sessionId={sessionId}
            client={client}
            aiNotetakerEnabled={isDocumentationAutomationEnabled}
            isDictation={isDictation}
          />
        </AudioCaptureProvider>
      </PanelManagerProvider>
    </MeetingProvider>
  )
}
