/**
 * Generated by orval v6.8.1 🍺
 * Do not edit manually.
 * Blueprint: Internal API
 * OpenAPI spec version: 1.0
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  CheckInDtoV2,
  CheckInsControllerV1GetCheckInsParams,
  UserDto,
  ApiExceptionDto,
  BirthdayVerificationStatusDto,
  UserControllerV1GetBirthdayVerificationStatusParams,
  UnsubscribeUserResponseDto,
  VerifyBirthdayResponseDto,
  VerifyBirthdayDto,
  AssignedAssessmentDto,
  SubmitAssessmentsBodyDto,
  CheckInScoreBodyV1,
  ConsentInfoResponse,
  SafetyNetItemDto,
  KioskClinicDto,
  KioskPatientDto,
  KioskIdentifyPatientDto,
  KioskIdentifyPatientFromAttributesBodyDto,
  PatientInviteLookupResponseV1,
  PatientInviteLookupBody,
  PatientInviteVerifyResponse,
  PatientInviteVerifyBody,
  PatientInviteSetupAccountResponse,
  PatientInviteSetupAccountBody,
  ForgotPasswordRequestCodeBody,
  ForgotPasswordConfirmBody,
  AssessmentDto,
  AssessmentControllerV1GetAssessmentsParams,
  ClinicianDto,
  CreatePartnerClinicianBody
} from './models'
import { hellojoyApiInstance, ErrorType } from '../../axios-instance'


export const checkInsControllerV1GetCheckIns = (
    checkInId: string,
    params?: CheckInsControllerV1GetCheckInsParams,
 signal?: AbortSignal
) => {
      return hellojoyApiInstance<CheckInDtoV2>(
      {url: `/v1/public-api/check-ins/${checkInId}`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getCheckInsControllerV1GetCheckInsQueryKey = (checkInId: string,
    params?: CheckInsControllerV1GetCheckInsParams,) => [`/v1/public-api/check-ins/${checkInId}`, ...(params ? [params]: [])];

    
export type CheckInsControllerV1GetCheckInsQueryResult = NonNullable<Awaited<ReturnType<typeof checkInsControllerV1GetCheckIns>>>
export type CheckInsControllerV1GetCheckInsQueryError = ErrorType<unknown>

export const useCheckInsControllerV1GetCheckIns = <TData = Awaited<ReturnType<typeof checkInsControllerV1GetCheckIns>>, TError = ErrorType<unknown>>(
 checkInId: string,
    params?: CheckInsControllerV1GetCheckInsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof checkInsControllerV1GetCheckIns>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getCheckInsControllerV1GetCheckInsQueryKey(checkInId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof checkInsControllerV1GetCheckIns>>> = ({ signal }) => checkInsControllerV1GetCheckIns(checkInId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof checkInsControllerV1GetCheckIns>>, TError, TData>(queryKey, queryFn, {enabled: !!(checkInId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


export const userControllerV1GetUser = (
    userId: string,
 signal?: AbortSignal
) => {
      return hellojoyApiInstance<UserDto>(
      {url: `/v1/public-api/user/${userId}`, method: 'get', signal
    },
      );
    }
  

export const getUserControllerV1GetUserQueryKey = (userId: string,) => [`/v1/public-api/user/${userId}`];

    
export type UserControllerV1GetUserQueryResult = NonNullable<Awaited<ReturnType<typeof userControllerV1GetUser>>>
export type UserControllerV1GetUserQueryError = ErrorType<ApiExceptionDto>

export const useUserControllerV1GetUser = <TData = Awaited<ReturnType<typeof userControllerV1GetUser>>, TError = ErrorType<ApiExceptionDto>>(
 userId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof userControllerV1GetUser>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getUserControllerV1GetUserQueryKey(userId);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof userControllerV1GetUser>>> = ({ signal }) => userControllerV1GetUser(userId, signal);

  const query = useQuery<Awaited<ReturnType<typeof userControllerV1GetUser>>, TError, TData>(queryKey, queryFn, {enabled: !!(userId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


export const userControllerV1GetBirthdayVerificationStatus = (
    userId: string,
    params?: UserControllerV1GetBirthdayVerificationStatusParams,
 signal?: AbortSignal
) => {
      return hellojoyApiInstance<BirthdayVerificationStatusDto>(
      {url: `/v1/public-api/user/${userId}/birthday-verification-status`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getUserControllerV1GetBirthdayVerificationStatusQueryKey = (userId: string,
    params?: UserControllerV1GetBirthdayVerificationStatusParams,) => [`/v1/public-api/user/${userId}/birthday-verification-status`, ...(params ? [params]: [])];

    
export type UserControllerV1GetBirthdayVerificationStatusQueryResult = NonNullable<Awaited<ReturnType<typeof userControllerV1GetBirthdayVerificationStatus>>>
export type UserControllerV1GetBirthdayVerificationStatusQueryError = ErrorType<ApiExceptionDto>

export const useUserControllerV1GetBirthdayVerificationStatus = <TData = Awaited<ReturnType<typeof userControllerV1GetBirthdayVerificationStatus>>, TError = ErrorType<ApiExceptionDto>>(
 userId: string,
    params?: UserControllerV1GetBirthdayVerificationStatusParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof userControllerV1GetBirthdayVerificationStatus>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getUserControllerV1GetBirthdayVerificationStatusQueryKey(userId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof userControllerV1GetBirthdayVerificationStatus>>> = ({ signal }) => userControllerV1GetBirthdayVerificationStatus(userId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof userControllerV1GetBirthdayVerificationStatus>>, TError, TData>(queryKey, queryFn, {enabled: !!(userId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


export const userControllerV1PostUnsubscribeUser = (
    userId: string,
 
) => {
      return hellojoyApiInstance<UnsubscribeUserResponseDto>(
      {url: `/v1/public-api/user/${userId}/unsubscribe`, method: 'post'
    },
      );
    }
  


    export type UserControllerV1PostUnsubscribeUserMutationResult = NonNullable<Awaited<ReturnType<typeof userControllerV1PostUnsubscribeUser>>>
    
    export type UserControllerV1PostUnsubscribeUserMutationError = ErrorType<ApiExceptionDto>

    export const useUserControllerV1PostUnsubscribeUser = <TError = ErrorType<ApiExceptionDto>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof userControllerV1PostUnsubscribeUser>>, TError,{userId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof userControllerV1PostUnsubscribeUser>>, {userId: string}> = (props) => {
          const {userId} = props ?? {};

          return  userControllerV1PostUnsubscribeUser(userId,)
        }

      return useMutation<Awaited<ReturnType<typeof userControllerV1PostUnsubscribeUser>>, TError, {userId: string}, TContext>(mutationFn, mutationOptions)
    }
    
export const userControllerV1PostSubscribeUser = (
    userId: string,
 
) => {
      return hellojoyApiInstance<UnsubscribeUserResponseDto>(
      {url: `/v1/public-api/user/${userId}/subscribe`, method: 'post'
    },
      );
    }
  


    export type UserControllerV1PostSubscribeUserMutationResult = NonNullable<Awaited<ReturnType<typeof userControllerV1PostSubscribeUser>>>
    
    export type UserControllerV1PostSubscribeUserMutationError = ErrorType<ApiExceptionDto>

    export const useUserControllerV1PostSubscribeUser = <TError = ErrorType<ApiExceptionDto>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof userControllerV1PostSubscribeUser>>, TError,{userId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof userControllerV1PostSubscribeUser>>, {userId: string}> = (props) => {
          const {userId} = props ?? {};

          return  userControllerV1PostSubscribeUser(userId,)
        }

      return useMutation<Awaited<ReturnType<typeof userControllerV1PostSubscribeUser>>, TError, {userId: string}, TContext>(mutationFn, mutationOptions)
    }
    
export const userControllerV1PostBirthdayVerification = (
    userId: string,
    verifyBirthdayDto: VerifyBirthdayDto,
 
) => {
      return hellojoyApiInstance<VerifyBirthdayResponseDto>(
      {url: `/v1/public-api/user/${userId}/birthday-verification`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: verifyBirthdayDto
    },
      );
    }
  


    export type UserControllerV1PostBirthdayVerificationMutationResult = NonNullable<Awaited<ReturnType<typeof userControllerV1PostBirthdayVerification>>>
    export type UserControllerV1PostBirthdayVerificationMutationBody = VerifyBirthdayDto
    export type UserControllerV1PostBirthdayVerificationMutationError = ErrorType<ApiExceptionDto>

    export const useUserControllerV1PostBirthdayVerification = <TError = ErrorType<ApiExceptionDto>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof userControllerV1PostBirthdayVerification>>, TError,{userId: string;data: VerifyBirthdayDto}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof userControllerV1PostBirthdayVerification>>, {userId: string;data: VerifyBirthdayDto}> = (props) => {
          const {userId,data} = props ?? {};

          return  userControllerV1PostBirthdayVerification(userId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof userControllerV1PostBirthdayVerification>>, TError, {userId: string;data: VerifyBirthdayDto}, TContext>(mutationFn, mutationOptions)
    }
    
export const userControllerV1GetAssignedAssessments = (
    userId: string,
 signal?: AbortSignal
) => {
      return hellojoyApiInstance<AssignedAssessmentDto[]>(
      {url: `/v1/public-api/user/${userId}/assigned-assessments`, method: 'get', signal
    },
      );
    }
  

export const getUserControllerV1GetAssignedAssessmentsQueryKey = (userId: string,) => [`/v1/public-api/user/${userId}/assigned-assessments`];

    
export type UserControllerV1GetAssignedAssessmentsQueryResult = NonNullable<Awaited<ReturnType<typeof userControllerV1GetAssignedAssessments>>>
export type UserControllerV1GetAssignedAssessmentsQueryError = ErrorType<unknown>

export const useUserControllerV1GetAssignedAssessments = <TData = Awaited<ReturnType<typeof userControllerV1GetAssignedAssessments>>, TError = ErrorType<unknown>>(
 userId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof userControllerV1GetAssignedAssessments>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getUserControllerV1GetAssignedAssessmentsQueryKey(userId);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof userControllerV1GetAssignedAssessments>>> = ({ signal }) => userControllerV1GetAssignedAssessments(userId, signal);

  const query = useQuery<Awaited<ReturnType<typeof userControllerV1GetAssignedAssessments>>, TError, TData>(queryKey, queryFn, {enabled: !!(userId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


export const userControllerV1PostSubmitAssessments = (
    userId: string,
    submitAssessmentsBodyDto: SubmitAssessmentsBodyDto,
 
) => {
      return hellojoyApiInstance<void>(
      {url: `/v1/public-api/user/${userId}/submit-assessments`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: submitAssessmentsBodyDto
    },
      );
    }
  


    export type UserControllerV1PostSubmitAssessmentsMutationResult = NonNullable<Awaited<ReturnType<typeof userControllerV1PostSubmitAssessments>>>
    export type UserControllerV1PostSubmitAssessmentsMutationBody = SubmitAssessmentsBodyDto
    export type UserControllerV1PostSubmitAssessmentsMutationError = ErrorType<ApiExceptionDto>

    export const useUserControllerV1PostSubmitAssessments = <TError = ErrorType<ApiExceptionDto>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof userControllerV1PostSubmitAssessments>>, TError,{userId: string;data: SubmitAssessmentsBodyDto}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof userControllerV1PostSubmitAssessments>>, {userId: string;data: SubmitAssessmentsBodyDto}> = (props) => {
          const {userId,data} = props ?? {};

          return  userControllerV1PostSubmitAssessments(userId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof userControllerV1PostSubmitAssessments>>, TError, {userId: string;data: SubmitAssessmentsBodyDto}, TContext>(mutationFn, mutationOptions)
    }
    
export const userControllerV1GetAssignedCheckIns = (
    userId: string,
 signal?: AbortSignal
) => {
      return hellojoyApiInstance<CheckInDtoV2[]>(
      {url: `/v1/public-api/user/${userId}/assigned-checkins`, method: 'get', signal
    },
      );
    }
  

export const getUserControllerV1GetAssignedCheckInsQueryKey = (userId: string,) => [`/v1/public-api/user/${userId}/assigned-checkins`];

    
export type UserControllerV1GetAssignedCheckInsQueryResult = NonNullable<Awaited<ReturnType<typeof userControllerV1GetAssignedCheckIns>>>
export type UserControllerV1GetAssignedCheckInsQueryError = ErrorType<unknown>

export const useUserControllerV1GetAssignedCheckIns = <TData = Awaited<ReturnType<typeof userControllerV1GetAssignedCheckIns>>, TError = ErrorType<unknown>>(
 userId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof userControllerV1GetAssignedCheckIns>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getUserControllerV1GetAssignedCheckInsQueryKey(userId);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof userControllerV1GetAssignedCheckIns>>> = ({ signal }) => userControllerV1GetAssignedCheckIns(userId, signal);

  const query = useQuery<Awaited<ReturnType<typeof userControllerV1GetAssignedCheckIns>>, TError, TData>(queryKey, queryFn, {enabled: !!(userId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


export const userControllerV1PostSubmitCheckIn = (
    userId: string,
    checkInScoreBodyV1: CheckInScoreBodyV1[],
 
) => {
      return hellojoyApiInstance<void>(
      {url: `/v1/public-api/user/${userId}/submit-checkins`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: checkInScoreBodyV1
    },
      );
    }
  


    export type UserControllerV1PostSubmitCheckInMutationResult = NonNullable<Awaited<ReturnType<typeof userControllerV1PostSubmitCheckIn>>>
    export type UserControllerV1PostSubmitCheckInMutationBody = CheckInScoreBodyV1[]
    export type UserControllerV1PostSubmitCheckInMutationError = ErrorType<ApiExceptionDto>

    export const useUserControllerV1PostSubmitCheckIn = <TError = ErrorType<ApiExceptionDto>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof userControllerV1PostSubmitCheckIn>>, TError,{userId: string;data: CheckInScoreBodyV1[]}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof userControllerV1PostSubmitCheckIn>>, {userId: string;data: CheckInScoreBodyV1[]}> = (props) => {
          const {userId,data} = props ?? {};

          return  userControllerV1PostSubmitCheckIn(userId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof userControllerV1PostSubmitCheckIn>>, TError, {userId: string;data: CheckInScoreBodyV1[]}, TContext>(mutationFn, mutationOptions)
    }
    
export const userControllerV1GetConsentInfo = (
    userId: string,
 signal?: AbortSignal
) => {
      return hellojoyApiInstance<ConsentInfoResponse>(
      {url: `/v1/public-api/user/${userId}/consent-info`, method: 'get', signal
    },
      );
    }
  

export const getUserControllerV1GetConsentInfoQueryKey = (userId: string,) => [`/v1/public-api/user/${userId}/consent-info`];

    
export type UserControllerV1GetConsentInfoQueryResult = NonNullable<Awaited<ReturnType<typeof userControllerV1GetConsentInfo>>>
export type UserControllerV1GetConsentInfoQueryError = ErrorType<ApiExceptionDto>

export const useUserControllerV1GetConsentInfo = <TData = Awaited<ReturnType<typeof userControllerV1GetConsentInfo>>, TError = ErrorType<ApiExceptionDto>>(
 userId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof userControllerV1GetConsentInfo>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getUserControllerV1GetConsentInfoQueryKey(userId);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof userControllerV1GetConsentInfo>>> = ({ signal }) => userControllerV1GetConsentInfo(userId, signal);

  const query = useQuery<Awaited<ReturnType<typeof userControllerV1GetConsentInfo>>, TError, TData>(queryKey, queryFn, {enabled: !!(userId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


export const userControllerV1AcceptConsent = (
    userId: string,
 
) => {
      return hellojoyApiInstance<void>(
      {url: `/v1/public-api/user/${userId}/accept-consent`, method: 'post'
    },
      );
    }
  


    export type UserControllerV1AcceptConsentMutationResult = NonNullable<Awaited<ReturnType<typeof userControllerV1AcceptConsent>>>
    
    export type UserControllerV1AcceptConsentMutationError = ErrorType<unknown>

    export const useUserControllerV1AcceptConsent = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof userControllerV1AcceptConsent>>, TError,{userId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof userControllerV1AcceptConsent>>, {userId: string}> = (props) => {
          const {userId} = props ?? {};

          return  userControllerV1AcceptConsent(userId,)
        }

      return useMutation<Awaited<ReturnType<typeof userControllerV1AcceptConsent>>, TError, {userId: string}, TContext>(mutationFn, mutationOptions)
    }
    
export const userControllerV1GetSafetyNet = (
    userId: string,
 signal?: AbortSignal
) => {
      return hellojoyApiInstance<SafetyNetItemDto[]>(
      {url: `/v1/public-api/user/${userId}/safety-net`, method: 'get', signal
    },
      );
    }
  

export const getUserControllerV1GetSafetyNetQueryKey = (userId: string,) => [`/v1/public-api/user/${userId}/safety-net`];

    
export type UserControllerV1GetSafetyNetQueryResult = NonNullable<Awaited<ReturnType<typeof userControllerV1GetSafetyNet>>>
export type UserControllerV1GetSafetyNetQueryError = ErrorType<ApiExceptionDto>

export const useUserControllerV1GetSafetyNet = <TData = Awaited<ReturnType<typeof userControllerV1GetSafetyNet>>, TError = ErrorType<ApiExceptionDto>>(
 userId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof userControllerV1GetSafetyNet>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getUserControllerV1GetSafetyNetQueryKey(userId);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof userControllerV1GetSafetyNet>>> = ({ signal }) => userControllerV1GetSafetyNet(userId, signal);

  const query = useQuery<Awaited<ReturnType<typeof userControllerV1GetSafetyNet>>, TError, TData>(queryKey, queryFn, {enabled: !!(userId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


export const kioskControllerV1GetClinic = (
    clinicDisplayId: string,
 signal?: AbortSignal
) => {
      return hellojoyApiInstance<KioskClinicDto>(
      {url: `/v1/public-api/kiosk/clinic/${clinicDisplayId}`, method: 'get', signal
    },
      );
    }
  

export const getKioskControllerV1GetClinicQueryKey = (clinicDisplayId: string,) => [`/v1/public-api/kiosk/clinic/${clinicDisplayId}`];

    
export type KioskControllerV1GetClinicQueryResult = NonNullable<Awaited<ReturnType<typeof kioskControllerV1GetClinic>>>
export type KioskControllerV1GetClinicQueryError = ErrorType<ApiExceptionDto>

export const useKioskControllerV1GetClinic = <TData = Awaited<ReturnType<typeof kioskControllerV1GetClinic>>, TError = ErrorType<ApiExceptionDto>>(
 clinicDisplayId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof kioskControllerV1GetClinic>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getKioskControllerV1GetClinicQueryKey(clinicDisplayId);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof kioskControllerV1GetClinic>>> = ({ signal }) => kioskControllerV1GetClinic(clinicDisplayId, signal);

  const query = useQuery<Awaited<ReturnType<typeof kioskControllerV1GetClinic>>, TError, TData>(queryKey, queryFn, {enabled: !!(clinicDisplayId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


export const kioskControllerV1GetPatientById = (
    patientId: string,
 signal?: AbortSignal
) => {
      return hellojoyApiInstance<KioskPatientDto>(
      {url: `/v1/public-api/kiosk/patients/${patientId}`, method: 'get', signal
    },
      );
    }
  

export const getKioskControllerV1GetPatientByIdQueryKey = (patientId: string,) => [`/v1/public-api/kiosk/patients/${patientId}`];

    
export type KioskControllerV1GetPatientByIdQueryResult = NonNullable<Awaited<ReturnType<typeof kioskControllerV1GetPatientById>>>
export type KioskControllerV1GetPatientByIdQueryError = ErrorType<ApiExceptionDto>

export const useKioskControllerV1GetPatientById = <TData = Awaited<ReturnType<typeof kioskControllerV1GetPatientById>>, TError = ErrorType<ApiExceptionDto>>(
 patientId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof kioskControllerV1GetPatientById>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getKioskControllerV1GetPatientByIdQueryKey(patientId);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof kioskControllerV1GetPatientById>>> = ({ signal }) => kioskControllerV1GetPatientById(patientId, signal);

  const query = useQuery<Awaited<ReturnType<typeof kioskControllerV1GetPatientById>>, TError, TData>(queryKey, queryFn, {enabled: !!(patientId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


export const kioskControllerV1PostIdentifyPatientFromAttributes = (
    kioskIdentifyPatientFromAttributesBodyDto: KioskIdentifyPatientFromAttributesBodyDto,
 
) => {
      return hellojoyApiInstance<KioskIdentifyPatientDto>(
      {url: `/v1/public-api/kiosk/identify-patient-attributes`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: kioskIdentifyPatientFromAttributesBodyDto
    },
      );
    }
  


    export type KioskControllerV1PostIdentifyPatientFromAttributesMutationResult = NonNullable<Awaited<ReturnType<typeof kioskControllerV1PostIdentifyPatientFromAttributes>>>
    export type KioskControllerV1PostIdentifyPatientFromAttributesMutationBody = KioskIdentifyPatientFromAttributesBodyDto
    export type KioskControllerV1PostIdentifyPatientFromAttributesMutationError = ErrorType<ApiExceptionDto>

    export const useKioskControllerV1PostIdentifyPatientFromAttributes = <TError = ErrorType<ApiExceptionDto>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof kioskControllerV1PostIdentifyPatientFromAttributes>>, TError,{data: KioskIdentifyPatientFromAttributesBodyDto}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof kioskControllerV1PostIdentifyPatientFromAttributes>>, {data: KioskIdentifyPatientFromAttributesBodyDto}> = (props) => {
          const {data} = props ?? {};

          return  kioskControllerV1PostIdentifyPatientFromAttributes(data,)
        }

      return useMutation<Awaited<ReturnType<typeof kioskControllerV1PostIdentifyPatientFromAttributes>>, TError, {data: KioskIdentifyPatientFromAttributesBodyDto}, TContext>(mutationFn, mutationOptions)
    }
    
export const publicOnboardingControllerPostLookup = (
    patientInviteLookupBody: PatientInviteLookupBody,
 
) => {
      return hellojoyApiInstance<PatientInviteLookupResponseV1>(
      {url: `/v1/public-api/onboarding/lookup`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: patientInviteLookupBody
    },
      );
    }
  


    export type PublicOnboardingControllerPostLookupMutationResult = NonNullable<Awaited<ReturnType<typeof publicOnboardingControllerPostLookup>>>
    export type PublicOnboardingControllerPostLookupMutationBody = PatientInviteLookupBody
    export type PublicOnboardingControllerPostLookupMutationError = ErrorType<ApiExceptionDto>

    export const usePublicOnboardingControllerPostLookup = <TError = ErrorType<ApiExceptionDto>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof publicOnboardingControllerPostLookup>>, TError,{data: PatientInviteLookupBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof publicOnboardingControllerPostLookup>>, {data: PatientInviteLookupBody}> = (props) => {
          const {data} = props ?? {};

          return  publicOnboardingControllerPostLookup(data,)
        }

      return useMutation<Awaited<ReturnType<typeof publicOnboardingControllerPostLookup>>, TError, {data: PatientInviteLookupBody}, TContext>(mutationFn, mutationOptions)
    }
    
export const publicOnboardingControllerPostVerify = (
    patientInviteVerifyBody: PatientInviteVerifyBody,
 
) => {
      return hellojoyApiInstance<PatientInviteVerifyResponse>(
      {url: `/v1/public-api/onboarding/verification-code`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: patientInviteVerifyBody
    },
      );
    }
  


    export type PublicOnboardingControllerPostVerifyMutationResult = NonNullable<Awaited<ReturnType<typeof publicOnboardingControllerPostVerify>>>
    export type PublicOnboardingControllerPostVerifyMutationBody = PatientInviteVerifyBody
    export type PublicOnboardingControllerPostVerifyMutationError = ErrorType<ApiExceptionDto>

    export const usePublicOnboardingControllerPostVerify = <TError = ErrorType<ApiExceptionDto>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof publicOnboardingControllerPostVerify>>, TError,{data: PatientInviteVerifyBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof publicOnboardingControllerPostVerify>>, {data: PatientInviteVerifyBody}> = (props) => {
          const {data} = props ?? {};

          return  publicOnboardingControllerPostVerify(data,)
        }

      return useMutation<Awaited<ReturnType<typeof publicOnboardingControllerPostVerify>>, TError, {data: PatientInviteVerifyBody}, TContext>(mutationFn, mutationOptions)
    }
    
export const publicOnboardingControllerPostSetupAccount = (
    patientInviteSetupAccountBody: PatientInviteSetupAccountBody,
 
) => {
      return hellojoyApiInstance<PatientInviteSetupAccountResponse>(
      {url: `/v1/public-api/onboarding/account`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: patientInviteSetupAccountBody
    },
      );
    }
  


    export type PublicOnboardingControllerPostSetupAccountMutationResult = NonNullable<Awaited<ReturnType<typeof publicOnboardingControllerPostSetupAccount>>>
    export type PublicOnboardingControllerPostSetupAccountMutationBody = PatientInviteSetupAccountBody
    export type PublicOnboardingControllerPostSetupAccountMutationError = ErrorType<ApiExceptionDto>

    export const usePublicOnboardingControllerPostSetupAccount = <TError = ErrorType<ApiExceptionDto>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof publicOnboardingControllerPostSetupAccount>>, TError,{data: PatientInviteSetupAccountBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof publicOnboardingControllerPostSetupAccount>>, {data: PatientInviteSetupAccountBody}> = (props) => {
          const {data} = props ?? {};

          return  publicOnboardingControllerPostSetupAccount(data,)
        }

      return useMutation<Awaited<ReturnType<typeof publicOnboardingControllerPostSetupAccount>>, TError, {data: PatientInviteSetupAccountBody}, TContext>(mutationFn, mutationOptions)
    }
    
export const authenticationControllerV1PostForgotPasswordRequestCode = (
    forgotPasswordRequestCodeBody: ForgotPasswordRequestCodeBody,
 
) => {
      return hellojoyApiInstance<void>(
      {url: `/v1/public-api/authentication/forgot-password/request-code`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: forgotPasswordRequestCodeBody
    },
      );
    }
  


    export type AuthenticationControllerV1PostForgotPasswordRequestCodeMutationResult = NonNullable<Awaited<ReturnType<typeof authenticationControllerV1PostForgotPasswordRequestCode>>>
    export type AuthenticationControllerV1PostForgotPasswordRequestCodeMutationBody = ForgotPasswordRequestCodeBody
    export type AuthenticationControllerV1PostForgotPasswordRequestCodeMutationError = ErrorType<unknown>

    export const useAuthenticationControllerV1PostForgotPasswordRequestCode = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof authenticationControllerV1PostForgotPasswordRequestCode>>, TError,{data: ForgotPasswordRequestCodeBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof authenticationControllerV1PostForgotPasswordRequestCode>>, {data: ForgotPasswordRequestCodeBody}> = (props) => {
          const {data} = props ?? {};

          return  authenticationControllerV1PostForgotPasswordRequestCode(data,)
        }

      return useMutation<Awaited<ReturnType<typeof authenticationControllerV1PostForgotPasswordRequestCode>>, TError, {data: ForgotPasswordRequestCodeBody}, TContext>(mutationFn, mutationOptions)
    }
    
export const authenticationControllerV1PostForgotPasswordConfirm = (
    forgotPasswordConfirmBody: ForgotPasswordConfirmBody,
 
) => {
      return hellojoyApiInstance<void>(
      {url: `/v1/public-api/authentication/forgot-password/confirm`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: forgotPasswordConfirmBody
    },
      );
    }
  


    export type AuthenticationControllerV1PostForgotPasswordConfirmMutationResult = NonNullable<Awaited<ReturnType<typeof authenticationControllerV1PostForgotPasswordConfirm>>>
    export type AuthenticationControllerV1PostForgotPasswordConfirmMutationBody = ForgotPasswordConfirmBody
    export type AuthenticationControllerV1PostForgotPasswordConfirmMutationError = ErrorType<unknown>

    export const useAuthenticationControllerV1PostForgotPasswordConfirm = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof authenticationControllerV1PostForgotPasswordConfirm>>, TError,{data: ForgotPasswordConfirmBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof authenticationControllerV1PostForgotPasswordConfirm>>, {data: ForgotPasswordConfirmBody}> = (props) => {
          const {data} = props ?? {};

          return  authenticationControllerV1PostForgotPasswordConfirm(data,)
        }

      return useMutation<Awaited<ReturnType<typeof authenticationControllerV1PostForgotPasswordConfirm>>, TError, {data: ForgotPasswordConfirmBody}, TContext>(mutationFn, mutationOptions)
    }
    
export const assessmentControllerV1GetAssessments = (
    params?: AssessmentControllerV1GetAssessmentsParams,
 signal?: AbortSignal
) => {
      return hellojoyApiInstance<AssessmentDto[]>(
      {url: `/v1/public-api/assessments`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getAssessmentControllerV1GetAssessmentsQueryKey = (params?: AssessmentControllerV1GetAssessmentsParams,) => [`/v1/public-api/assessments`, ...(params ? [params]: [])];

    
export type AssessmentControllerV1GetAssessmentsQueryResult = NonNullable<Awaited<ReturnType<typeof assessmentControllerV1GetAssessments>>>
export type AssessmentControllerV1GetAssessmentsQueryError = ErrorType<unknown>

export const useAssessmentControllerV1GetAssessments = <TData = Awaited<ReturnType<typeof assessmentControllerV1GetAssessments>>, TError = ErrorType<unknown>>(
 params?: AssessmentControllerV1GetAssessmentsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof assessmentControllerV1GetAssessments>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getAssessmentControllerV1GetAssessmentsQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof assessmentControllerV1GetAssessments>>> = ({ signal }) => assessmentControllerV1GetAssessments(params, signal);

  const query = useQuery<Awaited<ReturnType<typeof assessmentControllerV1GetAssessments>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const partnerOnboardingControllerCreatePartnerClinician = (
    externalOrgId: string,
    createPartnerClinicianBody: CreatePartnerClinicianBody,
 
) => {
      return hellojoyApiInstance<ClinicianDto>(
      {url: `/v1/public-api/partner/${externalOrgId}/clinician`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: createPartnerClinicianBody
    },
      );
    }
  


    export type PartnerOnboardingControllerCreatePartnerClinicianMutationResult = NonNullable<Awaited<ReturnType<typeof partnerOnboardingControllerCreatePartnerClinician>>>
    export type PartnerOnboardingControllerCreatePartnerClinicianMutationBody = CreatePartnerClinicianBody
    export type PartnerOnboardingControllerCreatePartnerClinicianMutationError = ErrorType<unknown>

    export const usePartnerOnboardingControllerCreatePartnerClinician = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof partnerOnboardingControllerCreatePartnerClinician>>, TError,{externalOrgId: string;data: CreatePartnerClinicianBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof partnerOnboardingControllerCreatePartnerClinician>>, {externalOrgId: string;data: CreatePartnerClinicianBody}> = (props) => {
          const {externalOrgId,data} = props ?? {};

          return  partnerOnboardingControllerCreatePartnerClinician(externalOrgId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof partnerOnboardingControllerCreatePartnerClinician>>, TError, {externalOrgId: string;data: CreatePartnerClinicianBody}, TContext>(mutationFn, mutationOptions)
    }
    
